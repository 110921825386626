import axios from "axios";
const jsonConfig = require("../../Config.json");


const countallstatusforrequitionforcandidates = async (userType, id, requisitionId,checkRecruiterCandidate) => {
  try {

    if (userType == "MIS Operator") {
      var payload_data = {
        "MISId": id,
        "requisitionId": requisitionId
      };
    }

    if (userType == "Recruiter") {
      var payload_data = {
        "recruiterId": id,
        "requisitionId": requisitionId
      };
    }
    if (userType == "Recruitment Leader") {
      var payload_data;
      if(checkRecruiterCandidate){
        payload_data = {
          "recruiterId": id,
          "requisitionId": requisitionId,
        };
      }else{
        payload_data = {
          "RecruitmentLeaderId": id,
          "requisitionId": requisitionId
        };
      }
      
    }
    if (userType == "Organization Admin") {
      var payload_data = {
        "orgId": id,
        "requisitionId": requisitionId
      };
    }
    const getAllResponse = await axios.post(
      jsonConfig.apiUrl + `countallstatusforrequitionforcandidates`, payload_data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default countallstatusforrequitionforcandidates;
