import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from "react";
import inviteCandidateResponse from "../../apis/inviteCandidate/inviteCandidate";
import getUserByEmailId from "../../apis/inviteCandidate/getUserByEmailId";
import getRecruiterAllSkillsResponse from "../../apis/recruiterPrivateSkillAccess/getRecruiterAllSkills";
import getRecruiterAllTestsResponse from "../../apis/recruiterPrivateTestAccess/getRecruiterAllTest";
import DropdownBox from "../../components/formElements/dropdownBox";
import { useHistory } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import * as XLSX from 'xlsx';
import { FilePicker } from "react-file-picker";
import PlusIcon from "./../../resources/images/plus_icon.png";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import "./inviteCnadidates.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import validateEmail from "../../utilities/validateEmail";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Header from "../../components/headerAdmin/Header";
import Footer from "../../components/adminFooter/Footer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { UserTypeContext } from "../../AppRoute";
import GetAppIcon from '@material-ui/icons/GetApp';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Route, Redirect, useParams, useLocation } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});
const useStyles = makeStyles((theme) => ({
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important"
  },
  btnCancel: {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    fontSize: '15px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },
  btnSave: {
    width: "120px",
    height: "32px",
    backgroundColor: "#1885E6",
    fontSize: '15px',
    color: "#FFFFFF",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8"
    }
  }
}
));

const InviteCandidates = ({ idContextTest }) => {
  const classes = useStyles();
  const { userType, userIdType } = useContext(UserTypeContext);
  const { email } = useParams();
  const history = useHistory();
  const [candidates, setCandidates] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      chk: "",
      firstNameHighlight: false,
      lastNameHighlight: false,
      emailHighlight: false,
      aFocus: false
    },
  ]);


  const [allTests, setAllTests] = useState([]);
  const [allTestType, setallTestType] = useState(["Public", "Client-specific"]);
  const [testId, setTestId] = useState("");
  const [isInviteSend, setisInviteSend] = useState(false);
  const [selectedTest, setSelectedTest] = useState({});
  const [render, setRender] = useState(false);
  const [bulkUploadClick, setbulkUploadClick] = useState(false);
  const [isNavigationOpen, setisNavigationOpen] = useState(false);
  const [confirmDeleteModal, setconfirmDeleteModal] = useState(false);
  const renderRecruiterSkill = async (recruiterId, type) => {
    try {
      const response = await getRecruiterAllTestsResponse(recruiterId);
      let isPublic = type === "Public" ? true : false;
      let skillArr = [];
      if (isPublic) {
        skillArr = response.data.data.filter(
          (e) => e.testtype.code === "Public"
        );
      } else {
        skillArr = response.data.data.filter(
          (e) => e.testtype.code != "Public"
        );
      }

      setAllTests(skillArr);
    } catch (error) {
      console.log(error);
    }
  };
  const userId = idContextTest;

  const handleSelect = (e, value) => {
    setTestId(value?.id);
    if (value) {
      setSelectedTest(value);
    }
  };
  const addMoreForm = () => {
    if (!isInviteSend && candidates.length < 50) {
      const candidateObj = {
        firstName: "",
        lastName: "",
        email: "",
        chk: "",
        firstNameHighlight: false,
        lastNameHighlight: false,
        emailHighlight: false,
        aFocus: false
      };
      setCandidates([...candidates, candidateObj]);
    }
    if (candidates.length >= 50) {
      return NotificationManager.error(
        "A maximum of 50 candidates can be invited at once",
        "Error",
        4500
      )
    }
  };
  const addMoreForm1 = () => {
    if (!isInviteSend && candidates.length < 51) {
      const candidateObj = {
        firstName: "",
        lastName: "",
        email: "",
        chk: "1",
        firstNameHighlight: false,
        lastNameHighlight: false,
        emailHighlight: false,
        aFocus: false
      };
      setCandidates([...candidates, candidateObj]);
    }
    if (candidates.length >= 51) {
      return NotificationManager.error(
        "A maximum of 50 candidates can be invited at once",
        "Error",
        4500
      )
    }
  };

  const addMoreFo = (Data) => {
    let candidatearr1 = [];
    for (let i = 0; i < Data.length; i++) {
      if (Data[i].FirstName != "" && Data[i].LastName != "" && Data[i].Email != "") {
        let candidateObj = {
          firstName: Data[i].FirstName === (undefined || null) ? "" : Data[i].FirstName,
          lastName: Data[i].LastName === (undefined || null) ? "" : Data[i].LastName,
          email: Data[i].Email === undefined ? "" : Data[i].Email,
          chk: "",
          firstNameHighlight: false,
          lastNameHighlight: false,
          emailHighlight: false,
        };
        candidates.push(candidateObj)
      }
      for (let i = 0; i < candidates.length; i++) {
        handleCandidateEmailForm(candidates[i].email, i);
      }
    }
  };
  const closeExtraForm = (index) => {
    const remaining = [...candidates].filter((o, i) => i !== index);

    setCandidates(remaining);
  };

  const validateEmailResponse = async (email) => {
    let payload = {
      email: email,
    };
    try {
      const response = await getUserByEmailId(payload);
      console.log("response", response);
      const currentUser = response?.data?.data[0];
      let candidatesData = [...candidates];
      if (currentUser) {
        candidatesData = candidatesData.map(c => {
          if (c.email === currentUser.email) {
            c = { ...c, ...currentUser }
            if (currentUser.firstName !== "") c.disableFirstName = true;
            if (currentUser.lastName !== "") c.disableLastName = true;
          }
          return c;
        });
      }

      const result = candidatesData.map((e) => {
        if (e.disableFirstName ||
          e.disableLastName ||
          e.MiddleInitial ||
          e.countryCode ||
          e.phoneNumber ||
          e.profile) {
          // delete e.disableFirstName;
          // delete e.disableLastName;
          delete e.MiddleInitial;
          delete e.countryCode;
          delete e.phoneNumber;
          delete e.profile;
        }
        return e;
      })
      setCandidates(result);

    } catch (error) {
      console.log("error", error);

    }
  };
  const handleCandidateEmailForm = (val, i) => {
    let value = val;
    let name = "email"
    if (name === "email") {
      value = value.trim().toLowerCase();
    }
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData[name] = value;
    originalData[i] = changingData;
  };

  const handleCandidateEmailFormChange = (e, i) => {
    let { value, name } = e.target;
    if (name === "email") {
      value = value.trim().toLowerCase();
    }
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData[name] = value;

    if (value === "") {
      changingData.firstName = "";
      changingData.lastName = "";
      changingData.disableFirstName = false;
      changingData.disableLastName = false;
      changingData.emailHighlight = true
    }
    else {
      changingData.emailHighlight = false
    }
    originalData[i] = changingData;

    const emailValidate = validateEmail(value);
    setCandidates(originalData || []);
    if (emailValidate && value) {
      validateEmailResponse(value);
      changingData.firstName = "";
      changingData.lastName = "";
      changingData.disableFirstName = false;
      changingData.disableLastName = false;
    } else {
      changingData.firstName = "";
      changingData.lastName = "";
      changingData.disableFirstName = false;
      changingData.disableLastName = false;
    }
  };

  const handleCandidateFormChange = (e, i) => {
    let { value, name } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }

    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData[name] = value;
    originalData[i] = changingData;
    if (originalData[i].firstName != "")
      originalData[i].firstNameHighlight = false
    if (originalData[i].lastName != "")
      originalData[i].lastNameHighlight = false
    setCandidates(originalData || []);
  };

  const validate = () => {
    if (!testId || testId === "" || userId === "") {
      return false;
    }
    return !candidates.some(
      (c) => ((c.firstName == undefined && c.lastName != undefined && c.email != undefined) || (c.firstName != undefined && c.lastName == undefined && c.email != undefined) || (c.firstName != undefined && c.lastName != undefined && c.email == undefined) || (c.firstName != undefined && c.lastName == undefined && c.email == undefined) || (c.firstName == undefined && c.lastName != undefined && c.email == undefined) || (c.firstName == undefined && c.lastName == undefined && c.email != undefined))
    );
  };
  const highlightFieldChk = () => {
    let temp = 0;
    let candidatearr = [];
    for (let i = 0; i < candidates.length; i++) {
      if ((candidates[i].email == "" && (candidates[i].firstName != "" || candidates[i].lastName != "") || candidates[i].email == undefined) && temp == 0) {
        temp = 1;
        document.getElementById("email" + i).focus();
      }
      if ((candidates[i].firstName == "" && (candidates[i].email != "" || candidates[i].lastName != "") || candidates[i].firstName == undefined) && temp == 0) {
        document.getElementById("fName" + i).focus();
        temp = 1;
      }
      if ((candidates[i].lastName == "" && (candidates[i].email != "" || candidates[i].firstName != "") || candidates[i].lastName == undefined) && temp == 0) {
        document.getElementById("lName" + i).focus();
        temp = 1;
      }
      candidatearr.push({
        firstName: candidates[i].firstName,
        lastName: candidates[i].lastName,
        email: candidates[i].email,
        chk: "",
        firstNameHighlight: (candidates[i].firstName === "" && (candidates[i].lastName != "" || candidates[i].email != "")) || candidates[i].firstName === undefined ? true : false,
        lastNameHighlight: (candidates[i].lastName === "" && (candidates[i].firstName != "" || candidates[i].email != "")) || candidates[i].lastName === undefined ? true : false,
        emailHighlight: (candidates[i].email === "" && (candidates[i].firstName != "" || candidates[i].lastName != "")) || candidates[i].email === undefined ? true : false,
      })
    }
    setCandidates(candidatearr);
  }

  const handleSendInviteCandidate = async (e) => {
    e.preventDefault();
    let highlightChk = highlightFieldChk();
    //need to do validation for testId and userId    
    try {
      const isValidated = validate();
      if (!isValidated) {
        return NotificationManager.error(
          "Please fill all the mandatory fields",
          "Error",
          2500
        );
      } else {
        const commonData = {
          testTaken: false,
          // skillId,
          testId,
          invitedBy: userId,
          createdBy: userId,
          updatedBy: userId,
        };
        const updateCandidates = [];
        for (let i = 0; i < candidates.length; i++) {
          if (candidates[i].firstName != "" && candidates[i].lastName != "" && candidates[i].email != "") {
            let candidateOb = {
              firstName: candidates[i].firstName,
              lastName: candidates[i].lastName,
              email: candidates[i].email,
            };

            updateCandidates.push(candidateOb)
          }
        }

        const payload = [...updateCandidates].map((c) => (
          { ...c, ...commonData }));

        const response = await inviteCandidateResponse(payload);
        const updatedArray = updateCandidates.map((o, i) => (
          {
            ...o,
            ...response.data.data[i],
          }
        ));

        setCandidates(updatedArray);
        setisInviteSend(true);
      }
    } catch (error) {

      if (error.response.status === 400) {
        const errorMessage = error.response.data.errMessage;
        NotificationManager.error(errorMessage, "Error", 2500);
      }
      console.log(error);
    }
  };
  const handleSelectSkillType = (e, value) => {
    setSelectedTest({});
    if (value) {
      renderRecruiterSkill(userId, value);
    } else {
      setSelectedTest({});
    }
  };

  let selectDropDown = {
    defaultValue: testId,
    value: testId,
    label: "Select Skills",
    options: allTests.map((skill, i) => skill),
    getOptionLabel: (option) => option?.name || "",
    placeholder: "Select skill type",
    onChange: handleSelect,
  };

  const renderSkillDropdown = () => {
    return (
      <Grid container spacing={1} className="grid__container">
        <Grid xs={12} sm={4} item>
          <DropdownBox {...selectDropDown} />
        </Grid>
      </Grid>
    );
  };
  const goToHomePage = () => {
    history.push("/");
  };

  const formReset = () => {
    setbulkUploadClick(false)
    setTestId("");
    setisInviteSend(false);
    setallTestType(["Public", "Client-specific"]);
    setAllTests([]);
    setSelectedTest({});

    setCandidates(
      candidates.slice(0, 1).map((c) => ({
        ...c,
        firstName: "",
        lastName: "",
        email: "",
        status: "",
      }))
    );

    setRender(!render);
  };

  const bulk_upload_conf = () => {
    setconfirmDeleteModal(true)
  }
  const bulk_upload_cls = () => {
    setconfirmDeleteModal(false)
  }
  const bulk_upload_clr = () => {
    setconfirmDeleteModal(false)
    formReset();
  }
  const handelExcel = (e) => {
    let selectedFile = e;
    let readExcel = new FileReader();
    readExcel.readAsArrayBuffer(selectedFile)
    readExcel.onload = (e) => {
      let dataStore = e.target.result

      const workBook = XLSX.read(e.target.result, { type: "buffer" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_json(workSheet)
      if ((data.length != 0) && (data[0].FirstName != undefined || data[0].LastName != undefined || data[0].Email != undefined)) {
        if ((data.length + candidates.length) <= 51) {
          addMoreFo(data)
          setbulkUploadClick(true)
        }
        else {
          return NotificationManager.error(
            "A maximum of 50 candidates can be invited at once",
            "Error",
            4500
          );
        }
      }
      else {
        return NotificationManager.error(
          "Please fill the downloaded template and try again ",
          "Error",
          4500
        );
      }
    }
  }

  useEffect(() => {

    if (email) {
      let obj = {
        target: {
          name: "email",
          value: email
        }
      }
      handleCandidateEmailFormChange(obj, 0)
    }
  }, [])


  return (
    <>
      <Header />
      {userType === "Recruiter" || userType === 'Recruitment Leader' ? (
        <>
          <div className="recruiter_invite_wrapper">
            <div className='titlesofCommunity'>Invite Candidate to Take Test</div>
            <div className="goBackToRecruiterDashboard" onClick={(e) => goToHomePage(e)}>
              <ArrowBackIcon /> Go Back to Dashboard
            </div>
            <p className="recruiter_invite_heading">
              Select Test to Invite Candidate
            </p>
            <hr className="recruiter_invite_divider"></hr>

            <div className="select customized-select dropdown_width_skill_recruiter_invite_skill">
              <div className="invite_candidate_skill_dropdown_wrapper_skilltype">
                <label className="recruiter_invite_label" htmlFor="Select Skill">
                  Test Type*
                </label>
                <Autocomplete
                  key={render}
                  id="combo-box-demo"
                  className="mt_15 selectfocus"
                  options={allTestType}
                  autoHighlight
                  disabled={isInviteSend}
                  getOptionLabel={(option) => option || ""}
                  onChange={handleSelectSkillType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      required={true}
                      placeholder="Select Test Type"
                    />
                  )}
                />
              </div>
              <div className="invite_candidate_skill_dropdown_wrapper_skill">
                <label className="recruiter_invite_label" htmlFor="Select Skill">
                  Test*
                </label>
                <Autocomplete
                  key={render}
                  id="combo-box-demo"
                  className="mt_15"
                  options={allTests}
                  value={selectedTest}
                  autoHighlight
                  getOptionLabel={(option) => option?.testName || ""}
                  onChange={handleSelect}
                  disabled={isInviteSend}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      required={true}
                      placeholder="Select Test"
                    />
                  )}
                />
                <p className="client_info">
                  {selectedTest?.recruitmentpartner?.name
                    ? `Recruitment Organization : ${selectedTest?.recruitmentpartner?.name} `
                    : ""}
                  {selectedTest?.recruitmentpartner?.name &&
                    selectedTest?.customer?.name
                    ? ","
                    : ""}
                  {selectedTest?.customer?.name
                    ? ` Customer : ${selectedTest?.customer?.name}`
                    : ""}
                </p>
              </div>
            </div>
            <div className="invite_candidate_buttom_wrapper">
              <p className="recruiter_invite_heading">Add Candidates</p>
              <hr className="recruiter_invite_divider_two"></hr>
              <div className="invite_candidate_form_wrapper_main">
                <div className="df">
                  <div className="invite_candidate_label_wrapper">
                    <label className="recruiter_invite_label_form" htmlFor="fname">
                      Email*
                    </label>
                  </div>
                  <div className="invite_candidate_label_wrapper">
                    <label className="recruiter_invite_label_form" htmlFor="fname">
                      First Name*
                    </label>
                  </div>
                  <div className="invite_candidate_label_wrapper">
                    <label className="recruiter_invite_label_form" htmlFor="fname">
                      Last Name*
                    </label>
                  </div>
                </div>
                <div className="scrollbar" id="style-2">
                  <div className="invite_candidate_form_wrapper">
                    {candidates.map((candidate, i) => (
                      <div className="invite_candidate_form" key={i}>
                        <div className="select customized-select dropdown_width_recruiter_invite">
                          {((bulkUploadClick && !candidate.email == "" && !candidate.firstName == "" && !candidate.lastName == "") || !bulkUploadClick || !candidate.email == "" || !candidate.firstName == "" || !candidate.lastName == "" || candidate.chk == 1) &&
                            <input
                              type="text"
                              className={!candidate.emailHighlight ? "text_box_cleartalent_form_recruiter mt_8" : "text_box_cleartalent_form_recruiter_highlighter mt_8"}
                              id={"email" + i}
                              placeholder="Enter Email"
                              name="email"
                              value={candidate.email}
                              disabled={isInviteSend}
                              onChange={(e) => handleCandidateEmailFormChange(e, i)}
                            />
                          }

                          {candidate.status && candidate.status === "success" && (
                            <>
                              <p className="invite__success">Invite sent</p>
                            </>
                          )}

                          {candidate.status &&
                            candidate.status ===
                            "not elligible due to skill test interval" && (
                              <>
                                <p className="invite__error">
                                  Candidate has recently taken a test.
                                </p>
                              </>
                            )}
                          {candidate.status &&
                            candidate.status === "already taken this test" && (
                              <>
                                <p className="invite__error">
                                  The candidate has already taken this test. Please
                                  ask the candidate to share the PoweResume with
                                  you.
                                </p>
                              </>
                            )}

                          {candidate.status &&
                            candidate.status === "The candidate has already taken the test." && (
                              <>
                                <p className="invite__error">
                                  The candidate has already taken the test.
                                </p>
                              </>
                            )}

                          {candidate.status &&
                            candidate.status === "The candidate has already taken the test. Please ask the candidate to share the PoweResume" && (
                              <>
                                <p className="invite__error">
                                  The candidate has already taken this test. Please
                                  ask the candidate to share the PoweResume with
                                  you.
                                </p>
                              </>
                            )}
                          {candidate.status &&
                            candidate.email == "" && (
                              <>
                              </>
                            )}


                          {candidate.status &&
                            candidate.status === "existinginvite" && !candidate.email == "" && (
                              <>
                                <p className="invite__error">
                                  The candidate has an active invite for a test.
                                </p>
                              </>
                            )}
                          {candidate.status &&
                            candidate.status ===
                            "the candidate already reached the skill test limit" && (
                              <>
                                <p className="invite__error">
                                  This candidate already reached the skill test limit. Please ask the candidate to delete a test by using “Take Skill Test” button in PoweResume.
                                </p>
                              </>
                            )}
                          {candidate.status &&
                            candidate.status ===
                            "Email ID already registered as a Recruiter. Please use a different Email ID." && (
                              <>
                                <p className="invite__error">
                                  Email ID already registered as a Recruiter. Please
                                  use a different Email ID.
                                </p>
                              </>
                            )}
                        </div>
                        <div className="select customized-select dropdown_width_recruiter_invite">
                          {((bulkUploadClick && !candidate.email == "" && !candidate.firstName == "" && !candidate.lastName == "") || !bulkUploadClick || !candidate.email == "" || !candidate.firstName == "" || !candidate.lastName == "" || candidate.chk == 1) &&
                            <input
                              type="text"
                              className={!candidate.firstNameHighlight ? "text_box_cleartalent_form_recruiter mt_8" : "text_box_cleartalent_form_recruiter_highlighter mt_8"}
                              id={"fName" + i}
                              name="firstName"
                              placeholder="Enter First Name"
                              value={candidate?.firstName}
                              disabled={candidate?.disableFirstName || isInviteSend}
                              onChange={(e) => handleCandidateFormChange(e, i)}
                            />
                          }
                        </div>
                        <div className="select customized-select dropdown_width_recruiter_invite">
                          {((bulkUploadClick && !candidate.email == "" && !candidate.firstName == "" && !candidate.lastName == "") || !bulkUploadClick || !candidate.email == "" || !candidate.firstName == "" || !candidate.lastName == "" || candidate.chk == 1) &&
                            <input
                              type="text"
                              className={!candidate.lastNameHighlight ? "text_box_cleartalent_form_recruiter mt_8" : "text_box_cleartalent_form_recruiter_highlighter mt_8"}
                              id={"lName" + i}
                              name="lastName"
                              placeholder="Enter Last Name"
                              value={candidate?.lastName}
                              disabled={candidate?.disableLastName || isInviteSend}
                              onChange={(e) => handleCandidateFormChange(e, i)}
                            />
                          }

                          {!candidate.status && (
                            <div >
                              <div className="closeIcon cp">
                                {((i !== 0) || (i == 0 && candidates.length > 1) || (bulkUploadClick && candidates.length > 2)) && (
                                  <CancelRoundedIcon
                                    onClick={() => closeExtraForm(i)}
                                  />
                                )}
                              </div>
                              <div className="invite_candidate_add_icon_wrap cp closeIcons">
                                {i == (candidates.length - 1) ? (
                                  <AddCircleIcon
                                    color="primary"
                                    onClick={bulkUploadClick ? (addMoreForm1) : (addMoreForm)}
                                  />
                                ) : ''}
                              </div>

                            </div >
                          )}
                        </div>
                      </div>
                    ))
                    }
                    {candidates.length <= 5 &&
                      <div className="bulk_drop_menu button_mgn_top" >
                        <div>
                          {
                            bulkUploadClick ?
                              (
                                <Button variant="outlined" className="linkButton btn_styl " id="btn_border" disabled={isInviteSend} onClick={bulk_upload_conf}>
                                  <p className="bulk_upload reset_email">
                                    Reset
                                  </p>
                                </Button>
                              ) : (
                                <FilePicker
                                  extensions={["xlsx"]}
                                  onChange={(e) => handelExcel(e)}
                                  onError={(errMsg) => {
                                    NotificationManager.error(
                                      errMsg,
                                      "Error"
                                    );
                                  }}
                                >
                                  <Button variant="outlined" className="linkButton " disabled={isInviteSend}>
                                    <p className="bulk_upload">
                                      Bulk Upload
                                    </p>
                                  </Button>
                                </FilePicker>
                              )

                          }
                        </div>
                        <div className="dropButton mt_5"  >
                          <a href="/Bulk_Upload_Template.xlsx" className="sample_file" id="center_Aling" > <span id="downTemp"><GetAppIcon /></span><span>Download Template </span></a>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn-invite_submit btn-invite_submit_blue cp submit_btn_ivt "
                            onClick={handleSendInviteCandidate}
                            disabled={isInviteSend || !candidates.some((c) => (c.firstName != "" || c.lastName != "" || c.email != ""))}
                          >
                            Submit
                          </button>
                          {isInviteSend && (
                            <button
                              type="button"
                              className="btn-invite_submit btn-invite_submit_blue cp ml_25  "
                              onClick={() => formReset()}
                            >
                              Invite More
                            </button>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {candidates.length > 5 &&
              <div className="bulk_drop_menu " >
                <div>
                  {
                    bulkUploadClick ?
                      (
                        <Button variant="outlined" className="linkButton btn_styl " id="btn_border" disabled={isInviteSend} onClick={bulk_upload_conf}>
                          <p className="bulk_upload reset_email">
                            Reset
                          </p>
                        </Button>
                      ) : (
                        <FilePicker
                          extensions={["xlsx"]}
                          onChange={(e) => handelExcel(e)}
                          onError={(errMsg) => {
                            NotificationManager.error(
                              errMsg,
                              "Error"
                            );
                          }}
                        >
                          <Button variant="outlined" className="linkButton " disabled={isInviteSend}>
                            <p className="bulk_upload">
                              Bulk Upload
                            </p>
                          </Button>
                        </FilePicker>
                      )
                  }
                </div>
                <div className="dropButton mt_5">
                  <a href="/Bulk_Upload_Template.xlsx" className="sample_file" id="center_Aling"> <span id="downTemp"><GetAppIcon /></span><span>Download Template </span></a>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn-invite_submit btn-invite_submit_blue cp submit_btn_ivt "
                    onClick={handleSendInviteCandidate}
                    disabled={isInviteSend || !candidates.some((c) => (c.firstName != "" || c.lastName != "" || c.email != ""))}
                  >
                    Submit
                  </button>
                  {isInviteSend && (
                    <button
                      type="button"
                      className="btn-invite_submit btn-invite_submit_blue cp ml_25  "
                      onClick={() => formReset()}
                    >
                      Invite More
                    </button>
                  )}
                </div>
              </div>
            }
          </div>
          <Footer />
          <NotificationContainer />
        </>
      ) : (
        <>
          <div className="row padding_80 text-center">
            <p className="fs-semi-bold fs-40 f-color  authorize ">
              You Are Not Authorized To Visit This Page
            </p>
          </div>
        </>
      )
      }

      <Dialog
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={confirmDeleteModal}
        onClose={bulk_upload_cls}
      >
        <DialogTitle>

        </DialogTitle>
        <DialogContent className='support_con'>
          <div className='support_header'>
            <p className="fs-semi-bold fs-16 f-color">
              Are you sure you want to reset ?
            </p>

          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <Button className={classes.btnCancel} id="buton" onClick={bulk_upload_cls}>No</Button>
          <Button className={classes.btnSave} id="buton" onClick={(e) => bulk_upload_clr()}
          >Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteCandidates;
