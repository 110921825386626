import React, { useState, useEffect, useCallback, useContext } from "react";
import Header from "../../components/adminHeader/adminHeader";
import getAllRecruitersResponse from "../../apis/recruiters/getAllRecruiters";
import approveRecruitersResponse from "../../apis/recruiters/approveRecruiters";
import AllRevokedRecruitersKendoTable from "./AllRevokedRecruitersKendoTable";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AllNotApprovedRecruitersKendoTable from "./AllNotApprovedRecruitersKendoTable";
import AllapprovedRecruitersKendoTable from "./AllapprovedRecruitersKendoTable";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
// import FormControl from "@material-ui/core/FormControl";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import getAllActiveRecruitmentpartner from "../../apis/organization/getAllActiveRecruitmentpartner";
import getActiveRoles from "../../apis/users/getActiveRoles";
import { getAgencyType } from "../../apis/agency/agencyApi";
import getAgencyByAgencytypeResponse from "../../apis/recruiters/getAgencyByAgencytype";
import { UserTypeContext } from "../../AppRoute";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
// const filterRoles = createFilterOptions({
//   matchFrom: "start",
//   stringify: (option) => option.role,
// });
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const Recruiters = ({ idContextTest }) => {
  const { userType } = useContext(UserTypeContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [allapprovedRecruiters, setallapprovedRecruiters] = useState([]);
  const [allNotApprovedRecruiters, setallNotApprovedRecruiters] = useState([]);
  const [allRevokedRecruiters, setallRevokedRecruiters] = useState([]);
  const [tabName, settabName] = useState("Pending");
  const [openApproveDialogue, setOpenApproveDialogue] = useState(false);
  const [openRevokeDialogue, setOpenRevokeDialogue] = useState(false);
  const [recruiterData, setRecruiterData] = useState({});
  const [userRole, setUserRole] = useState([]);
  const [AllOrganization, setAllOrganization] = useState([]);
  const [email, setEmail] = useState("");
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agencyTypeData, setAgencTypeData] = useState([]);
  const [selectAgencyType, setSelectAgencyType] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [RecruitmentParterId, setRecruitmentParterId] = useState("");
  const [apvBtn, setApvBtn] = useState(false);

  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }
  const getAllRoles = async () => {

    try {
      const allRoleResponse = await getActiveRoles();

      setUserRole(allRoleResponse.data.data);

    } catch (error) {
      console.log(error);
    }

  };

  const getAllAgencyTypesData = useCallback(async () => {
    try {
      const allData = await getAgencyByAgencytypeResponse(RecruitmentParterId, {
        type: selectAgencyType,
      });

      setAgencTypeData(allData?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }, [RecruitmentParterId, selectAgencyType]);

  const geAllAgencyType = async () => {

    try {
      const allAgency = await getAgencyType();

      setAgencies(allAgency.data.data);

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {
    if (selectAgencyType) {
      // const response =
      getAllAgencyTypesData();
    }
  }, [getAllAgencyTypesData, selectAgencyType, RecruitmentParterId]);

  const getAllRecruiters = async () => {

    let allRecruiters = await getAllRecruitersResponse();
    let approved = allRecruiters.data.data.filter((item) => item.isApproved);

    //-------------Adding False to the isApproved column of the selected dataitem---------
    let revoked = allRecruiters.data.data.filter(
      (item) => (item.isApproved === false || item.isApproved === null)
    );

    // ------------ Checking if the dataItem is not approved and is null then priting it in the pending tab------------
    let notApproved = allRecruiters.data.data.filter(
      (item) => !item.isApproved && item.isApproved === null
    );

    setallNotApprovedRecruiters(notApproved);
    setallapprovedRecruiters(approved);
    setallRevokedRecruiters(revoked);


  };

  const approveRecruiterHandler = async () => {
    setApvBtn(true);
    let payload = {
      userid: recruiterData.id,
      approval: true,
      recruitmentpartnerId: recruiterData.organizationId,
      roleId: recruiterData.userRole,
      roleCode: recruiterData.roleCode,
      agencyId: selectedAgency?.id,
    };

    try {
      if (
        // isRecruiter &&
        payload.recruitmentpartnerId &&
        payload.roleId &&
        selectAgencyType &&
        selectedAgency
      ) {
        let acceptRecruiter = await approveRecruitersResponse(payload);
        await getAllRecruiters();
        setOpenApproveDialogue(false);
        if (acceptRecruiter.status === 208 || acceptRecruiter.status === 200 || acceptRecruiter.status === 202 || acceptRecruiter.status === 500) {
          NotificationManager.error(
            `${acceptRecruiter.data.message}`,
            "Error",
            2500
          );
          setApvBtn(false);
        } else {
          NotificationManager.success(
            `${acceptRecruiter.data.message}`,
            "Success",
            2500
          );
          setIsRecruiter(false);
          setSelectAgencyType(null);
          setSelectedAgency(null);
        }
      }
      else {
        setOpenApproveDialogue(true);
        NotificationManager.error("Please select all fields", "Error", 2500);
        setApvBtn(false);
      }
    } catch (error) {
      setOpenApproveDialogue(false);
      NotificationManager.error("Approval failed", "Error", 2500);
      setApvBtn(false);
    }
  };

  // ------------------- Revoke Recuiter Dialog Box Button Function -------------

  const revokeRecruiterHandler = async () => {
    setApvBtn(true);
    let payload = {
      userid: recruiterData.id,
      approval: false,
      recruitmentpartnerId: recruiterData.organizationId,
      //new parameters
      roleId: recruiterData.userRole,
      roleCode: recruiterData.code,
    };
    try {
      // Sending false value to isApproved via approveRecruiter API
      // let revokeRecruiter =
      await approveRecruitersResponse(payload);
      await getAllRecruiters();
      NotificationManager.success("Recruiter revoked", "Success", 2500);
    } catch (error) {
      NotificationManager.error("Revoking failed", "Error", 2500);
    }
    setOpenRevokeDialogue(false);
    setApvBtn(false);
  };

  //-------------------------------- END -------------------------------

  const tabClicked = (name) => {
    settabName(name);
  };
  const openApproveModal = async (e, value) => {
    setRecruiterData(value);
    await getOrganization();
    setEmail(value.email);
    setApvBtn(false);
    setOpenApproveDialogue(true);
  };
  const closeApproveModal = () => {
    setRecruiterData({});
    setOpenApproveDialogue(false);
    setSelectedAgency(null);
    setIsRecruiter(false);
    setRecruitmentParterId("");
  };

  // --------------------- Revoke Modal Open----------------

  const openRevokeModal = async (e, value) => {
    setRecruiterData(value);
    await getOrganization();
    setEmail(value.email);
    setApvBtn(false);
    setOpenRevokeDialogue(true);
  };
  const closeRevokeModal = () => {
    setRecruiterData({});
    setOpenRevokeDialogue(false);
  };
  //-------------------------------- END -------------------------------

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getAllRoles();
        await getAllRecruiters();
        await geAllAgencyType();
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();

  }, []);

  const userRoleChangeHandler = (e, value) => {
    console.log(value, "valuevalue")
    /* Get User role from here */
    if (value) {
      console.log(value);
      setRecruiterData((item) => ({
        ...item,
        userRole: value.id,
        roleCode: value.code
      }));
    }
  };

  const userRoleChanger = (e, value) => {
    if (value === "Recruiter") {
      setIsRecruiter(true);
    } else {
      setIsRecruiter(false);
    }

    if (value === "") {
      setRecruiterData((item) => ({
        ...item,
        userRole: undefined,
      }));
    }
  };

  const organizationChangeHandler = (e, value) => {
    /** Get user recruitment parner id from here */
    if (value) {
      setRecruitmentParterId(value?.id);
      setRecruiterData((item) => ({
        ...item,
        organizationId: value.id,
      }));
      setSelectedAgency(null)
    }
  };
  const onAgencyChange = (e, value) => {
    setSelectAgencyType(value?.code || "");
    setSelectedAgency(null)
  };

  const organizationChanger = (e, value) => {
    if (value === "") {
      console.log(value);
      setRecruiterData((item) => ({
        ...item,
        organizationId: undefined,
      }));
    }
  };

  useEffect(() => {
    if (!selectAgencyType) {
      setSelectedAgency(null);
    }
  }, [selectAgencyType]);
  const internalExternalChangeHandler = (e, value) => {
    setSelectedAgency(value);
  };

  const getOrganization = async () => {
    let allOrganization = await getAllActiveRecruitmentpartner();
    setAllOrganization(allOrganization.data.data);
  };

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={true} />
      {userType === "Admin" ? (
        <div>
          <div className="section-wrapper">
            <div className="container w1200 flex-to-footer">
              <div className="height_adjust mt_100">
                <div className="container w1200 pageWidth">
                  <div className="card talent_community">
                    <div>
                      <p className="fs-semi-bold fs-30 f-color mb_16">
                        Sign-Up Requests
                      </p>
                    </div>
                    <div className="tab_header">
                      <div className="df">
                        <div
                          className={`tab_button ${tabName === "Pending" ? "tab_button_active" : ""
                            }`}
                          onClick={() => tabClicked("Pending")}
                        >
                          Pending
                        </div>
                        <div
                          className={`tab_button ${tabName === "Approved" ? "tab_button_active" : ""
                            }`}
                          onClick={() => tabClicked("Approved")}
                        >
                          Approved
                        </div>
                        <div
                          className={`tab_button ${tabName === "Revoked" ? "tab_button_active" : ""
                            }`}
                          onClick={() => tabClicked("Revoked")}
                        >
                          Revoked
                        </div>
                      </div>
                      <div>
                        {/* <div className='my_talent_search_container' id="tabOneDisplay"><input type="text" placeholder="Search.." /></div> */}
                      </div>
                    </div>
                    {tabName === "Approved" ? (
                      <AllapprovedRecruitersKendoTable
                        allapprovedRecruiters={allapprovedRecruiters}
                        isDataLoaded={isDataLoaded}
                      />
                    ) : tabName === "Revoked" ? (
                      <AllRevokedRecruitersKendoTable
                        allRevokedRecruiters={allRevokedRecruiters}
                        isDataLoaded={isDataLoaded}
                      />
                    ) : (
                      <AllNotApprovedRecruitersKendoTable
                        allNotApprovedRecruiters={allNotApprovedRecruiters}
                        approveRecruiterHandler={openApproveModal}
                        revokeRecruiterHandler={openRevokeModal}
                        isDataLoaded={isDataLoaded}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            open={openApproveDialogue}
            onClose={closeApproveModal}
            aria-labelledby="customized-dialog-title"
          >
            <Grid item xs={12}>
              <div className="Recruiter_Private_Skill_Modal">
                <DialogTitle
                  id="customized-dialog-title"
                  className="admindailog_title_modal"
                  onClose={closeApproveModal}
                >
                  Approve Sign-up Request
                  <IconButton
                    aria-label="close"
                    style={closeButton}
                    onClick={closeApproveModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
              </div>
            </Grid>
            <DialogContent
              className={`${classes.bodyForRemove} removemodal_responsive`}
            >
              <>
                <div className="select customized-select options">
                  <label className="field_name" htmlFor="country">
                    User Role*
                  </label>
                  <Autocomplete
                    autoSelect
                    id="combo-box-demo"
                    className="mt_8"
                    options={userRole}
                    getOptionLabel={(option) => option.roleName || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select User Type"
                      />
                    )}
                    onChange={userRoleChangeHandler}
                    onInputChange={userRoleChanger}
                  />
                </div>
                <div className="select customized-select options">
                  <label className="field_name" htmlFor="country">
                    Recruitment Organization*
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    className="mt_8"
                    options={AllOrganization}
                    autoHighlight
                    // defaultValue={RecruitmentParterId}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={organizationChangeHandler}
                    onInputChange={organizationChanger}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required={true}
                        placeholder="Select Recruitment Organization"
                      />
                    )}
                  />
                </div>

                {/* {isRecruiter && (
                  <> */}
                <div className="select customized-select options">
                  <label className="field_name" htmlFor="country">
                    Agency Type*
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    className="mt_8"
                    options={agencies}
                    autoHighlight
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.code || ""}
                    onChange={onAgencyChange}
                    onInputChange={organizationChanger}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required={true}
                        placeholder="Select Agency Type"
                      />
                    )}
                  />
                </div>
                <div className="select customized-select options">
                  <label className="field_name" htmlFor="country">
                    Agency*
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    className="mt_8"
                    options={agencyTypeData ? agencyTypeData : []}
                    value={selectedAgency}
                    autoHighlight
                    // filterOptions={filterOptions}
                    getOptionLabel={(option) => option?.agencyName || ""}
                    onChange={internalExternalChangeHandler}
                    // onInputChange={organizationChanger}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required={true}
                        placeholder={` select ${selectAgencyType} Agency `}
                      />
                    )}
                  />
                </div>
                {/* </>
                )} */}
              </>
              <div className="text-center fs-semi-bold f-color fs-18 mt_20">
                Do you want to approve the Sign Up of the customer ({email}) ?
              </div>
            </DialogContent>
            <DialogActions
              className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
            >
              <div className="remove_modal revoke_adjust">
                <button
                  type="button"
                  className="btn_remove btn4 btn-skill cp"
                  onClick={closeApproveModal}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn4 btn_primary ml_5 cp"
                  onClick={approveRecruiterHandler}
                  disabled={apvBtn}
                >
                  <div className="btn_loader">
                    Yes
                    &nbsp;
                    {apvBtn &&
                      <div>
                        <CircularProgress size={13} />
                      </div>
                    }
                  </div>
                </button>
              </div>
            </DialogActions>
          </Dialog>

          {/* -------------------    Confirmation Dialog Box for Revoking Recruiters -------------- */}

          <Dialog
            open={openRevokeDialogue}
            onClose={closeRevokeModal}
            aria-labelledby="customized-dialog-title"
          >
            <DialogContent
              className={`${classes.bodyForRemove} removemodal_responsive`}
            >
              <div className="text-center fs-semi-bold f-color fs-18 mt_20">
                Do you want to revoke this customer ({email}) ?
              </div>
            </DialogContent>
            <DialogActions
              className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
            >
              <div className="remove_modal revoke_adjust">
                <button
                  type="button"
                  className="btn_remove btn4 btn-skill cp"
                  onClick={closeRevokeModal}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn4 btn_primary ml_5 cp"
                  onClick={revokeRecruiterHandler}
                  disabled={apvBtn}
                >
                  <div className="btn_loader">
                    Yes
                    &nbsp;
                    {apvBtn &&
                      <div>
                        <CircularProgress size={13} />
                      </div>
                    }
                  </div>
                </button>
              </div>
            </DialogActions>
          </Dialog>

          <NotificationContainer />
        </div>
      ) : (
        <>
          <div className="row padding_80 text-center ">
            <p className="fs-semi-bold fs-40 f-color authorize">
              You Are Not Authorized To Visit This Page
            </p>
          </div>
        </>
      )
      }
    </>
  );
};

export default Recruiters;
