import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import GetRecentActivitiesGraph from '../../../apis/GetRecentActivitiesGraph/GetRecentActivitiesGraph';
import { CircularProgress } from '@material-ui/core';

const LineChart = (props) => {
  const {
    id, userType, userRole
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    x: ['SUBMITTED', 'INTERVIEW', 'SELECTED', 'STARTED'],
    y: [], // Sample y values
    type: 'bar',
    marker: {
      color: ['#30B1F5', '#F3EABD', '#FEBFBD', '#1AD5BB', '#353535'],

    },
    width: 0.4 // Make bars thinner
  });

  const layout = {
  };

  const userId = id
  const role = (userType === 'Admin') ? userRole : userType;

  useEffect(() => {
    setIsLoading(true); // Start loading

    function addGraphDataToData(graphData) {
      const newData = {
        type: 'bar',
        marker: {
          color: ['#30B1F5', '#F3EABD', '#FEBFBD', '#1AD5BB', '#353535'],
        },
        width: 0.4,
        x: [],
        y: [],
      };
      graphData.forEach(item => {
        item._id === 'INTERVIEW' ? newData.x.push(item._id + ' SCHEDULED') : newData.x.push(item._id);;
        newData.y.push(item.totalQuantity);
      });
      return newData;
    }

      GetRecentActivitiesGraph({ id: userId, role: role })
      .then((res) => {
        const modifiedData = addGraphDataToData(res.data.data);
        setData(modifiedData);
        setIsLoading(false); // Stop loading
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false); // Stop loading in case of error
      });
  }, [userId, role]);

 

  return (
    <div style={{ width: '100%', margin: 'auto' }}>
      {
        isLoading ? (
          <div
            style={{
              height: '40vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 3px 8px #0000003d',

            }}
          ><CircularProgress /></div>
        ) : (
          <Plot
            data={[data]}
            layout={layout}
            style={{ width: '100%', height: '100%' }}
          />
        )
      }
    </div>
  );
};

export default LineChart;
