import { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    FormControl,
    CircularProgress
} from "@material-ui/core";
import department from './icons/department.png';
import './manageClient.css';
import { Autocomplete } from "@material-ui/lab";
import ManageDivisionTable from "../../components/manageDivisionTable/manageDivisionTable.js";
import Header from "../../components/headerAdmin/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import checkRequisitionExists from "../../apis/checkRequisitionExists";

import {
    addDivision,
    getRecruiterDetails,
    getMisOperator,
    updateDivision,
    getDivisionByOrg,
    getRecruitmentLeaderbyorg
}
    from "../../apis/organisationAdminAPI/manageDivision";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BasicHeader from "../../components/basicHeader/BasicHeader";

const ManageDivision = (props) => {
    const [userIdType, setUserIdType] = useState(props?.location?.state?.id ? props?.location?.state?.id : props?.id);
    const [userRecruitmentpartnerId, setUserRecruitmentpartnerId] = useState(props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId);
    const [userType, setUserType] = useState(props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType);
    const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props?.id;
    const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props.userRecords;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [divisionId, setDivisionId] = useState();
    const [divisionData, setDivisionData] = useState([]);
    const [recruiterOptions, setRecruiterOptions] = useState([]);
    const [recruitmentLeaderOptions, setRecruitmentLeaderOptions] = useState([]);
    const [misOperator, setMisOperator] = useState();
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [editedRow, setEditedRow] = useState(null);
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [isActiveFieldDisabled, setIsActiveFieldDisabled] = useState(true)
    const [divisionInfo, setDivisionInfo] = useState({
        divisionName: "",
        recruitmentLeaderID: "",
        recruitmentLeader: "",
        selectedRecruiterId: [],
        selectedRecruiter: [],
        active: { name: "True", value: true },
    })


    const handleOpenDialog = () => {
        setOpenDialog(true);
        setEditedRow(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow(null);
        setDivisionInfo({
            divisionName: "",
            recruitmentLeader: "",
            selectedRecruiter: [],
            active: { name: "True", value: true }
        })
        setIsActiveFieldDisabled(true);
    };


    const handleSubmit = async () => {
        if (editedRow) {
            if (divisionInfo.divisionName === "" ||
                divisionInfo.recruitmentLeaderID === undefined ||
                divisionInfo.recruitmentLeaderID === "" ||
                divisionInfo.selectedRecruiter.length === 0 ||
                divisionInfo.recruitmentLeader.length === 0 ||
                divisionInfo.active === undefined
            ) {
                return NotificationManager.error(
                    "Please fill in all the fields",
                    "Error",
                    2500
                );
            } else {
                let payload = {
                    "divisionName": divisionInfo.divisionName,
                    "recruitmentpartnerId": userRecruitmentpartnerId,
                    "recruiterLeaderId": divisionInfo.recruitmentLeaderID,
                    "recruiterid": divisionInfo.selectedRecruiterId,
                    "isActive": divisionInfo.active.value,
                }
                try {
                    setIsUserDataUpdating(true)
                    const response = await updateDivision(editedRow._id, payload);
                    allDivisonData();
                    fetchManageDivisionDetails();
                    setIsUserDataUpdating(false)
                    if (response.status === 200) {
                        NotificationManager.success("Division details edited sucessfullly", "Success", 2500);
                        handleCloseDialog();
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    console.log(error)
                    if(error.response.status === 400 && error.response.data.errorCode === 400){
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
        }
        else {
            if (divisionInfo.divisionName === "" ||
                divisionInfo.recruitmentLeaderID === undefined ||
                divisionInfo.recruitmentLeaderID === "" ||
                divisionInfo.selectedRecruiter.length === 0 ||
                divisionInfo.active === undefined
            ) {
                return NotificationManager.error(
                    "Please fill in all the fields",
                    "Error",
                    2500
                );
            } else {
                let payload = {
                    "divisionName": divisionInfo.divisionName,
                    "recruitmentpartnerId": userRecruitmentpartnerId,
                    "orgAdminId": userIdType,
                    "misOperatorId": "",
                    "recruiterLeaderId": divisionInfo.recruitmentLeaderID,
                    "recruiterid": divisionInfo.selectedRecruiterId,
                    "isActive": divisionInfo.active.value,
                }
                try {
                    setIsUserDataUpdating(true)
                    const response = await addDivision(payload);
                    allDivisonData();
                    fetchManageDivisionDetails();
                    setIsUserDataUpdating(false)
                    if (response.status === 200) {
                        NotificationManager.success("Division has been added sucessfullly", "Success", 2500);
                        handleCloseDialog();
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    console.log(error)
                    if(error.response.status === 400 && error.response.data.errorCode === 400){
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
            handleCloseDialog();
        }
    };
    const allDivisonData = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            setisDataLoaded(false)
            let response = await getDivisionByOrg(payload);
            if (response.status === 200) {
                setDivisionData(response.data.data);
            }
            setisDataLoaded(true)
        } catch (error) {
            setisDataLoaded(false)
            setDivisionData([]);
            console.log(error);
        }
    };
    const fetchManageDivisionDetails = async () => {

        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            // let misOperator = await getMisOperator(userRecruitmentpartnerId);
            // if (misOperator.status === 200) {
            //     // setMisOperator(misOperator.data.data?.id);
            //     setMisOperator(misOperator?.data?.data[0]?.id)
            // }
            let recruiterDetails = await getRecruiterDetails(payload);
            if (recruiterDetails.status === 200) {
                setRecruiterOptions(recruiterDetails.data.data);
            }
            let recruitmentLeaderOptions = await getRecruitmentLeaderbyorg(payload);
            if (recruitmentLeaderOptions.status === 200) {
                setRecruitmentLeaderOptions(recruitmentLeaderOptions.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        allDivisonData()
        fetchManageDivisionDetails()
    }, [])

    const handleDivisonName = (e) => {
        if (e.target.value) {
            setDivisionInfo({ ...divisionInfo, divisionName: e.target.value })
        } else {
            setDivisionInfo({ ...divisionInfo, divisionName: "" })
        }
    }
    const handleRecruitmentLeader = (e, value) => {
        setDivisionInfo({ ...divisionInfo, selectedRecruiterId: [], selectedRecruiter: [] })
        if (value) {
            setDivisionInfo({ ...divisionInfo, recruitmentLeader: value, recruitmentLeaderID: value?.id })
        } else {
            setDivisionInfo({ ...divisionInfo, recruitmentLeader: "", recruitmentLeaderID: "" })
        }
    }

    const handleRecruiter = (e, value) => {
        let recruiterId = value.map(item => item.id);
        setDivisionInfo({ ...divisionInfo, selectedRecruiterId: recruiterId, selectedRecruiter: value })
    }
    const handleActiveChange = async (e, value) => {
        setDivisionInfo({ ...divisionInfo, active: value })
    }
    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleEdit = async (rowData) => {
        setDivisionId(rowData._id);
        setDivisionInfo({
            ...divisionInfo,
            divisionName: rowData.divisionName,
            recruitmentLeaderID: rowData.recruiterLeader[0]?.id,
            recruitmentLeader: rowData.recruiterLeader[0],
            selectedRecruiterId: rowData.recruiterid,
            selectedRecruiter: rowData.recruiter,
            active: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            }
        })
        setEditedRow(rowData); // Set the row data when edit button is clicked
        handelEditOpenDialog(); // Open the dialog
        const dataRes = rowData ? await checkRequisitionExists(rowData?._id, 'Division') : null;
        const requisitionExists = dataRes?.data?.data?.length;
        if (requisitionExists > 0 && rowData) {
            setIsActiveFieldDisabled(true)
        }
        else {
            setIsActiveFieldDisabled(false)
        }
    };
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecords,
                roleName: userRole
            }
        });
    }
    return (
        <>
            {(userType === "Organization Admin") ?
                <>
                    <Header />

                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-client-main">
                                <div className="header-client-manager">
                                    <div className="header-image-manage-client">
                                        <Typography className="manage-client-main-head">Manage Division</Typography>
                                    </div>
                                    {userType === 'Admin' ? "" :
                                        <Button className="add-button-manageclient"
                                            onClick={handleOpenDialog}
                                            startIcon={<img src={department} alt="Add Client" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add Division
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                {/* Add here kendo table */}
                                <ManageDivisionTable divisionData={divisionData} isDataLoaded={isDataLoaded} onEdit={handleEdit} userType={userType} />

                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
            <Dialog
                size="xs"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="xs"
                className="add-division"
            >
                <DialogTitle id="dialog-title" className="dialog-title">
                    <img src={department} alt="Manage division" className="iconStyle" />
                    <span className="dialog-title-text">{editedRow ? 'Edit Division' : 'Add New Division'}</span>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Division Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Division Name"
                                value={divisionInfo.divisionName}
                                onChange={handleDivisonName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Recruitment Leader*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={divisionInfo.recruitmentLeader}
                                options={recruitmentLeaderOptions}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Recruitment Leader"
                                    />
                                )}
                                onChange={handleRecruitmentLeader}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Recruiter*</Typography>

                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    multiple
                                    id="combo-box-demo"
                                    options={divisionInfo.recruitmentLeader ? recruiterOptions.filter(item => !divisionInfo.selectedRecruiter.some(selected => selected.id === item.id)) : []}
                                    getOptionLabel={(option) => option.email}
                                    value={divisionInfo.selectedRecruiter}
                                    disableClearable
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Recruiters"
                                            style={{ backgroundColor: '#fff', color: '#000', borderRadius: 4, borderColor: '#31b3f8' }}
                                        />
                                    )}
                                    onChange={handleRecruiter}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={divisionInfo.active}
                                options={activeDropDown}
                                disabled={editedRow ? isActiveFieldDisabled : false}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className="dialog-button">
                        <button
                            className={isUserDataUpdating ? 'updateButton_OrgAdmin_disabled' : 'updateButton_OrgAdmin'}
                            onClick={handleSubmit}
                            disabled={isUserDataUpdating}
                        >
    
                            <span>Save</span>
                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton"/>) : null}
                        </button>
                        <button onClick={handleCloseDialog} className="dialog-cancel-button">
                            Cancel
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    )
}

export default ManageDivision;