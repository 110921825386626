import React, { useContext, useState } from 'react'
import Header from "../headerAdmin/Header.js"
import Footer from "../adminFooter/Footer.js";
import './requisitionReports.css'
import { ArrowBack, SignalCellularAlt } from '@material-ui/icons';
import JobReport from './JobReport.js';
import SubmissionReport from './SubmissionReport.js';
import WorkReport from './WorkReport.js';
import RecruitersActivity from './RecruitersActivity.js';
import ExcelImg from "../../components/basicInfoForm/images/excelIcon.jpg";
import { useHistory } from "react-router";
import { UserTypeContext } from "../../AppRoute";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BasicHeader from '../basicHeader/BasicHeader.js';

const RequisitionReports = (props) => {

    const [selectedTab, setSelectedTab] = useState('Job Report')
    const history = useHistory();
    const [userType, setUserType] = useState(props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType)
    const [userIdType, setUserIdType] = useState(props?.location?.state?.id ? props?.location?.state?.id : props?.id)
    const [userRecruitmentpartnerId, setUserRecruitmentpartnerId] = useState(props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : props?.userRecruitmentpartnerId)
    const [userRecords, setUserRecords] = useState(props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords)
    const [userRole, setUserRole] = useState(props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType)
    const [modalIsOpen, setmodalIsOpen] = useState(false);

    const [startSelectedDate, setStartSelectedDate] = useState(new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000));
    const [endSelectedDate, setEndSelectedDate] = useState(new Date());
    const [triggerWorkReportAction, setTriggerWorkReportAction] = useState(false);
    const [triggerJobReportAction, setTriggerJobReportAction] = useState(false);
    const [triggerSubmissionReportAction, setTriggerSubmissionReportAction] = useState(false);
    const [triggerRecruiterActivityReportAction, setTriggerRecruiterActivityReportAction] = useState(false);
    const gotoDashBoard = () => {
        if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
            history.push({
                pathname: "/requisition-dashboard",
                state: { id: userIdType, recruitementPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
            });
        } else {
            history.push({
                pathname: "/dashboard",
                state: {
                    userType: userType,
                    recruitmentPartnerId: userRecruitmentpartnerId,
                    id: userIdType,
                    userRecords: userRecords,
                    roleName: userRole
                }
            });
        }

    }

    const closeModal = () => {
        setmodalIsOpen(false);
    }

    const openModel = () => {
        setmodalIsOpen(true);
    }



    const exportExcel = () => {

        const formattedDatestart = startSelectedDate.toISOString();
        const formattedDateend = endSelectedDate.toISOString();

        // Calculate time difference in milliseconds
        const timeDiff = new Date(formattedDateend).getTime() - new Date(formattedDatestart).getTime();

        // Convert time difference to days
        const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));

        if (diffDays <= 365 && diffDays > -1) {
            setmodalIsOpen(false); // Close the modal
            if (selectedTab === 'Work Report') {
                setTriggerWorkReportAction(true); // This triggers the child action
            } else if (selectedTab === 'Job Report') {
                setTriggerJobReportAction(true);
            } else if (selectedTab === 'Submission Report') {
                setTriggerSubmissionReportAction(true);
            } else if (selectedTab === "Recruiter's Activity") {
                setTriggerRecruiterActivityReportAction(true);
            }
        } else {
            NotificationManager.error(
                "Duration of Selected date should be  5 days",
                "Error",
                2500
            );
        }
    }

    const startDateChangeHandler = (date) => {
        setStartSelectedDate(date);
    };

    const endDateChangeHandler = (date) => {
        setEndSelectedDate(date);
    }



    return (<>

        {((userType === "Admin") || (userType === "Recruitment Leader") || (userType === "Organization Admin") || (userType === "MIS Operator")) ?
            <>

                <div className='mainContainer_requisitionReports'>
                    <div className='topContainer_requisitionReports'>
                        <Header />
                        <div className='mainDiv_requisitionReports'>
                            <div className='titleContainer_requisitionReports'>
                                <span className='title'>Reports</span>
                            </div>
                            <div className='gobackContainer_requisitionReports'>
                                <div className="goBackToDashboard_requisitionReports" onClick={(e) => gotoDashBoard()}>
                                    <ArrowBack />&nbsp; Go Back to Dashboard
                                </div>
                                <div className='dateRangeContainer_requisitionReports'>
                                    <span className='dateRangeContainer_requisitionReports_text'>Date Range : </span>
                                    <div className='datePicker_Container'>
                                        <DatePicker
                                            className="createRequisition_textbox_datapicker_reports"
                                            selected={startSelectedDate}
                                            onChange={startDateChangeHandler}
                                            value={startSelectedDate}
                                            dateFormat="MM/dd/yyyy"
                                            showIcon
                                        // minDate={new Date()}
                                        />
                                    </div>
                                    <span className='dateRangeContainer_requisitionReports_text'>to</span>
                                    <div className='datePicker_Container'>
                                        <DatePicker
                                            className="createRequisition_textbox_datapicker_reports"
                                            selected={endSelectedDate}
                                            onChange={endDateChangeHandler}
                                            value={endSelectedDate}
                                            dateFormat="MM/dd/yyyy"
                                            showIcon
                                        // minDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='tabsMainContainer_requisitionReports'>
                                <div className='tabsContainer_requisitionReports'>
                                    <div className={selectedTab === 'Job Report' ? 'tabs_requisitionReports_selected' : 'tabs_requisitionReports'} onClick={(e) => setSelectedTab('Job Report')}>Job Report</div>
                                    <div className={selectedTab === 'Submission Report' ? 'tabs_requisitionReports_selected' : 'tabs_requisitionReports'} onClick={(e) => setSelectedTab('Submission Report')}>Submission Report</div>
                                    <div className={selectedTab === 'Work Report' ? 'tabs_requisitionReports_selected' : 'tabs_requisitionReports'} onClick={(e) => setSelectedTab('Work Report')}>Work Report</div>
                                    <div className={selectedTab === "Recruiter's Activity" ? 'tabs_requisitionReports_selected' : 'tabs_requisitionReports'} onClick={(e) => setSelectedTab("Recruiter's Activity")}>Recruiter's Activity</div>
                                    {/* <button className='excelButton_requisitionReports' onClick={(e) => openModel()}><span>Export</span></button> */}
                                </div>
                                <Button className='excelButton_requisitionReports_dialog' onClick={(e) => exportExcel()}>
                                    <div className='excelButtonContainer_reports'>
                                        <img src={ExcelImg} className='excelImg_requisitionReports' />
                                        <span className='exportText_Dialog_reports'>Export</span>
                                    </div>

                                </Button>
                            </div>

                            <div className='kendoContainer_requisitionReports'>
                                {selectedTab === 'Job Report' && <JobReport userType={userType === 'Admin' ? userRole : userType} userIdType={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords}
                                    triggerAction={triggerJobReportAction} resetTrigger={() => setTriggerJobReportAction(false)} startSelectedDate={startSelectedDate} endSelectedDate={endSelectedDate} />}
                                {selectedTab === 'Submission Report' && <SubmissionReport userType={userType === 'Admin' ? userRole : userType} userIdType={userIdType} initator={"requisitionReport"} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords}
                                    triggerAction={triggerSubmissionReportAction} resetTrigger={() => setTriggerSubmissionReportAction(false)} startSelectedDate={startSelectedDate} endSelectedDate={endSelectedDate} />}
                                {selectedTab === 'Work Report' && <WorkReport userType={userType === 'Admin' ? userRole : userType} userIdType={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords}
                                    triggerAction={triggerWorkReportAction} resetTrigger={() => setTriggerWorkReportAction(false)} startSelectedDate={startSelectedDate} endSelectedDate={endSelectedDate} />}
                                {selectedTab === "Recruiter's Activity" && <RecruitersActivity userType={userType === 'Admin' ? userRole : userType} userIdType={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords}
                                    triggerAction={triggerRecruiterActivityReportAction} resetTrigger={() => setTriggerRecruiterActivityReportAction(false)} startSelectedDate={startSelectedDate} endSelectedDate={endSelectedDate} />}

                            </div>


                        </div>
                    </div>

                    <div className='bottomContainer_requisitionReports'>
                        <Footer />
                    </div>

                </div>

                <NotificationContainer />

            </>
            :
            <>
                <div className='Unauthorized_container'>
                    <BasicHeader />
                    <div className="row padding_80 text-center">
                        <div className='UnAuthorized_contain'>
                            <p className="fs-semi-bold fs-40 f-color authorize">
                                You Are Not Authorized To Visit This Page
                            </p>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
    )
}

export default RequisitionReports