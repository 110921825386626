import React, { useState, useCallback, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid as GridDiv,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
import submissionReports from "../../apis/requisitionReports/submissionReport";
import { UserTypeContext } from "../../AppRoute";
import { DateTime } from "luxon";
import "./requisitionReports.css";
import * as XLSX from "xlsx";

import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const useStyles = makeStyles((theme) => ({
  odd: {
    background: "#F6F6F6 !important",
  },
  even: {
    background: "#fffff !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  waitingText: {
    marginLeft: "5px",
  },
}));

const SubmissionReport = (props) => {
  const {
    userType,
    userIdType,
    userRecruitmentpartnerId,
    userRecords,
    triggerAction,
    resetTrigger,
    startSelectedDate,
    endSelectedDate,
    trackCandidateSearch,
    initiator,
    trackCandidateSearchEmail,
  } = props;
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const [dataResult, setDataResult] = useState([]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    setIsSearching(true);
    setDataResult([]);
    const terms =
      filters && filters.length > 0
        ? filters
            .filter(({ value }) => value && value !== "") // Filter out objects where value is empty, null, or white spaces
            .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];

    setSearchTerms(terms);

    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    setIsSearching(true);
  };
  useEffect(() => {
    if (triggerAction) {
      callApiToGenerateExcel(); // Perform the action
      resetTrigger(); // Reset the trigger to avoid repeated execution
    }
  }, [triggerAction, resetTrigger]);
  useEffect(() => {
    let setDefaultValue = {
      skip: 0,
      take: 10,
      filter: {
        logic: "and",
        filters: [],
      },
    };
    setDataState(setDefaultValue);
  }, []);


  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Get only the YYYY-MM-DD part
  }


  const callApiToGenerateExcel = async () => {
    setIsExporting(true);
    // const formattedDatestart = startSelectedDate.toISOString();
    // const formattedDateend = endSelectedDate.toISOString();
    let isLeader = false;
    if (userType === "Recruitment Leader") {
      isLeader = true;
    }
    let maxSize = 100;
    let end = Math.ceil(totalResult / maxSize);
    let pageStart = 1;
    let pageEnd = maxSize;
    let exportArr = [];
    for (let start = 0; start < end; start++) {
      let payload = {
        orgId: userRecruitmentpartnerId,
        isExport: true,
        page: pageStart,
        size: maxSize,
        formattedDatestart: formatDateOnly(startSelectedDate),
        formattedDateend: formatDateOnly(endSelectedDate),
        userId: userIdType,
        isLeader: isLeader,
      };
      const allDetails = await submissionReports(payload);
      allDetails.data.forEach((element) => {
        let data = {
          Recruiter: element.recruiter,
          "Submitted Date": element.created_at,
          "Status Change Date": element.displayDate,
          "Candidate Name": element.name,
          Email: element.email,
          Client: element.client,
          Status: element.status,
          "Reference ID": element.reqreferenceId,
          "Country": element.countryStr,
          "State": element.stateStr,
          "City": element.cityStr,
          "Offer Released Date": element.offerRealeaseDate,
          "Interview Date": element.interviewDate,
          "Start Date": element.startDate,
          "Primary Skill": element.primarySkill,
          Phone: element.phone,
          "Activity Date": element.updated_at,
          "Drop Out Notes": element.dropOutNotes,
          "Person of Contact": element.recruiterLeader,
          Source: element.source,
        };
        exportArr.push(data);
      });
      pageStart = pageStart + maxSize;
    }

    if (exportArr.length === 0) {
      NotificationManager.error("No data present", "Error", 2500);
      setIsExporting(false);
    } else {
      const MAX_ROWS_PER_SHEET = 500;

      const wb = XLSX.utils.book_new();

      // Calculate the number of sheets needed
      const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

      // Loop to create sheets
      for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
        // Get a subset of data for each sheet
        const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
        const endRow = Math.min(
          (sheetIndex + 1) * MAX_ROWS_PER_SHEET,
          exportArr.length
        );
        const subsetData = exportArr.slice(startRow, endRow);

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(subsetData);

        // Append the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
      }

      // Save the workbook to an Excel file
      XLSX.writeFile(wb, `SubmissionReport.xlsx`, {
        bookType: "xlsx",
        type: "file",
      });
      setIsExporting(false);
    }

    // Call the API to generate the Excel file
  };

  const debouncedSearch = useCallback(
    debounce(
      async (
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userId,
        isLeader
      ) => {
        setLoading(true);
        let payload = {
          orgId: userRecruitmentpartnerId,
          page: pageNumber,
          size: takeNumberOfResult,
          formattedDatestart: formatDateOnly(startSelectedDate),
          formattedDateend: formatDateOnly(endSelectedDate),
          isExport: false,
          userId: userId,
          isLeader: isLeader,
        };
        if (trackCandidateSearchEmail) {
          payload.candidateEmail = trackCandidateSearchEmail;
        }
        const search = await submissionReports(payload);

        if (search.data.length <= 0) {
          setLoading(false);
          setDataResult(search.data);
          setTotalResult(0);
          setIsSearching(false);
        }
        if (search.data.length > 0) {
          setDataResult(search.data);

          if (search.totalCount[0].total)
            setTotalResult(search.totalCount[0].total);
          setIsSearching(false);
        }
      },
      1000
    ),
    []
  );

  useEffect(() => {
    if((!startSelectedDate || !endSelectedDate) && initiator !=="candidateHistory"){
      NotificationManager.error(
        "Please enter a valid date",
        "Error",
        2500
    );
    }
    else{
      setIsSearching(true);
      setDataResult([]);
      let isLeader = false;
      if (userType === "Recruitment Leader") {
        isLeader = true;
      }
  
      debouncedSearch(
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userIdType,
        isLeader
      );
    }

    
  }, [
    searchTerms,
    pageNumber,
    takeNumberOfResult,
    startSelectedDate,
    endSelectedDate,
  ]);

  const jobReportSkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push(
        <Skeleton
          className={i % 2 === 0 ? classes.odd : classes.even}
          variant="rect"
          width="100%"
          height="50px"
        />
      );
    }
    return arr;
  };
  return (
    <>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
        <Typography className={classes.waitingText}>
          {" "}
          Please wait ...
        </Typography>
      </Backdrop>
      <GridDiv
        filterable={false}
        filter={dataState.filter}
        ortable={false}
        resizable={false}
        reorderable={false}
        pageable={{
          pageSizes: [10, 20, 50, 100],
          info: true,
          previousNext: true,
          buttonCount: 10,
        }}
        skip={dataState.skip}
        take={dataState.take}
        //    filterOperators={filterOperators}
        onDataStateChange={dataStateChange}
        data={dataResult}
        total={totalResult}
        className="kendo_reports"
      >
        <GridNoRecords>
          {isSearching ? jobReportSkeleton() : "No results found !"}
        </GridNoRecords>
        <GridColumn field="recruiter" title="Recruiter" width="180px" />

        <GridColumn field="created_at" title="Submitted Date" width="180px" />
        <GridColumn
          field="displayDate"
          title="Status Change Date"
          width="180px"
        />
        <GridColumn field="name" title="Candidate Name" width="180px" />
        {trackCandidateSearch ? (
          ""
        ) : (
          <GridColumn field="email" title="Email" width="180px" />
        )}
        <GridColumn field="client" title="Client" width="180px" />
        <GridColumn field="status" title="Status" width="180px" />

        <GridColumn field="reqreferenceId" title="Reference ID" width="180px" />

        {trackCandidateSearch ? (
          ""
        ) : (
          <>
            <GridColumn field="city" title="City" width="180px" />
            <GridColumn field="state" title="State" width="180px" />
            <GridColumn field="country" title="Country" width="180px" />
          </>
        )}

        <GridColumn
          field="offerRealeaseDate"
          title="Offer Released Date"
          width="180px"
        />

        <GridColumn
          field="interviewDate"
          title="Interview Date"
          width="180px"
        />
        <GridColumn field="startDate" title="Start Date" width="180px" />
        <GridColumn field="primarySkill" title="Primary Skill" width="180px" />
        {trackCandidateSearch ? (
          ""
        ) : (
          <>
            <GridColumn field="phone" title="Phone" width="180px" />
            <GridColumn
              field="updated_at"
              title="Activity Date"
              width="180px"
            />
          </>
        )}

        <GridColumn field="dropOutNotes" title="Drop Out Notes" width="180px" />
        {trackCandidateSearch ? (
          ""
        ) : (
          <GridColumn
            field="recruiterLeader"
            title="Person of Contact"
            width="180px"
          />
        )}
        <GridColumn field="source" title="Source" width="180px" />
      </GridDiv>
    </>
  );
};

export default SubmissionReport;
