import axios from "axios";
const jsonConfig = require("../Config.json");

const checkRequisitionExists = async (id, validation) => {
    try {
        const getAllResponse = await axios.post(jsonConfig.apiUrl + "checkRequisitionExists",
            {
                "id": id,
                "validation": validation,
            },
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },

            });

        return Promise.resolve(getAllResponse)

    } catch (error) {
        return Promise.reject(error)
    }
}
export default checkRequisitionExists;
