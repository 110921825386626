import { useState, useEffect, useContext } from "react";
import Profile from "./profile/Profile";
import { useHistory } from "react-router";
import verifyMyCandidateResponse from "../../apis/recruiters/verifyMyCandidateAccess";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";
import { UserTypeContext } from "../../AppRoute";
import { useLocation } from "react-router-dom";
const AdminProfile = (props) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const history = useHistory();
  const { match } = props;
  const location = useLocation();
  const [userId, setuserId] = useState("");
  const token = localStorage.getItem("token");


  const getRecruiterResponse = async () => {
    try {
      let payload = {
        "candidateid": match.params.id,
        "id": userId
      }
      let isRecruiterHasAccessResponse = await verifyMyCandidateResponse(payload)
      if (!isRecruiterHasAccessResponse.data.access) {
        history.push("/");
      }
    } catch (error) {
      history.push("/");
    }
  };


  useEffect(() => {
    tokenExpireVerification();
  }, []);

  const tokenExpireVerification = () => {
    try {
      const decodedJwt = jwt_decode(token);

      if (decodedJwt) {

        if (decodedJwt.exp < DateTime.now().toUnixInteger()) {

          localStorage.clear();
          history.push("/")

        } else {

          setuserId(decodedJwt.id);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (userId) {
      getRecruiterResponse();
    }
  }, [userId]);
  return (
    <>
      {userType === "Admin" || userType === "Recruiter" || userType=== "Recruitment Leader"  || userType === "Organization Admin" ? (
        <div>
          <Profile candidateId={match.params.id} recruitmentpartnerId ={match.params.recruitmentpartnerId}  testAccess={false} isShare={false} />
        </div>
      ) : (<>
        <div className="row padding_80 text-center">
          <p className="fs-semi-bold fs-40 f-color">
            You Are Not Authorized To Visit This Page
          </p>
        </div>
      </>)}
    </>
  );
};

export default AdminProfile;
