import React, { useState, useEffect, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import "./recordVideointro.css";
import { Box, CircularProgress, DialogContentText } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import cross from "./images/cross.svg";
import Revised from "./images/videoIntro.jpg";
import { CheckCircle } from "@material-ui/icons";
import saveVideoIntro from "../../../apis/videoIntro/saveVideoIntro";
import profileResponse from "../../../apis/profile/profileById";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: "flex",
    alignItems: "center",
  },
  bodyForEditProfile: {
    padding: "0px 24px 0px 42px!important",
    marginTop: "70px",
    position: "relative",
  },
  buttonAlignForRemove: {
    padding: "32px!important"
  },
  videoIntroBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  circleCheck: {
    height: '65px',
    width: '65px',
    color: '#009EFD'
  },
  doneMsg: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#009EFD',
    textShadow: '1px 2px 2px #04093f',
    marginTop: '5px'
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});
const circularProg = {
  height: '65px',
  width: '65px',
  color: '#009EFD'
};
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div className="crossFormodal cp" onClick={onClose}>
          <img className="closeicon_set" src={cross} alt=""></img>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    marginTop: "0px!important",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
}))(MuiDialogActions);

const RecordVideoIntro = ({ setLastUpdate, userId,profileData }) => {
  const classes = useStyles();
  const jsonConfig = require("../../../Config.json");

  // States
  const [videoIntroUrl, setVideoIntroUrl] = useState("");
  const [videoBlob, setVideoBlob] = useState("");
  const [videoBlobAvailable, setVideoBlobAvailable] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loadingController, setLoadingController] = useState(true);
  const [time, setTime] = useState(180000);
  const [timerOn, setTimerOn] = useState(false);
  const [countDown, setCountDown] = useState(5000);
  const [isActive, setIsActive] = useState(false);
  const [deleteVedioIntro, setdeleteVedioIntro] = useState(false);
  const [timeCount, settimeCount] = useState(true);
  const [openVid, setopenVid] = useState(true);
  // Refs
  const userCameraPermissionRef = useRef(false);
  const videoRef = useRef(null);
  const userCameraStreamRef = useRef(null);
  const streamRecorderRef = useRef(null);
  const currentTimeoutIdRef = useRef(null);
  const videoBlobRef = useRef(null);
  const videoFilePickerRef = useRef(null);
  const retakeVideRef = useRef(null);
  const [playUrl, setPlayUrl] = useState("");
  const [newUrl, setUrl] = useState("");
  const [videoFilePick, setVideoFilePick] = useState(false);
  const [recordedVideoOpen, setRecordedVideoOpen] = useState(false);
  const [loderAferSave, setLoaderAfterSave] = useState(false);
  const [openSampleVideoModal, setopenSampleVideoModal] = useState(false);
  const [successRecord, setSuccessRecord] = useState(false);
  // Save video intro
  const saveVideointro = async () => {
    setLoaderAfterSave(true);
    if (videoBlob) {
      let timeStamp = new Date().getTime();
      await uploadVideoToBlobStorage(videoBlob, timeStamp);
    }
    {/* For showing the success alert with some timeout */ }
    setTimeout(() => {
      setSuccessRecord(true)
    }, 2000)
    {/* Resetting the successRecord state*/ }
    setSuccessRecord(false);
  };

  //Initialize preview
  const initializePreview = async () => {
    if (videoRef.current) {
      videoRef.current.srcObject = userCameraStreamRef.current;
      videoRef.current.play();
      videoRef.current.volume = 0;
    }
  };

  // Start video recording
  const startRecording = async () => {
    await initializeUserCameraRecorder();
    const streamRecorder = streamRecorderRef.current;

    if (streamRecorder && streamRecorder?.state === "inactive") {
      setIsActive(true);
      setTimeout(() => {
        try {
          if (streamRecorder && streamRecorder?.state === "inactive") {
            streamRecorder.start();
            settimeCount(false);
          }
        } catch {
        }   
      }, 6000)
      setIsRecording(true);
      setTimerOn(true);
    }
    await initializePreview();

  };

  // Stop video recording
  const stopRecording = () => {
    setPlayUrl(true);
    const streamRecorder = streamRecorderRef.current;

    if (streamRecorder && streamRecorder.state === "recording") {
      streamRecorder.stop();
      // streamRecorderRef.current.onstop = async function (event) {
      //   console.log('onStopHandlecalled', event.data);
      // }

      // streamRecorderRef.current = null;
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
      setTimerOn(false);
      setIsActive(false);
      setIsRecording(false);
    }
  };

  // Initialize user camera
  const initializeUserCamera = async () => {
    if (!userCameraStreamRef.current) {
      const getWebcamStream = async () => {
        const webcamStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        userCameraStreamRef.current = webcamStream;
        if (
          webcamStream.getVideoTracks().length > 0 &&
          webcamStream.getAudioTracks().length > 0
        ) {
          userCameraPermissionRef.current = true;
        } else {
          setErrorAlert(true);
          // userCameraPermissionRef.current = false;
        }
      };
      try {
        await getWebcamStream();
      } catch (error) {
        setErrorAlert(true);
      }
    }
  };

  // Initialize user camera recorder
  const initializeUserCameraRecorder = async () => {
    if (userCameraStreamRef.current) {
      const options = {
        audioBitsPerSecond: 64000,
        videoBitsPerSecond: 500000,
        mimeType: "video/webm; codecs=vp8,opus",
      };

      if (!streamRecorderRef.current) {
        streamRecorderRef.current = new MediaRecorder(
          userCameraStreamRef.current,
          options
        );

        if (streamRecorderRef.current) {
          streamRecorderRef.current.onerror = async function (event) {
            // do nothing
            console.error("errorevent", event);
          };
          streamRecorderRef.current.ondataavailable = async function (event) {
            let videoModalPlaybackElement = document.getElementById(
              "video-intro-playback-element"
            );
            if (videoModalPlaybackElement) {
              videoBlobRef.current = URL.createObjectURL(event.data);
              setVideoBlobAvailable(true);
              videoModalPlaybackElement.src = videoBlobRef.current;
              setVideoBlob(event.data);
            }
          };
        }
      }
    }
  };

  // generate random user Id
  const generateUserId = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // Upload video to storage
  const uploadVideoToBlobStorage = async (videoBlob, timeStamp) => {
    const randomId = generateUserId(16);
    const blob = videoBlob;
    // const videoExtension = blob.type.split('/')[1];
    if (blob) {
      const sas = jsonConfig.sasToken;
      const bsClient = new BlobServiceClient(
        `${jsonConfig.ctResourcesCdn + sas}`
      );
      const containerName = "videointro";
      const containerClient = bsClient.getContainerClient(containerName);
      // const blobName =
      //   authResponse.uniqueId + "/" + "video-" + timeStamp + ".webm";
      const blobName = userId + "/" + randomId + ".webm";
      const blobClient = containerClient.getBlockBlobClient(blobName);
      const response = blobClient.uploadBrowserData(blob);
      response.then(async (res) => {
        if (res._response.request.url) {
          const authUniqueId = userId;
          setVideoIntroUrl(
            `${jsonConfig.ctResourcesCdn}/videointro/${authUniqueId}/${randomId}.webm`
          );
          const formData = {
            videoIntroUrl: `${jsonConfig.ctResourcesCdn}/videointro/${authUniqueId}/${randomId}.webm`
          };
          const payload = {
            id: userId,
            data: formData,
          }
          await saveVideoIntro(payload);
          await getVideoIntroData();
          setLoaderAfterSave(false);
          handleClose();
        } else {
          handleClose();
          setLoaderAfterSave(false);
        }
      });
    }
  };

  // Get video intro
  const getVideoIntroDatas = () => {
    try {
      if (profileData?.videoIntroUrl) {
        setVideoIntroUrl(profileData.videoIntroUrl);
        setVideoBlobAvailable(true);
        setLastUpdate(profileData.lastUpdated)
        setLoadingController(false);
      } else {
        setVideoBlobAvailable(false);        
      }
      if(profileData.lastUpdated)
      {
        setLoadingController(false);
      }
      
    } catch (error) {
      setVideoBlobAvailable(false);
      setLoadingController(false);
    }
  };
  const getVideoIntroData = async () => {
    try {
      const videoIntroData = await profileResponse(userId);

      if (videoIntroData && !!videoIntroData.data.data[0].videoIntroUrl) {
        setVideoIntroUrl(videoIntroData.data.data[0].videoIntroUrl);
        setVideoBlobAvailable(true);
        setLastUpdate(videoIntroData.data.data[0].lastUpdated)
      } else {
        setVideoBlobAvailable(false);
      }
      setLoadingController(false);
    } catch (error) {
      setVideoBlobAvailable(false);
      setLoadingController(false);
    }
    // }
  };

  // Higher order function. It takes the millisToMinutesAndSeconds Function
  // Don't remove the code. we can take reference to create higher order function. Here it's not needed

  // const millisToMinutesAndSecondsHOC = (fn,millis) => {
  //  const mns =  fn(millis)
  //   const spl = mns.split(':');
  //   return `${spl[0]} minutes ${spl[1]} seconds`
  // }

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      minutes +
      (minutes === 1 ? " minute" : " minutes") +
      " " +
      (seconds < 10 ? "0" : "") +
      seconds +
      " seconds"
    );
  };

  const millisToSecond = (millis) => {
    let seconds = (millis % 60000) / 1000;
    return seconds;
  };
  // Add timeout
  const addInterviewTimeout = () => {
    const timeLimit = jsonConfig.introVideoTimeLimit;
    let timeMili = jsonConfig.introVideoTimeLimit;
    let tempTime = 0;
    const tempTimer = setTimeout(() => {
      tempTime = millisToMinutesAndSeconds(timeMili - 1000);
    }, 1000);
    if (currentTimeoutIdRef.current) {
      clearTimeout(currentTimeoutIdRef.current);
    }
    currentTimeoutIdRef.current = setTimeout(() => {
      stopRecording();
      clearTimeout(tempTimer);
      setVideoBlobAvailable(true);
    }, timeLimit);
  };

  const retakeVideo = async () => { 
    stopRecording();
    setTime(180000);
    setCountDown(5000);
    streamRecorderRef.current = null;
    userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    userCameraStreamRef.current = null;
    if (videoBlobAvailable) {
      setVideoBlobAvailable(false); // This is for retaking the video intro after saved
    }
    await initializePreview();
  };

  const handleClickOpen = async () => {
    setRecordedVideoOpen(false);
    buttonActive();

    if (videoBlobAvailable) {
      setVideoBlobAvailable(false); // This is for retaking the video intro after saved
    }
    setOpen(true);

    // if (userCameraPermissionRef.current) {
    //   setOpen(true);
    // }

    await initializePreview();
  };

  const handleClose = () => {
    // setVideoBlobAvailable(false);
    // setIsRecording(false);
    setOpen(false);
    setTimerOn(false)
    setIsActive(false)
    setTime(180000);
    setCountDown(5000);
    // stopRecording();
    if (userCameraStreamRef.current) {
      setRecordedVideoOpen(false);
      stopRecording();
      streamRecorderRef.current = null;
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      userCameraStreamRef.current = null;
      clearTimeout(currentTimeoutIdRef.current);

    }

  };

  // snackbar close
  const snackBarClose = () => {
    setErrorAlert(false);
  };

  const startVideoRecording = async () => {
    await initializeUserCamera();

    // countDownTimeout();
    if (userCameraPermissionRef.current) {
      startRecording();
      setVideoFilePick(false);
      setTimerOn(true);
      setIsActive(true);
      clearTimeout(currentTimeoutIdRef.current);
      addInterviewTimeout();
      buttonActive();
    }
  };
  const buttonActive = () => {
    settimeCount(true);
  }

  const stopVideoRecording = async () => {
    // settimeCount(!timeCount);
    setVideoBlobAvailable(true);
    stopRecording();
    setIsRecording(false);
    userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    clearTimeout(currentTimeoutIdRef.current);
  };

  const videoBlobReference = () => {
    videoBlobRef.current = null;
  };

  const deleteCroppedImg = () => {
    setdeleteVedioIntro(true)
    setVideoBlobAvailable(false);
    uploadVideoToBlobStorage(null, 0);
  }
  const handleClos = () => {
    setdeleteVedioIntro(false);
  };
  const confirmDelete = async () => {
    setopenVid(false);
    videoBlobReference();
    try {
      const { data } = await axios.put(`${jsonConfig.apiUrl}deleteVideoIntro`, { "videoIntroUrl": videoIntroUrl })
    } catch (error) { console.log(error) }

    const formDat = {
      videoIntroUrl: 'null'
    };
    const payload = {
      id: userId,
      data: formDat,
    }
    await saveVideoIntro(payload);
    await getVideoIntroData();
    setLoaderAfterSave(false);
    setVideoBlob(false)
    handleClose();
    setLoaderAfterSave(false);
    setVideoBlobAvailable(false); // This is for retaking the video intro after saved
    setRecordedVideoOpen(false);
    setVideoIntroUrl("null");
    setLoaderAfterSave(false)
    setdeleteVedioIntro(false)
  }
  useEffect(() => {
    if (videoFilePick) {
      videoBlobReference();
    }
  }, [videoFilePick]);

  useEffect(() => {
    getVideoIntroDatas();
  }, [profileData]);

  useEffect(() => {
    let interval = null;
    if (timerOn && countDown === -1000) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time, timerOn, countDown]);

  useEffect(() => {
    let interval = null;
    if (isActive && countDown > -1000) {
      interval = setInterval(() => {
        setCountDown((prevTime) => prevTime - 1000);
      }, 1000);
    } else if (countDown === -1000) {
      setIsActive(false);
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [countDown, isActive]);

  useEffect(() => {
    if (playUrl) {
      setTimeout(() => {
        setUrl(videoBlobRef.current);
      }, 2000);
    }
  }, [playUrl]);

  const modalTitle = {};

  // for opening recorded video inside Modal
  const recordedVideoModal = (e) => {
    setRecordedVideoOpen(true);
  };

  // for closing recorded video inside Modal
  const recordedVideoClose = (e) => {
    setRecordedVideoOpen(false);
  };

  return (
    <>
      {!loadingController ? (
        <>
          <div className="powres_card mt_10 powres_box ml_20">
            <div className="row">
              {videoIntroUrl ? (
                <p className="mn_semibold fs-14  text-center">Video Introduction</p>
              ) : (
                <p className="mn_semibold fs-14 text-center" >Video Introduction</p>
              )}

              <div className="row mt_10">
                  {(videoIntroUrl != "null" && videoIntroUrl) ? (
                    <>
                      <div className="skill_video_sec_left skill-test-left md400" onClick={(e) => recordedVideoModal(e)} >
                        <video
                          className="preview-video-intro cp"
                          src={videoIntroUrl}
                          width={"100px"}
                          height={"100px"}
                          poster={Revised}
                        />
                      </div>
                      <div>
                      <button className="btn-resume-testimonial cp fs_manrope_12_btn mt_10"onClick={handleClickOpen}>
                        Retake Video Intro
                      </button>
                      </div>                      
                    </>
                  ) : (                 
                    <div className="skill_video_sec_right ">
                      <p className="fs-regular fs-12 f-color mt_30 text-center">No video introduction available</p>
                      <button
                        type="button"
                        className="btn-resume-testimonial cp fs_manrope_12_btn mt_70 " 
                        onClick={handleClickOpen}
                      >
                        Record my Video Introduction
                      </button>
                    </div>
                  )}
              </div>
            </div>

            <Snackbar
              open={errorAlert}
              autoHideDuration={6000}
              onClose={snackBarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={snackBarClose} severity="error">
                Please provide permission to camera and microphone
              </Alert>
            </Snackbar>

            <Dialog
              onClose={handleClose}
              open={open}
              aria-labelledby="customized-dialog-title"
              maxWidth="md"
              fullWidth={true}
              className="record-video-intro-responsive"
            >
              <div className="record-intro-title">
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                  style={modalTitle}
                >
                  My Video Introduction
                </DialogTitle>
                {
                  isRecording ?
                    <>
                      {isActive === true ? (
                        <span className="record-video-timer-wrapper">
                          Video recording starts in:
                          <span className="ml_5">{millisToSecond(countDown)}</span>
                        </span>
                      ) : (
                        timerOn && (
                          <span className="record-video-timer-wrapper">
                            Time Left:
                            <span className="ml_5">
                              {millisToMinutesAndSeconds(time)}
                            </span>
                          </span>
                        )
                      )}
                    </>
                    : ""
                }

              </div>
              <DialogContent
                className={`${classes.bodyForEditProfile} responsive-body-for-profile-edit`}
              >
                {!videoBlobAvailable ? (
                  !isRecording ? (
                    <div>
                      <div className="seperate_border">
                        <p className="fs-semi-bold fs-14 f-color">
                          Your video introduction is an important and integral
                          part of your PowerResume™. It has been established,
                          across multiple studies over several years that a
                          crisp video introduction exponentially increases a
                          candidate’s chances of hire. Here are some guidelines
                          to help you think about and prepare your video
                          introduction:
                        </p>
                      </div>
                      <div className="seperate_border mt_9">
                        <p className="fs-semi-bold fs-18 f-color text-center">
                          Video content and timeline
                        </p>
                        <p className="fs-semi-bold fs-14 text-underline f-color text-center">
                          We strongly encourage you to watch the sample video
                          resume on this page ahead of preparing and for
                          recording your own video
                        </p>
                        <div className="df mt_19">
                          <p className="video_section_arrow">
                            &#9654;
                            {/* <i className="fa fa-long-arrow-right" aria-hidden="true"></i> */}
                          </p>
                          <p className="fs-semi-bold fs-14 f-color">
                            The most effective videos are 90 seconds or less.
                            Somewhere between 60 seconds and 90 seconds is
                            optimal.
                          </p>
                        </div>
                        <div className="df mt_9">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Your video should cover the following order of
                            content:
                          </p>
                        </div>
                        <div className="df mt_9 ml_30">
                          <p className="video_section_box">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Name, location, educational background + one
                            “overarching” line that describes you
                          </p>
                        </div>
                        <div className="df mt_9 ml_30">
                          <p className="video_section_box">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </p>
                          <p className="fs-semi-bold fs-14 f-color">
                            What set of skills do you bring to the table?
                          </p>
                        </div>
                        <div className="df mt_9 ml_30">
                          <p className="video_section_box">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Examples of two or three recent projects you have
                            meaningfully worked on – summary only
                          </p>
                        </div>
                        <div className="df mt_9 ml_30">
                          <p className="video_section_box">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </p>
                          <p className="fs-semi-bold fs-14 f-color">
                            What are you looking forward to, in the context of
                            your next project(s)
                          </p>
                        </div>
                      </div>
                      <div className="seperate_border mt_9">
                        <p className="fs-semi-bold fs-18 f-color text-center">
                          Tips and Best Practices
                        </p>
                        <div className="df mt_19">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            How do I communicate? Be yourself. Organizations
                            want to know who you are and how you communicate on
                            a regular basis. Do not change your accent or
                            modulate unnecessarily.
                          </p>
                        </div>
                        <div className="df mt_9">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            How do I dress? Dress like you would during a
                            regular interview. Be comfortable and think about
                            the fact that viewers across geographies and
                            cultures may review your video.
                          </p>
                        </div>
                        <div className="df mt_9">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Lighting and location? Both are critical. Find a
                            well lit and quiet location. Dark videos and
                            background noises will turn off a recruiter.
                          </p>
                        </div>
                        <div className="df mt_9">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Should I prepare a script beforehand? Absolutely!
                            Write a script and practice, if possible. You have
                            the opportunity to record and re-record here before
                            you decide to post your video introduction. Once you
                            are happy with your script, memorize it – so that it
                            does not appear that you are reading anything.
                            Organizations like to see people being genuine.
                          </p>
                        </div>
                        <div className="df mt_9">
                          <p className="video_section_arrow">&#9654;</p>
                          <p className="fs-semi-bold fs-14 f-color">
                            Posture and Positioning? Sit or stand straight up as
                            you record and look into the camera, at eye-level,
                            and with confidence. You know who you are and what
                            you bring to the table. Let that stand out.
                          </p>
                        </div>
                        <p className="fs-semi-bold fs-14 mt_9  f-color text-center">
                          Above all. Be yourself!
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {isActive ? (
                        <div className="video_container">
                          <video
                            className="MediaStreamView-video"
                            id="video-intro-element"
                            ref={videoRef}
                          />
                          <div className="videotimer_count_text">{millisToSecond(countDown)}</div>
                        </div>
                      ) : (
                        <div className="video_container">
                          <video
                            className="MediaStreamView-video"
                            id="video-intro-element"
                            ref={videoRef}
                          />
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <></>
                    {!videoFilePick ? (
                      <video
                        className="MediaStreamView-video"
                        id="video-intro-playback-element"
                        src={videoBlobRef}
                        playsInline
                        controls={true}
                      />
                    ) : (
                      <>
                        <></>
                        <video
                          className="MediaStreamView-video-FilePicker"
                          id="video-intro-filePicker"
                          src={videoFilePickerRef}
                          playsInline
                          controls={true}
                        />
                      </>
                    )}
                  </>
                )}
                {/*Beta 4.0 - CircularProgress with Success Alert*/}
                {loderAferSave && (
                  <div className="circular-progress-record-video">
                    {successRecord ?
                      <Box className={`${classes.videoIntroBox}`}>
                        {/*Success Tick and Alert Will Appears if the successRecord set to True*/}
                        <CheckCircle className={`${classes.circleCheck}`} />
                        <h5 variant="h5" className={`${classes.doneMsg}`}>Done</h5>
                      </Box>
                      :
                      <>
                        {/*Circular Progress will Appears if the successRecord set to False*/}
                        <Box className={`${classes.videoIntroBox}`}>
                          <CircularProgress style={circularProg} />
                        </Box>
                      </>
                    }
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={`${classes.buttonAlign} responsive-dialog video-intro-btn-container`}
              >
                {videoBlobAvailable ? (<button
                  type="button"
                  className="btn-big btn-skill cp "
                  onClick={retakeVideo}
                >
                  Retake
                </button>) : (
                  isRecording ? (
                    <button
                      type="button"
                      className={timeCount ? "btn-big" : "btn-big btn_powres ml_5"}
                      onClick={stopVideoRecording}
                      disabled={timeCount}
                    >
                      Stop Recording
                    </button>
                  ) : (
                    <>
                      {/* <a href="https://www.youtube.com/watch?v=F05P_5l8E4w" target="_blank"> */}
                      <button
                        type="button"
                        className="btn-big btn-blue cp ml_5"
                        onClick={() => setopenSampleVideoModal(true)}
                      >
                        Sample Video
                      </button>
                      {/* </a> */}

                      <button
                        type="button"
                        className="btn-big btn_powres cp ml_5"
                        onClick={startVideoRecording}
                      >
                        Start Recording
                      </button>
                    </>
                  )
                )}
                {videoBlobAvailable ? (
                  <button
                    type="button"
                    className="btn-big btn_powres ml_10 cp"
                    onClick={saveVideointro}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn-big btn-skill ml_10 cp"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>

            <Dialog
              onClose={recordedVideoClose}
              open={recordedVideoOpen}
              aria-labelledby="customized-dialog-title"
              PaperProps={{
                style: {
                  width: "600px",
                },
              }}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={recordedVideoClose}
                className="video-intro-heading"
              >
                Video Intro
              </DialogTitle>
              <DialogContent>
                <video
                  className="recorded-video-intro"
                  src={videoIntroUrl}
                  controls={true}
                />
                <DialogActions>
                  <div className="mb2vh">
                    {/* skill_video_sec_right available-video-intro-right */}
                    <button
                      type="button"
                      className="mt_10 cp btn-small ml_5 btn-skill cp "
                      onClick={handleClickOpen}
                      ref={retakeVideRef}
                    >
                      Retake
                    </button>

                    <button
                      type="button"
                      className=" mt_10 cp btn-small  ml_10 delbutton "
                      onClick={deleteCroppedImg}
                    >
                      Delete
                    </button>
                  </div>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </div>
        </>
      ) : (
        <>
          <>
          <div className="card mt_10 powres_box ml_20 ">
            <div className="row">
              <Skeleton variant="text" animation="wave" />
              <div className="df mt_6">
                <div className="w-100 ml_10">
                  <Skeleton variant="text" animation="wave" />
                </div>
              </div>
              <Skeleton variant="text" animation="wave" />
              <div className="w-100 ml_10">
                  <Skeleton variant="text" animation="wave" />
                </div>            
            </div>
          </div>
        </>
        </>
      )}
      <Dialog
        onClose={() => setopenSampleVideoModal(false)}
        open={openSampleVideoModal}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setopenSampleVideoModal(false)}
          className="video-intro-heading"
        >
          Sample Video
        </DialogTitle>
        <DialogContent>
          <video
            className="recorded-video-intro"
            src={jsonConfig.sampleVideoIntro}
            controls={true}
          />
        </DialogContent>
        <DialogActions className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}>

          <button
            type="button"
            className="btn-big btn-skill ml_10 cp"
            onClick={() => setopenSampleVideoModal(false)}
          >
            Close
          </button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteVedioIntro}
        onClose={handleClos}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        ></DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <p className="fs-semi-bold fs-16 f-color">
            Are you sure you want to delete your Video Introduction?

          </p>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn-small btn-skill cp"
            onClick={handleClos}
          >
            No
          </button>
          <button
            type="button"
            className="btn-small btn_powres cp"
            onClick={confirmDelete}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default RecordVideoIntro;
