import React, { useEffect, useState, useRef } from 'react'
import './RecLead.css';
import InterviewTable from '../../components/recruitmentLeaderInterviewTable/recruitmentLeaderInterviewTable';
import UpcomingStarts from '../../components/UpcomingStarts/UpcomingStarts';
import {
    NotificationManager,
} from "react-notifications";
import LineChart from './Charts/LineChart';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RequisitionCountTable from '../../components/recruitmentLeader/RequisitionCountTable';
import recentInvitations from '../../apis/recentInvitationsAPI/recentInvitations';
import { CircularProgress, TextField } from '@material-ui/core';
import pdfIcon from "../../pages/myTalentCommunity/icons/PDFIcon.png"
import docIcon from "../../pages/myTalentCommunity/icons/doc.png";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Dialog, DialogActions, DialogContent, Grid, IconButton, Typography, Backdrop } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert } from '@material-ui/lab';
import { BlobServiceClient } from '@azure/storage-blob';
import crypto from 'crypto';
import { Button } from 'react-bootstrap';
import { DateTime } from "luxon";
import countNoOfFilesParsed from "../../apis/parsing/countNoOfFilesParsed";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import getTrackCandidateInfo from '../../apis/getTrackCandidateInfo/getTrackCandidateInfo';

const jsonConfig = require("../../Config.json");


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: '"Segoe UI Semibold", sans-serif !important',
        fontWeight: 'bold',
        fontSize: "10px",
        color: "#ffffff !important",
        textShadow: "0px 1px 2px black !important"
    },
    searchHeight: {
        "& .MuiOutlinedInput-adornedStart": {
            height: "45px",
        },
    },
    globalSearchButton: {
        color: '#fff',
        backgroundColor: '#31B3F8',
        height: '45px',
        marginRight: '-8px',
        borderRadius: '6px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        '&:hover': {
            backgroundColor: '#31B3F8',
            opacity: '0.9'
        }
    },
}));

function RecLeadFeed(props) {
    const classes = useStyles();
    const history = useHistory();
    const { id, userType, userRecruitmentpartnerId, userRecords, userRole } = props;

    const idContextTest = id;

    const [sourceResume, setsourceResume] = useState("");

    const [recruiterPartnerLogo, setrecruiterPartnerLogo] = React.useState("");
    const [recruiterPartnerLogoLoader, setrecruiterPartnerLogoLoader] = React.useState(false);
    const [RecentInvitationData, setRecentInvitationData] = React.useState([]);
    const [RecentInvitationDataLoader, setRecentInvitationDataLoader] = React.useState(false);

    // Dialog modal useStates
    const [progressOpen, setprogressOpen] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState({ text: '', visible: false });
    const [backDropOpen, setBackDropOpen] = useState(false);
    const uploadedFileParseCount = useRef(0);
    const { showResumeModal, setShowResumeModal } = props

    const closeEditModal = () => {
        setShowResumeModal(false);
        setSelectedFiles([]);
        setErrorMessage('');
    };

    const handleFileChange = (event) => {

        const files = event.target.files;

        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const selectedFilesArray = [...selectedFiles];

        for (let i = 0; i < files.length; i++) {
            const fileExtension = files[i].name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage({ text: 'Only PDF and DOC files are allowed.', visible: true });
                setTimeout(() => {
                    setErrorMessage({ text: '', visible: false });
                }, 5000);
                return;
            }

            if (selectedFilesArray.length + 1 > 5) {
                setErrorMessage({ text: 'You can only select up to 5 files.', visible: true });
                setTimeout(() => {
                    setErrorMessage({ text: '', visible: false });
                }, 5000);
                return;
            }
            selectedFilesArray.push(files[i]);
        }

        setErrorMessage({ text: '', visible: false });
        setSelectedFiles(selectedFilesArray);
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();

        if (extension === 'pdf') {
            return <img src={pdfIcon} alt="PDF Icon" className="custom-icon" />;
        } else if (extension === 'doc' || extension === 'docx') {
            return <img src={docIcon} alt="DOC Icon" className="custom-icon" />;
        } else {
            return <InsertDriveFileIcon />;
        }
    };

    const removeSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    }


    const uploadResumeToBlobStorage = async (filesArray) => {

        if (filesArray.length === 0) {
            setErrorMessage({ text: 'Select a file first.', visible: true });
            setTimeout(() => {
                setErrorMessage({ text: '', visible: false });
            }, 5000);
            return;
        }
        else {
            if (sourceResume === "")
                NotificationManager.error("Source field can not be blank", "Error", 2000);
            else {
                setBackDropOpen(true)
                closeEditModal()
                setprogressOpen(true)
                try {


                    const sas = decodeURI(jsonConfig.sasResumeToken);


                    const bsClient = new BlobServiceClient(
                        `https://${jsonConfig.blobStorageResumeAccountName}.blob.core.windows.net${sas}`
                    );

                    const containerClient = bsClient.getContainerClient("staging");
                    if (userRecruitmentpartnerId) {
                        let filnameWithtimearr = []
                        filesArray.map(async (blob) => {
                            let date = DateTime.now();
                            let currentDate = date.toFormat("yyyy-MM-dd");
                            let formattedDate = date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");
                            var v = blob.name.split(".");
                            let uuid = create_UUID();
                            let hashUuid = hash_UUID(uuid);
                            let blobName = currentDate + "/" + idContextTest + "/" + hashUuid + "." + v[v.length - 1];
                            const blobClient = containerClient.getBlockBlobClient(blobName);
                            const blobUploadOptions = {
                                blobHTTPHeaders: {
                                    blobContentType: blob.type,
                                },
                                metadata: {
                                    "createdtime": formattedDate,
                                    "fullname": blob.name,
                                    "uploadedtype": "Recruiter Upload",
                                    "organizationid": userRecruitmentpartnerId,
                                    "blobfilename": blobName,
                                    "source": sourceResume
                                },
                            };
                            filnameWithtimearr.push({ "blobfilename": blobName, "createdtime": formattedDate, "organizationid": userRecruitmentpartnerId })
                            await blobClient.uploadBrowserData(blob, blobUploadOptions);

                        });
                        // await Promise.all(uploadPromises);
                        if (filesArray.length === filnameWithtimearr.length) {
                            const intervalID = setInterval(() => {
                                if (stopCondition(filnameWithtimearr)) {
                                    delayedFunction(filnameWithtimearr);
                                } else {
                                    clearInterval(intervalID);
                                    setprogressOpen(false)
                                    setBackDropOpen(false)

                                    history.push({
                                        pathname: "/pending-resume-validations",
                                        state: { id: idContextTest },
                                    });
                                }
                            }, filesArray.length * 5000);
                            // return () => clearInterval(interval);  // Clear the interval when the component unmounts
                        }

                    }




                    setSelectedFiles([]);
                } catch (error) {

                    setSelectedFiles([]);
                    console.error("An error occurred during upload:", error);
                }
            }
        }

    };
    function create_UUID() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt >>>= 4; // unsigned right shift to ensure a 32-bit integer
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }

    function hash_UUID(uuid) {
        const sha256 = crypto.createHash('sha256');
        sha256.update(uuid);
        return sha256.digest('hex');
    }

    function stopCondition(filnameWithtimearr) {

        if (uploadedFileParseCount.current === filnameWithtimearr.length) {
            return false
        } else {
            return true
        }
    }

    const delayedFunction = async (filnameWithtimearr) => {
        //call api
        const response = await countNoOfFilesParsed(filnameWithtimearr);

        if (response.status === 200) {

            uploadedFileParseCount.current = response.data.count;
        }
    }

    const goToCandidateProfile = async (itemValue) => {
        const response = await getTrackCandidateInfo({
            candidateEmail: itemValue.candidateEmail,
            recruitmentPartnerId: userRecruitmentpartnerId,
        });
        const userData = response?.data?.data?.userDetails[0]
        let url = '';
        if (userData?.powerResumeId) {
            url = 'candidate/profile/' + userData?.powerResumeId + '/' + userData.recruitmentOrgId
        }
        else {
            url = 'only-candidate-profile/' + userData?.candidateId
        }
        window.open(url, "_blank")
    }

    useEffect(() => {

        const getCommunityStaticsData = async () => {
            try {
                if (userRecords.recruitmentpartner) {
                    setrecruiterPartnerLogo(
                        userRecords.recruitmentpartner.recruitmentpartnerLogoUrl
                    );
                    setrecruiterPartnerLogoLoader(true)
                }
            } catch (error) {
                console.log("error", error);
            } finally {
                setrecruiterPartnerLogoLoader(false)
            }
        }

        const getRecentInvitations = async () => {
            try {
                let response = await recentInvitations({ id: id, userType: userType === 'Admin' ? userRole : userType });
                setRecentInvitationData(response?.data?.data)
                setRecentInvitationDataLoader(false)


            } catch (error) {
                console.log("error", error);
            }

        }
        (async () => {
            setRecentInvitationDataLoader(true)

            await getCommunityStaticsData();
            await getRecentInvitations();
        })()
        return () => {
            setrecruiterPartnerLogo("")
            setrecruiterPartnerLogoLoader(true)

        }
    }, [id, userRecords.recruitmentpartner])


    return (
        <div>
            {recruiterPartnerLogo || recruiterPartnerLogoLoader ? (
                <div className='topBar-Ats-Feed'>
                    <div className='ats-feed-orgLogoArea'>
                        {
                            recruiterPartnerLogoLoader ? (
                                <div className='ats-feed-orgLogoLoader'>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className='recruiterLogoContainerMain'>
                                    <img
                                        className='recruiterLogoContainer'
                                        src={recruiterPartnerLogo}
                                        alt="Recruiter Partner Logo"
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            ) : ""}



            <div className='ats-dashFeed-MainGrid'>
                <div className='ats-dashFeedWrapper'>

                    <div >
                        <RequisitionCountTable
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />

                    </div>
                    <div >
                        <InterviewTable
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />
                    </div>
                    <div className='wasi-lineChart'>
                        <div className='ats-dashFeed-RightBar-Title'>
                            <div>Recent Activities</div>
                            <sub className='subtile_recLeadeFeed'>(Last 30 Days)</sub>

                        </div>
                        <LineChart
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />

                    </div>
                    <div >
                        <UpcomingStarts
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />
                    </div>

                </div>
                <div className='ats-dashFeed-RightBar'>
                    <div className='ats-dashFeed-RightBar-Title'>
                        <div>Recent Test Taken</div>



                    </div>


                    <div className='ats-dash-rightBar-infoContainer'>

                        {
                            RecentInvitationDataLoader ? (
                                <div className='ats-dash-rightBar-loader' >
                                    <CircularProgress />
                                </div>

                            ) : (

                                RecentInvitationData.length > 0 ? (
                                    RecentInvitationData.map((item, index) => {
                                        return (
                                            <div key={index} className='ats-dashFeed-RightBar-InfoCard' onClick={(e) => goToCandidateProfile(item)}>
                                                <VerifiedUserIcon style={{ color: "#31B3F8" }} />

                                                <div>
                                                    <div style={{ fontWeight: "600" }}>{item?.candidateName} completed the {item?.testName}</div>
                                                    <div style={{ fontWeight: "300" }}>Invited By: {item?.InvitedBy}</div>
                                                </div>

                                            </div>
                                        )
                                    }
                                    ))
                                    :
                                    <div className='ats-dashFeed-RightBar-InfoCard' >
                                        <div style={{ fontWeight: "600" }}>No Invitations Found</div>
                                    </div>
                            )
                        }

                    </div>


                </div>
            </div>

            {/* Resume modal */}
            <Dialog
                onClose={!progressOpen ? closeEditModal : ''}
                aria-labelledby="customized-dialog-title"
                open={showResumeModal}
            >
                <div className="upload_resume_dialog_modal" >
                    <div className='ats-UploadResumeModal-TitleContainer'>
                        <div>Upload Resume</div>
                    </div>

                </div>
                <DialogContent className='choose_file_dialog_content'>
                    <>
                        <div className="choose_file_dialog">
                            <button className="choose_file_btn">Choose Files</button>
                            <input type='file' name='myFiles' onChange={handleFileChange} multiple />
                        </div>
                        <div className='caution_text'>
                            <h4>PDF or doc</h4>
                        </div>

                        <div className='grid-container'>
                            {selectedFiles.map((file, index) => (
                                <div className='grid-item' key={index}>
                                    <div className='file-info'>
                                        <span className='file-icon'>
                                            {getFileIcon(file.name)}
                                        </span>
                                    </div>
                                    <div className='file-desc'>
                                        {file.name}
                                    </div>
                                    <IconButton className='close-icon' onClick={() => removeSelectedFile(index)}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                        {errorMessage.visible && <Alert severity="error">{errorMessage.text}</Alert>}
                    </>
                </DialogContent>
                {!progressOpen ? <>
                    <Grid item xs={12} md={12} className="user_pop_button">
                        <DialogActions className='action_buttons'>
                            <div className='source_div'>
                                <Typography className='resume_source'> Source*</Typography>
                                <TextField id="standard-basic" variant="filled" disabled={selectedFiles.length === 0} onChange={(e) => { setsourceResume(e.target.value) }} />
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                className='upload_button'
                                onClick={(e) => { uploadResumeToBlobStorage(selectedFiles) }}
                            >
                                Upload
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className='cancel_button'
                                onClick={closeEditModal}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </>
                    : ""}
            </Dialog>
            <Backdrop className={classes.backdrop} open={backDropOpen}>
                <CircularProgress color="inherit" className="circularprogress_mytalent" />
                <Typography className={classes.text} variant="h6"> Uploading Resume</Typography>
            </Backdrop>
        </div>
    )
}

export default RecLeadFeed