import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateCandidateResumeById = async (id, payload) => {
    try {
        const response = await axios.put(
            jsonConfig.apiUrl + "candidateResume/" + id,
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        const apiResponse = response;

        return Promise.resolve(apiResponse);
    } catch (error) {
        console.error("Error updating candidate resume", error);
        return Promise.reject(error);
    }
};
export default updateCandidateResumeById;