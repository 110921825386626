import React, { useCallback, useContext, useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid as GridDiv,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import recruiterActivityReports from "../../apis/requisitionReports/recruiterActivityReport";
import debounce from "lodash/debounce";
import { UserTypeContext } from "../../AppRoute";
import { DateTime } from "luxon";
import configJSON from "../../Config.json";
import "./requisitionReports.css";
import * as XLSX from "xlsx";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  odd: {
    background: "#F6F6F6 !important",
  },
  even: {
    background: "#fffff !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  waitingText: {
    marginLeft: "5px",
  },
}));

const RecruitersActivity = (props) => {
  const {
    userType,
    userIdType,
    userRecruitmentpartnerId,
    userRecords,
    triggerAction,
    resetTrigger,
    startSelectedDate,
    endSelectedDate,
  } = props;
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const [dataResult, setDataResult] = useState([]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    console.log(filters, "filters");
    setIsSearching(true);
    setDataResult([]);
    const terms =
      filters && filters.length > 0
        ? filters
            .filter(({ value }) => value && value !== "") // Filter out objects where value is empty, null, or white spaces
            .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];

    setSearchTerms(terms);

    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    setIsSearching(true);
  };

  useEffect(() => {
    let setDefaultValue = {
      skip: 0,
      take: 10,
      filter: {
        logic: "and",
        filters: [],
      },
    };
    setDataState(setDefaultValue);
  }, []);

  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Get only the YYYY-MM-DD part
  }

  const debouncedSearch = useCallback(
    debounce(
      async (
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userId,
        isLeader
      ) => {
        if (!startSelectedDate || !endSelectedDate) {
          NotificationManager.error("Please enter a valid date", "Error", 2500);
        } else {
          setLoading(true);
          let payload = {
            orgId: userRecruitmentpartnerId,
            page: pageNumber,
            size: takeNumberOfResult,
            formattedDatestart: formatDateOnly(startSelectedDate),
            formattedDateend: formatDateOnly(endSelectedDate),
            isExport: false,
            userId: userId,
            isLeader: isLeader,
          };
          const search = await recruiterActivityReports(payload);
          console.log(search, "atanu");

          if (search.data.data.length <= 0) {
            setLoading(false);
            setDataResult(search.data.data[0]);
            setTotalResult(0);
            setIsSearching(false);
          }
          if (search.data.data.length > 0) {
            let allReponse = search.data.data;
            setDataResult(allReponse);
            if (search.data.totalCount)
              setTotalResult(search.data.totalCount[0].total);
            setIsSearching(false);
          }
        }
      },
      1000
    ),
    []
  );
  // console.log("userRecruitmentpartnerId",userRecruitmentpartnerId);
  useEffect(() => {
    if (triggerAction) {
      callApiToGenerateExcel(); // Perform the action
      resetTrigger(); // Reset the trigger to avoid repeated execution
    }
  }, [triggerAction, resetTrigger]);
  useEffect(() => {

    if(!startSelectedDate || !endSelectedDate){
      NotificationManager.error(
        "Please enter a valid date",
        "Error",
        2500
    );
    
  }

  else{
    setIsSearching(true);
    setDataResult([]);

    let isLeader = false;
    if (userType === "Recruitment Leader") {
      isLeader = true;
    }

    debouncedSearch(
      userRecruitmentpartnerId,
      pageNumber,
      takeNumberOfResult,
      startSelectedDate,
      endSelectedDate,
      userIdType,
      isLeader
    );
  }

  
  }, [
    searchTerms,
    pageNumber,
    takeNumberOfResult,
    startSelectedDate,
    endSelectedDate,
  ]);

  const callApiToGenerateExcel = async () => {
    if (!startSelectedDate || !endSelectedDate) {
      NotificationManager.error("Please enter a valid date", "Error", 2500);
    } else {
      setIsExporting(true);
      // const formattedDatestart = startSelectedDate.toISOString();
      // const formattedDateend = endSelectedDate.toISOString();
      let isLeader = false;
      if (userType === "Recruitment Leader") {
        isLeader = true;
      }
      let maxSize = 100;
      let end = Math.ceil(totalResult / maxSize);
      let pageStart = 1;
      let pageEnd = maxSize;
      let exportArr = [];

      for (let start = 0; start < end; start++) {
        let payload = {
          orgId: userRecruitmentpartnerId,
          isExport: true,
          page: pageStart,
          size: maxSize,
          formattedDatestart: formatDateOnly(startSelectedDate),
          formattedDateend: formatDateOnly(endSelectedDate),
          userId: userIdType,
          isLeader: isLeader,
        };
        const allDetails = await recruiterActivityReports(payload);

        allDetails.data.data.forEach((element) => {
          let data = {
            "Recruiter Name": element.recruiterDetails.displayName,
            "Email ID": element.recruiterDetails.email,
            "Requisition Assigned": element.assignedRequisitionsCount,
            Covered: element.covered,
            Uncovered: element.uncovered,
            Submitted: element.submitted,
            "Client Declined": element.clientDeclined,
            "Interview Scheduled": element.interviewScheduled,
            Selected: element.selected,
            "Candidate Declined": element.candidateDeclined,
            Started: element.started,
            Dropout: element.dropout,
          };
          exportArr.push(data);
        });
        pageStart = pageStart + maxSize;
      }
      if (exportArr.length === 0) {
        NotificationManager.error("No data present", "Error", 2500);
        setIsExporting(false);
      } else {
        const MAX_ROWS_PER_SHEET = 500;

        const wb = XLSX.utils.book_new();

        // Calculate the number of sheets needed
        const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

        // Loop to create sheets
        for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
          // Get a subset of data for each sheet
          const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
          const endRow = Math.min(
            (sheetIndex + 1) * MAX_ROWS_PER_SHEET,
            exportArr.length
          );
          const subsetData = exportArr.slice(startRow, endRow);

          // Convert JSON array to worksheet
          const ws = XLSX.utils.json_to_sheet(subsetData);

          // Append the sheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
        }

        // Save the workbook to an Excel file
        XLSX.writeFile(wb, `RecruiterActivity.xlsx`, {
          bookType: "xlsx",
          type: "file",
        });
        setIsExporting(false);
      }
    }

    // Call the API to generate the Excel file
  };

  const recruiterActivitySkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push(
        <Skeleton
          className={i % 2 === 0 ? classes.odd : classes.even}
          variant="rect"
          width="100%"
          height="50px"
        />
      );
    }
    return arr;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
        <Typography className={classes.waitingText}>
          {" "}
          Please wait ...
        </Typography>
      </Backdrop>
      <GridDiv
        filterable={false}
        filter={dataState.filter}
        sortable={true}
        resizable={true}
        reorderable={true}
        pageable={{
          pageSizes: [10, 20, 50, 100],
          info: true,
          previousNext: true,
          buttonCount: 10,
        }}
        skip={dataState.skip}
        take={dataState.take}
        // filterOperators={filterOperators}
        onDataStateChange={dataStateChange}
        data={dataResult}
        total={totalResult}
        className="kendo_reports_recruiterActivity"
      >
        <GridNoRecords>
          {isSearching ? recruiterActivitySkeleton() : "No results found !"}
        </GridNoRecords>
        <GridColumn
          field="recruiterDetails.displayName"
          title="Recruiter Name"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="recruiterDetails.email"
          title="Email ID"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="assignedRequisitionsCount"
          title="Requisition Assigned"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="covered"
          title="Covered"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="uncovered"
          title="Uncovered"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="submitted"
          title="Submitted"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="clientDeclined"
          title="Client Declined"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="interviewScheduled"
          title="Interview Scheduled"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="selected"
          title="Selected"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="candidateDeclined"
          title="Candidate Declined"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="started"
          title="Started"
          width="180px"
          filterable={false}
        />
        <GridColumn
          field="dropout"
          title="Dropout"
          width="180px"
          filterable={false}
        />
      </GridDiv>
    </>
  );
};

export default RecruitersActivity;
