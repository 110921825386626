import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import "./pendingResumeValidationsForm.css";
import { Button, CircularProgress } from "@material-ui/core";
import { DateTime } from "luxon";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import csc from "../../utilities/country-state-city/dist";
import countriesList from "../../utilities/country-state-city/dist/lib/country.json"
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import {
    FormControl,
    OutlinedInput,
    FormHelperText
} from "@material-ui/core";
import getAllPrimarySkillData from "../../apis/getAllPrimarySkillData"
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import updatePendingResume from "../../apis/updateCandidateResumeById/updateCandidateResumeById";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
});


const PendingResumeValidationsForm = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { candidateResume, idContextTest, showPendingResumeForm, userType, dataResult, setDataResult, userRecruitmentpartnerId, candidateResumeId, setShowPendingResumeForm } = props;
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [atvBtn, setAtvBtn] = useState(false);
    // refactor code
    const [contactInfo, setContactInfo] = useState({
        emailID: "",
        emailError: "",
        phoneNumber: "",
        phoneCode: "",
        phoneError: true,
        phoneNumberInt: "",
        isPhoneNumberBlank: false,
        isEmailIdDisabled: "",
    });
    const [personalInfo, setPersonalInfo] = useState({
        name: "",
        uploadedBy: "",
        initialUploadDate: "",
        lastUploadDate: "",
        noticePeriod: "",
        yearsOfExperience: "",
        currentCompensation: "",
        expectedCompensation: "",
    })
    const [locationInfo, setLocationInfo] = useState({
        cityName: candidateResume?.city || "",
        stateName: candidateResume?.state || "",
        countryName: candidateResume?.country || "",
        cityList: [],
        stateList: [],
        countryList: countriesList,
        selectedCountry: "",
        selectedState: "",
        selectedCity: "",
        defaultCountryCode: "",
        currencyValue: "",
    });

    const [skillsInfo, setSkillsInfo] = useState({
        primarySkill: [],
        primarySkillOptions: [],
        selectedPrimarySkill: "",
        primarySkillTextFieldValue: "",
        additionalSkills: [],
        additionalSkillOptions: [],
        currentAdditionalSkillValue: "",
    });
    useEffect(() => {
        setContactInfo((prev) => ({
            ...prev,
            emailID: candidateResume?.emailID || ""
        }));
        if (candidateResume?.countryCallingCode && candidateResume.phoneNumber) {
            let ccode = candidateResume.countryCallingCode;
            let originalPhoneNumber = candidateResume.phoneNumber;
            let index = originalPhoneNumber.indexOf(ccode);
            originalPhoneNumber = originalPhoneNumber.replace(/[()-]/g, '');

            if (index === -1) {
                setContactInfo((prev) => ({ ...prev, phoneNumberInt: (ccode + originalPhoneNumber) }))
                setContactInfo((prev) => ({
                    ...prev,
                    phoneNumber: originalPhoneNumber
                }));
                setContactInfo((prev) => ({ ...prev, isPhoneNumberBlank: (ccode + originalPhoneNumber ? false : true) }))

                setContactInfo((prev) => ({ ...prev, phoneError: !isPossiblePhoneNumber(ccode + " " + originalPhoneNumber) }))

            } else {
                setContactInfo((prev) => ({ ...prev, phoneNumberInt: originalPhoneNumber }))

                setContactInfo((prev) => ({
                    ...prev,
                    phoneNumber: originalPhoneNumber
                }));
                setContactInfo((prev) => ({ ...prev, isPhoneNumberBlank: (originalPhoneNumber ? false : true) }))

                setContactInfo((prev) => ({ ...prev, phoneError: !(isPossiblePhoneNumber(originalPhoneNumber)) }))

            }
        }
        else {
            setContactInfo((prev) => ({ ...prev, phoneNumberInt: (candidateResume?.phoneNumber || "") }))

            setContactInfo((prev) => ({
                ...prev,
                phoneNumber: candidateResume?.phoneNumber || ""
            }));
            setContactInfo((prev) => ({ ...prev, isPhoneNumberBlank: (candidateResume?.phoneNumber ? false : true) }))

            if (candidateResume?.phoneNumber) {
                setContactInfo((prev) => ({ ...prev, phoneError: !(isPossiblePhoneNumber(candidateResume?.phoneNumber)) }))

            }
            else {
                setContactInfo((prev) => ({ ...prev, phoneError: false }))


            }
        }

        setPersonalInfo((prev) => ({ ...prev, name: candidateResume?.name || "" }))
        setSkillsInfo((prev) => ({ ...prev, selectedPrimarySkill: candidateResume?.primarySkill || "" }))

        let allState = []
        let allCity = []
        if (candidateResume?.country || candidateResume?.countryCallingCode) {
            let parsedCountry = []
            if (candidateResume?.country) {
                parsedCountry = locationInfo.countryList.filter((item) => item.name === candidateResume.country)
            } else {

                parsedCountry = locationInfo.countryList.filter((item) => item.phonecode === candidateResume.countryCallingCode)
            }
            setLocationInfo((prev) => ({ ...prev, selectedCountry: parsedCountry[0] }))

            setLocationInfo((prev) => ({ ...prev, defaultCountryCode: parsedCountry[0]?.isoCode }))
            setLocationInfo((prev) => ({ ...prev, countryName: parsedCountry[0]?.name }))
            setContactInfo((prev) => ({ ...prev, phoneCode: candidateResume?.countryCallingCode }))

            setLocationInfo((prev) => ({ ...prev, stateList: csc.getStatesOfCountry(parsedCountry[0]?.isoCode) }))

            allState = (csc.getStatesOfCountry(parsedCountry[0]?.isoCode));
            let candidateindex = candidateResume.countryCallingCode.indexOf('+')

            if (candidateResume.currency) {
                let currencydata = countriesList.filter((item) => item.currency === candidateResume.currency)
                setLocationInfo((prev) => ({ ...prev, currencyValue: currencydata[0] }))

            } else {

                if (candidateindex !== -1) {
                    let currencydata = countriesList.filter((item) => item.phonecode === candidateResume.countryCallingCode)
                    if (currencydata.length > 0) {
                        setLocationInfo((prev) => ({ ...prev, currencyValue: currencydata[0] }))

                    } else {
                        let countryCallingCode = candidateResume.countryCallingCode
                        countryCallingCode = countryCallingCode.replace(/[+]/g, '')
                        currencydata = locationInfo.countryList.filter((item) => item.phonecode === countryCallingCode)
                        if (currencydata.length > 0) {
                            setLocationInfo((prev) => ({ ...prev, currencyValue: currencydata[0] }))

                        }
                        else {
                            setLocationInfo((prev) => ({ ...prev, currencyValue: [] }))

                        }

                    }

                }
            }
        }

        if (candidateResume?.state) {
            let parsedState = allState.filter((item) => item.name === candidateResume.state)
            setLocationInfo((prev) => ({ ...prev, stateName: parsedState[0]?.name }))

            setLocationInfo((prev) => ({ ...prev, selectedState: parsedState[0]?.name }))

            setLocationInfo((prev) => ({ ...prev, cityList: csc.getCitiesOfState(parsedState[0]?.countryCode, parsedState[0]?.isoCode) }))

            allCity = (csc.getCitiesOfState(parsedState[0]?.countryCode, parsedState[0]?.isoCode));
        }
        if (candidateResume?.city) {
            let parsedCity = allCity.filter((item) => item.name === candidateResume.city)
            setLocationInfo((prev) => ({ ...prev, cityName: parsedCity[0]?.name }))

            setLocationInfo((prev) => ({ ...prev, selectedCity: parsedCity[0] }))

        }


        setPersonalInfo((prev) => ({ ...prev, uploadedBy: candidateResume?.recruiterName || "" }))
        setPersonalInfo((prev) => ({ ...prev, initialUploadDate: candidateResume?.initialUploadDate || "" }))

        setPersonalInfo((prev) => ({ ...prev, lastUploadDate: candidateResume?.lastUploadDate || "" }))
        setSkillsInfo((prev) => ({ ...prev, additionalSkills: candidateResume?.skills || [] }))

        setPersonalInfo((prev) => ({ ...prev, yearsOfExperience: candidateResume?.yr_of_exp || "" }))

        setPersonalInfo((prev) => ({ ...prev, currentCompensation: candidateResume?.currentSalary || "" }))

        setPersonalInfo((prev) => ({ ...prev, expectedCompensation: candidateResume?.expectedSalary || "" }))
        setPersonalInfo((prev) => ({ ...prev, noticePeriod: candidateResume?.noticePeriod || "" }))

        setContactInfo((prev) => ({ ...prev, isEmailIdDisabled: candidateResume?.isEmailIdDisabled || "" }))

    }, [candidateResume]);

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setContactInfo((prev) => ({
            ...prev,
            emailID: email
        }));
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            setContactInfo((prev) => ({ ...prev, emailError: "Invalid email address" }));
        } else {
            setContactInfo((prev) => ({ ...prev, emailError: "" }));
        }
    };

    const handlePhoneNumberChange = (val) => {
        if (val) {
            setContactInfo((prev) => ({ ...prev, isPhoneNumberBlank: false }))

            let userInputPhnNo = val;
            if (parsePhoneNumber(val)?.countryCallingCode) {
                let countryCallingCode_Input = `+${parsePhoneNumber(val).countryCallingCode}`;
                let phoneNumber_Input = val.replace(countryCallingCode_Input, "")
                userInputPhnNo = countryCallingCode_Input + " " + phoneNumber_Input;
            }
            setContactInfo((prev) => ({ ...prev, phoneNumberInt: userInputPhnNo }))


            if (val && isPossiblePhoneNumber(val)) {
                let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
                setContactInfo((prev) => ({ ...prev, phoneCode: countryCode_PhoneNumber }))

                const phNumber_withoutCountryCode = val.replace(countryCode_PhoneNumber, "")
                setContactInfo((prev) => ({
                    ...prev,
                    phoneNumber: phNumber_withoutCountryCode
                }));
                setContactInfo((prev) => ({ ...prev, phoneError: false }))

            } else {
                setContactInfo((prev) => ({ ...prev, phoneError: true }))

            }
        }
        else {
            setContactInfo((prev) => ({ ...prev, phoneError: false }))
            setContactInfo((prev) => ({
                ...prev,
                phoneNumber: ""
            }));
            setContactInfo((prev) => ({ ...prev, phoneCode: "" }))

            setContactInfo((prev) => ({ ...prev, isPhoneNumberBlank: true }))

        }
    };

    const openResume = () => {
        let resumeUrl = candidateResume.resumeUrl
        window.open(resumeUrl, '_blank');
    }

    const currencyChangeHandler = (e, value) => {
        if (value)
            setLocationInfo((prev) => ({ ...prev, currencyValue: value }))

    }

    const handleNameChange = (e) => {
        setPersonalInfo({ ...personalInfo, name: e.target.value })

    };

    const handleCityChange = (e, value) => {
        if (value === null) {
            setLocationInfo((prev) => ({ ...prev, cityName: "" }))

            setLocationInfo((prev) => ({ ...prev, selectedCity: "" }))

        } else {
            setLocationInfo((prev) => ({ ...prev, cityName: value.name }))

            setLocationInfo((prev) => ({ ...prev, selectedCity: value }))

        }
    };

    const handleStateChange = (e, value) => {
        if (value === null) {
            setLocationInfo((prev) => ({ ...prev, stateName: "" }))
            setLocationInfo((prev) => ({ ...prev, selectedState: "" }))


            setLocationInfo((prev) => ({ ...prev, cityList: [] }))

        } else {
            setLocationInfo((prev) => ({ ...prev, stateName: value.name }))

            setLocationInfo((prev) => ({ ...prev, selectedState: value }))

            setLocationInfo((prev) => ({ ...prev, cityList: csc.getCitiesOfState(value.countryCode, value.isoCode) }))


        }
        setLocationInfo((prev) => ({ ...prev, cityName: "" }))

        setLocationInfo((prev) => ({ ...prev, selectedCity: "" }))

    };


    const handleCountryChange = (event, value) => {
        if (!value) {
            setLocationInfo((prev) => ({ ...prev, countryName: "" }))

            setLocationInfo((prev) => ({ ...prev, selectedCountry: "" }))

            setLocationInfo((prev) => ({ ...prev, stateList: [] }))

            setLocationInfo((prev) => ({ ...prev, cityList: [] }))


        } else {
            setLocationInfo((prev) => ({ ...prev, countryName: value?.name }))

            setLocationInfo((prev) => ({ ...prev, selectedCountry: value }))

            setLocationInfo((prev) => ({ ...prev, currencyValue: value }))

            setLocationInfo((prev) => ({ ...prev, stateList: csc.getStatesOfCountry(value.isoCode) }))

            setLocationInfo((prev) => ({ ...prev, defaultCountryCode: value?.isoCode }))

        }
        setLocationInfo((prev) => ({ ...prev, selectedState: "" }))

        setLocationInfo((prev) => ({ ...prev, stateName: "" }))

        setLocationInfo((prev) => ({ ...prev, cityName: "" }))

        setLocationInfo((prev) => ({ ...prev, selectedCity: "" }))

        setLocationInfo((prev) => ({ ...prev, cityList: [] }))

    };



    useEffect(() => {
        const fetchPrimarySkills = async () => {
            try {
                const response = await getAllPrimarySkillData();
                const skills = response.data.data.map(skill => skill.name);

                setSkillsInfo((prev) => ({ ...prev, primarySkillOptions: skills }))
                setSkillsInfo((prev) => ({ ...prev, primarySkill: skills }))
            } catch (error) {
                console.error('Error fetching primary skills:', error);
            }
        };
        fetchPrimarySkills();
    }, []);


    const handlePrimarySkillsChange = (event, newValue) => {
        if (newValue) {
            setSkillsInfo((prev) => ({ ...prev, selectedPrimarySkill: newValue }))

        } else {
            setSkillsInfo((prev) => ({ ...prev, selectedPrimarySkill: "" }))

        } setSkillsInfo((prev) => ({ ...prev, primarySkill: skillsInfo.primarySkillOptions }))

    };

    const openDialog = () => {
        setIsDialogOpen(true);
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const handleYearsOfExperienceChange = (e) => {
        setPersonalInfo({ ...personalInfo, yearsOfExperience: e.target.value })


    }

    const handleCurrentCompensationChange = (e) => {
        setPersonalInfo({ ...personalInfo, currentCompensation: e.target.value })

    };

    const handleExpectedCompensationChange = (e) => {
        setPersonalInfo({ ...personalInfo, expectedCompensation: e.target.value })
    }

    const handleNoticePeriod = (e) => {
        setPersonalInfo({ ...personalInfo, noticePeriod: e.target.value })

    }

    const keypressHandler = (e) => {
        var invalidChars = ["-", "+", "e"];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const goToMyTalentPage = (e) => {
        if (!showPendingResumeForm) {
            userType === 'Admin'
                ? history.push({
                    pathname: "/recruiter-talent-community",
                    state: { id: location.state.id },
                })
                : history.push("/recruiter-dashboard");
        } else {
            setShowPendingResumeForm(false);
        }
    };

    const submitButtonHandler = async () => {
        setAtvBtn(true);
        let candidatesData = [...dataResult];

        const candidateParsing = candidateResume?.isParsing
        let payload = {};
        payload.emailID = contactInfo.emailID;
        payload.phoneNumber = contactInfo.phoneError ? "invalid" : contactInfo.phoneNumber;
        payload.name = personalInfo.name;
        payload.primarySkill = skillsInfo.selectedPrimarySkill;
        payload.city = locationInfo.cityName;
        payload.state = locationInfo.stateName;
        payload.country = locationInfo.countryName;
        payload.countryCode = locationInfo.selectedCountry?.isoCode;
        payload.countryCallingCode = contactInfo.phoneCode;
        payload.currency = locationInfo?.currencyValue?.currency;
        payload.skills = skillsInfo.additionalSkills;
        payload.yr_of_exp = personalInfo.yearsOfExperience;
        payload.currentSalary = parseInt(personalInfo.currentCompensation);
        payload.expectedSalary = parseInt(personalInfo.expectedCompensation);
        payload.noticePeriod = parseInt(personalInfo.noticePeriod);
        payload.lastUpdatedBy = idContextTest.idContextTest;

        if (payload.emailID === "") {
            setAtvBtn(false);
            NotificationManager.error(
                "Please enter an email address",
                "Error",
                2500
            );
        }
        else if (payload.primarySkill === "") {
            setAtvBtn(false);
            NotificationManager.error(
                "Please enter a Primary Skill.",
                "Error",
                2500
            );
        }
        else if (payload.country === "") {
            setAtvBtn(false);
            NotificationManager.error(
                "Please select a country",
                "Error",
                2500
            );
        }
        else if (payload.emailID !== "" && contactInfo.emailError) {
            setAtvBtn(false);
            NotificationManager.error(
                "Please enter a valid email address",
                "Error",
                2500
            );
        }
        else if (payload.phoneNumber === "invalid") {
            setAtvBtn(false);
            NotificationManager.error(
                "Please enter a valid phone number",
                "Error",
                2500
            );
        }
        else if (payload.phoneNumber === "" && candidateParsing === true) {
            setAtvBtn(false);
            NotificationManager.error(
                "Please enter a phone number",
                "Error",
                2500
            );
        }
        else {
            try {

                payload.recruitmentOrgId = userRecruitmentpartnerId;
                payload.isParsing = candidateParsing;
                const response = await updatePendingResume(candidateResumeId, payload);
                if (response.status === 202) {
                    setAtvBtn(false);
                    NotificationManager.error(`${response.data.message}`, "error");
                }
                else {
                    let candidatesData = [...dataResult];
                    let index = candidatesData.findIndex((item) => item.candidateResumeId === candidateResumeId)
                    if (index !== -1) {
                        if (payload.emailID && payload.primarySkill && payload.phoneNumber && payload.country && payload.countryCode) {
                            candidatesData[index].isProfileComplete = true
                        }

                        candidatesData[index].city = payload.city
                        candidatesData[index].country = payload.country
                        candidatesData[index].countryCallingCode = payload.countryCallingCode
                        candidatesData[index].currentSalary = payload.currentSalary
                        candidatesData[index].emailID = payload.emailID
                        candidatesData[index].expectedSalary = payload.expectedSalary
                        candidatesData[index].name = payload.name
                        candidatesData[index].phoneNumber = payload.phoneNumber
                        candidatesData[index].primarySkill = payload.primarySkill
                        candidatesData[index].skills = payload.skills
                        candidatesData[index].state = payload.state
                        candidatesData[index].yr_of_exp = payload.yr_of_exp
                        candidatesData[index].noticePeriod = payload.noticePeriod
                        candidatesData[index].currency = payload.currency
                        candidatesData[index].countryCode = payload.countryCode
                        setDataResult(candidatesData)
                        // setPhoneNumberInitial(candidatesData.phoneNumberInt);
                        goToMyTalentPage();
                        if (true) {
                            setAtvBtn(false);
                            NotificationManager.success("Resume is successfully updated", "success");
                        }
                    }
                }
            } catch (error) {
                if (error.response.status === 400) {
                    setAtvBtn(false);
                    const errorMessage = error.response.data.errMessage;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
                else {
                    NotificationManager.error("There is an error updating", "error");
                    setAtvBtn(false);
                }
                console.error("Error updating resume:", error);
            }
        }
    };

    const handlePrimarySkillTextFieldChange = (e) => {
        let typedSkill = e.target.value;
        if (typedSkill) {
            setSkillsInfo((prev) => ({ ...prev, primarySkillTextFieldValue: typedSkill }))
            // setPrimarySkillTextFieldValue(typedSkill);
            let arr = [];
            let isSkillExists = false;
            skillsInfo.primarySkillOptions.forEach(element => {
                if (element.toLowerCase().startsWith(typedSkill.toLowerCase())) {
                    arr.push(element)
                }
                if (element.toLowerCase() === typedSkill.toLowerCase()) {
                    isSkillExists = true
                }
            });
            if (!isSkillExists)
                arr.unshift(typedSkill)
            setSkillsInfo((prev) => ({ ...prev, primarySkill: arr }))
            // setPrimarySkill(arr)
        }
        else {
            setSkillsInfo((prev) => ({ ...prev, primarySkillTextFieldValue: "" }))
            // setPrimarySkillTextFieldValue("")
            setSkillsInfo((prev) => ({ ...prev, primarySkill: skillsInfo.primarySkillOptions }))
        }
    }

    const additionalSkillTextFieldHandler = (e) => {
        setSkillsInfo({ ...skillsInfo, currentAdditionalSkillValue: e.target.value })
        let arr = [];
        arr.push(e.target.value)
        console.log(arr)
        setSkillsInfo((prev) => ({ ...prev, additionalSkillOptions: arr }))
        // setAdditionalSkillOptions(arr)
    }


    const additionalSkillChangeHandler = (e, value) => {
        let lastValue = value[value.length - 1]
        let index = value.findIndex(option => option.toLowerCase() === lastValue.toLowerCase())
        if (index === -1 || index === value.length - 1) {
            setSkillsInfo((prev) => ({ ...prev, additionalSkills: value }))

            // setAdditionalSkills(value)
        }
        setSkillsInfo((prev) => ({ ...prev, additionalSkillOptions: [] }))

        // setAdditionalSkillOptions([])
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={3} wrap='wrap'>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Email Address*</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Email Address"
                            className={contactInfo.emailID !== "" ? "admin_textbox" : "admin_textbox_error"}
                            value={contactInfo.emailID}
                            onChange={handleEmailChange}
                            disabled={contactInfo.isEmailIdDisabled}
                        />
                        <FormHelperText error>{contactInfo.emailError}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={3} className='phoneNumber_pendingResume'>
                    <div className="inputLabel_right">Phone Number{candidateResume.isParsing === true ? "*" : ""}</div>
                    <FormControl fullWidth variant="outlined">
                        <PhoneInput
                            international
                            defaultCountry={locationInfo.defaultCountryCode}
                            className={((candidateResume.isParsing === true) && contactInfo.isPhoneNumberBlank) ? "admin_textbox_error" : "textbox_phone_input_pendingResume"}
                            placeholder="Enter Phone Number"
                            value={contactInfo.phoneNumberInt}
                            onChange={(val) => handlePhoneNumberChange(val)}
                            onKeyPress={(e) => keypressHandler(e)}
                        />
                        {contactInfo.phoneError && (
                            <FormHelperText error>Please enter a valid phone number</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Name</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Name"
                            className="admin_textbox"
                            value={personalInfo.name ? personalInfo.name : ""}
                            onChange={handleNameChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Primary Skill*</div>
                    <div className="autocomplete-container">
                        <Autocomplete
                            id="multiple-limit-tags"
                            getOptionLabel={(option) => option}
                            options={skillsInfo.primarySkill}
                            value={skillsInfo.selectedPrimarySkill}
                            freeSolo
                            className={skillsInfo.selectedPrimarySkill !== "" ? "admin_textbox" : "admin_textbox_primarySkill_error"}
                            onChange={handlePrimarySkillsChange}
                            filterSelectedOptions
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder='Select Primary Skill'
                                    variant="outlined"
                                    value={skillsInfo.primarySkillTextFieldValue}
                                    onChange={(e) => handlePrimarySkillTextFieldChange(e)}
                                    fullWidth
                                />
                            }
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="inputLabel_right">Additional Skill</div>
                    <div className="autocomplete-container">
                        <Autocomplete
                            id="multiple-limit-tags"
                            multiple
                            freeSolo
                            filterSelectedOptions
                            options={skillsInfo.additionalSkillOptions}
                            value={skillsInfo.additionalSkills}
                            getOptionLabel={(option) => option}
                            onChange={additionalSkillChangeHandler}
                            renderInput={(params) => (
                                <TextField {...params}
                                    variant='outlined'
                                    value={skillsInfo.currentAdditionalSkillValue}
                                    onChange={(e) => additionalSkillTextFieldHandler(e)}
                                    placeholder="Enter Skills" />
                            )}
                            renderTags={(value, getTagProps) => {
                                if (value.length > 3) {
                                    const firstTwo = value.slice(0, 3);
                                    const remainingCount = value.length - 3;
                                    return (
                                        <>
                                            {firstTwo.map((option, index) => (
                                                <Chip
                                                    style={option.length > 20 ? { width: "185px" } : {}}
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ))}
                                            <Chip
                                                label={`+${remainingCount} more`}
                                                onClick={openDialog}
                                            />
                                        </>
                                    );
                                }
                                return (
                                    value.map((option, index) => (
                                        <Chip
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                );
                            }}
                        />
                    </div>
                    <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
                        <DialogTitle>Additional Skills</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                id="multiple-limit-tags"
                                multiple
                                freeSolo
                                filterSelectedOptions
                                disableClearable={false}
                                options={skillsInfo.additionalSkillOptions}
                                value={skillsInfo.additionalSkills}
                                getOptionLabel={(option) => option}
                                onChange={additionalSkillChangeHandler}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        variant='outlined'
                                        value={skillsInfo.currentAdditionalSkillValue}
                                        onChange={(e) => additionalSkillTextFieldHandler(e)}
                                        placeholder="Enter Skills" />
                                )}

                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">Close</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

                <Grid item xs={3}>
                    <div className="inputLabel_right">Country*</div>
                    <Autocomplete
                        id="combo-box-demo"
                        className={locationInfo.selectedCountry !== "" ? "mt_14" : "admin_textbox_error"}
                        options={locationInfo.countryList}
                        autoHighlight
                        filterOptions={filterOptions}
                        getOptionLabel={(option) =>
                            option.name
                                ? option.name
                                : ""
                        }
                        value={locationInfo.selectedCountry}
                        onChange={handleCountryChange}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                required={true}
                                placeholder="Please Select Country"
                            />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">State</div>

                    <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={locationInfo.stateList}
                        autoHighlight
                        filterOptions={filterOptions}
                        getOptionLabel={(option) =>
                            option.name ? option.name : ""
                        }
                        value={locationInfo.selectedState}
                        onChange={handleStateChange}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                required={true}
                                placeholder="Please Select State"
                            />}
                    />
                </Grid>

                <Grid item xs={3}>
                    <div className="inputLabel_right">City</div>

                    <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={locationInfo.cityList}
                        autoHighlight
                        filterOptions={filterOptions}
                        getOptionLabel={(option) =>
                            option.name ? option.name : ""
                        }
                        value={locationInfo.selectedCity}
                        onChange={handleCityChange}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Please Select City" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Years of Experience</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Years of Experience"
                            className="admin_textbox"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={personalInfo.yearsOfExperience ? personalInfo.yearsOfExperience : ""}
                            onChange={handleYearsOfExperienceChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <div className="inputLabel_right">Currency</div>
                    <Autocomplete
                        id="combo-box-demo"

                        className='admin_textbox_currency'
                        options={locationInfo.countryList}
                        getOptionLabel={(option) =>
                            option?.name
                                ? option?.currency
                                : ""
                        }
                        value={locationInfo.currencyValue || ""}
                        onChange={currencyChangeHandler}
                        renderInput={(params) => <TextField {...params} placeholder="Currency" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <div className="inputLabel_right">Current Compensation</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Current Compensation"
                            className="admin_textbox"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={personalInfo.currentCompensation}
                            onChange={handleCurrentCompensationChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <div className="inputLabel_right">Currency</div>
                    <Autocomplete
                        id="combo-box-demo"
                        className='admin_textbox_currency'
                        placeholder="Currency"
                        options={locationInfo.countryList}
                        getOptionLabel={(option) =>
                            option?.name
                                ? option?.currency
                                : ""
                        }
                        value={locationInfo.currencyValue || ""}
                        onChange={currencyChangeHandler}
                        renderInput={(params) => <TextField {...params} placeholder="Currency" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <div className="inputLabel_right">Expected Compensation</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Expected Compensation"
                            className="admin_textbox"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={personalInfo.expectedCompensation}
                            onChange={handleExpectedCompensationChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Notice Period (Days)</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Notice Period"
                            className="admin_textbox"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={personalInfo.noticePeriod}
                            onChange={handleNoticePeriod}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Uploaded By*</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Uploaded By"
                            className="uploadedBy_textbox admin_textbox"
                            value={personalInfo.uploadedBy || ""}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Initial Upload Date*</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Initial Upload Date"
                            className="admin_textbox"
                            value={personalInfo.initialUploadDate ? DateTime.fromISO(personalInfo.initialUploadDate).toFormat("MM-dd-yyyy") : DateTime.fromISO(candidateResume?.createdAt).toFormat("MM-dd-yyyy")}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <div className="inputLabel_right">Last Upload Date*</div>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Last Upload Date"
                            className="admin_textbox"
                            value={personalInfo.lastUploadDate ? DateTime.fromISO(personalInfo.lastUploadDate).toFormat("MM-dd-yyyy") : DateTime.fromISO(candidateResume?.updatedAt).toFormat("MM-dd-yyyy")}
                            disabled
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <div className="pending-validations-actions">
                {showPendingResumeForm ? (
                    <>
                        <Button
                            variant="contained"
                            className={candidateResume.resumeUrl === "" ? "pending-validations-actions__viewResume_blank" : "pending-validations-actions__viewResume"}
                            onClick={openResume}
                            disabled={candidateResume.resumeUrl === "" ? true : false}
                        >
                            View Resume
                        </Button>



                        <button
                            className={userType === "Admin" ? 'pending-validations-actions__submit_disable' : atvBtn ? 'pending-validations-actions__submit_disable' : 'pending-validations-actions__submit'}
                            // className={userType === "Admin" ? 'pending-validations-actions__submit_disable' : 'pending-validations-actions__submit'}
                            onClick={() => submitButtonHandler()}
                            disabled={atvBtn || userType === "Admin" ? true : false }
                        >
                            <span>Submit</span>
                            {atvBtn ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                        </button>

                        <Button
                            variant="outlined"
                            className="pending-validations-actions__cancel"
                            onClick={(e) => goToMyTalentPage(e)}
                        >
                            Cancel
                        </Button>

                    </>
                )
                    : null
                }
            </div>
            <NotificationContainer />
        </div>
    );
}

export default PendingResumeValidationsForm;