// import { React, useState, useEffect } from 'react'
import React, {useEffect, useState } from 'react'
import './RecentActivities.css';
import { makeStyles } from '@material-ui/core';
import countallstatus from "../../apis/countallstatusforrequitionforcandidates/countallstatusforrequitionforcandidates";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    AssignRecruiterBtn: {
        backgroundColor: '#2ECA75',
        color: 'white',
        marginBottom: '10px',
        width: '100%',
        padding: '10px',
        fontFamily: 'Segoe UI',
        display: 'flex', // Added display flex
        flexDirection: 'row',
        '& .MuiButton-label': {
            display: 'flex', // Added display flex
            flexDirection: 'row',
            justifyContent: 'center'
        },
        '&:hover': {
            //make background color a little darker
            backgroundColor: '#32c173',
            color: 'white',
        },

    }
}));

function RecentActivities(props) {

    // const { userType, userIdType, userRecruitmentpartnerId } = useContext(UserTypeContext);
    const { requisitionId, userType, idContextTest, userRecruitmentpartnerId, userRole, userRecords, isSearching } = props
    const [allCandidate, setallCandidate] = useState(true);
    const [activCount, setactivCount] = useState();
    const jsonConfig = require("../../Config.json");

    const history = useHistory();
    
    

    useEffect(() => {
        getEmailOptions();
    }, [isSearching]);

    //For getting list of email ids
    const getEmailOptions = async () => {
        let allCount
        if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
            allCount = await countallstatus(userType === 'Admin' ? userRole : userType, userRecruitmentpartnerId, requisitionId)
        }
        else {
            allCount = await countallstatus(userType === 'Admin' ? userRole : userType, idContextTest, requisitionId)
        }
        setactivCount(allCount.data.statusCounts)
    };
    
    const reqSubmitted = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "SUBMITTED", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.SUBMITTED, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }
    const reqClientDeclined = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "CLIENT DECLINED", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.CLIENT_DECLINED, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }
    const reqInterview = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "INTERVIEW", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.INTERVIEW, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }
    const reqStarted = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "STARTED", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.STARTED, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }
    const reqDropout = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "DROPOUT", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.DROPOUT, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }
    const applicationWithdrawn = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "APPLICATION WITHDRAWN", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.APPLICATION_WITHDRAWN, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },
        });

    }

    const reqSelected = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "SELECTED", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.SELECTED, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }

    const reqCandidateDeclined = () => {
        setallCandidate(true);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "CANDIDATE DECLINED", requisitionId: requisitionId, statuscode: jsonConfig.candidateRequisitionStatusCode.CANDIDATE_DECLINED, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }

    const reqAllCandidates = () => {
        setallCandidate(false);
        history.push({
            pathname: `/activity/${requisitionId}`,
            state: { candidateState: "All Candidate", requisitionId: requisitionId, statuscode: "", userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, idContextTest: idContextTest },

        });

    }

    return (
        <div className='activities_cont'>
            <div className='recentActivities_Container' >
                <div className='recentActivities_heading'>
                    Requisition Activities
                </div>

                <div className='recentActivities_status' onClick={reqSubmitted}>
                    <div className='recentActivities_statusHead'>SUBMITTED</div>
                    <div className='recentActivities_statusValue'>{activCount?.SUBMITTED?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqInterview}>
                    <div className='recentActivities_statusHead'>INTERVIEW SCHEDULED</div>
                    <div className='recentActivities_statusValue'>{activCount?.INTERVIEW?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqSelected}>
                    <div className='recentActivities_statusHead'>SELECTED</div>
                    <div className='recentActivities_statusValue'>{activCount?.SELECTED?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqStarted}>
                    <div className='recentActivities_statusHead'>STARTED</div>
                    <div className='recentActivities_statusValue'>{activCount?.STARTED?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqDropout}>
                    <div className='recentActivities_statusHead'>DROPOUT</div>
                    <div className='recentActivities_statusValue'>{activCount?.DROPOUT?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqClientDeclined}>
                    <div className='recentActivities_statusHead'>CLIENT DECLINED</div>
                    <div className='recentActivities_statusValue'>{activCount?.CLIENTDECLINED?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={reqCandidateDeclined}>
                    <div className='recentActivities_statusHead'>CANDIDATE DECLINED</div>
                    <div className='recentActivities_statusValue'>{activCount?.CANDIDATEDECLINED?.count}</div>
                </div>

                <div className='recentActivities_status' onClick={applicationWithdrawn}>
                    <div className='recentActivities_statusHead'>APPLICATION WITHDRAWN</div>
                    <div className='recentActivities_statusValue'>{activCount?.APPLICATIONWITHDRAWN?.count}</div>
                </div>

                <div className='recentActivities_status_bottom'>
                    {allCandidate &&
                        <div className='recentActivities_ViewAllCandidates' onClick={reqAllCandidates}>View All Candidates</div>
                    }
                </div>
            </div>
            <NotificationContainer />
        </div>
    )
}

export default RecentActivities