import React, { useState } from 'react'
import Header from '../../components/headerAdmin/Header'
import Footer from '../../components/adminFooter/Footer'
import RequisitionDetailsBody from './RequisitionDetailsBody.js';
import { useHistory } from "react-router";
import "../RequisitionDetails/RequisitionDetails.css"

function RequisitionDetails(props) {
  const { match } = props;
  const [idContextTest, setIdContextTest] = useState(props?.location?.state?.id ? props?.location?.state?.id : props?.id);
  const [userType, setUserType] = useState(props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType);
  const [userRecruitmentpartnerId, setUserRecruitmentpartnerId] = useState(props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : props?.userRecruitmentpartnerId);
  const [userRecords, setUserRecords] = useState(props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords);
  const [userRole, setUserRole] = useState(props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType);
  const history = useHistory();

  return (
    <div  className='mainContainer_requisitionDetails'
    >
      <div className='topContainer_requisitionDetails'
      >
        <Header />

        <RequisitionDetailsBody
          requisitionId={match.params.id}
          idContextTest={idContextTest}
          userRecruitmentpartnerId={userRecruitmentpartnerId}
          userType={userType}
          userRecords={userRecords}
          userRole={userRole}
        />
      </div>
      <Footer />
    </div>
  )
}

export default RequisitionDetails;



