import React, { useState } from "react";
import './footer.css';
import Logo from './images/logo.png';
function Footer() {
  const today = new Date()
    const goToTerms = () => {
      window.open("/terms-of-use", "_self");
    }
    const goToPrivacyPolicy = () => {
      window.open("/privacy-policy", "_self");
    }

   return (
    <div>
        <div className="footer_bg">
          <div className="container w1004">
              <div className="footer_adjust">

              <div className="footerlogo_section">
                  <img src={Logo} className="mt_40 footerlogo" alt='logo'/>
                </div>
                <div className="footer_wrapper">
                    <ul className="footer_links">
                    <li className="poweresume-footer-copyright">ClearedTalent © {today.getFullYear()}.&nbsp; All Rights Reserved.</li>&nbsp;&nbsp;
                    <li><span className="poweresume-footer cp" onClick={goToTerms}>Terms of Use</span>&nbsp;&nbsp;
                    <span className="poweresume-footer cp" onClick={goToPrivacyPolicy}>Privacy Policy</span></li>
                    </ul>
                </div>
              </div>
          </div>
        </div>
    </div>
   )
}

export default Footer


