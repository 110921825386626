import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import interview from "../../pages/myTalentCommunity/icons/interview.png";
import DatePicker from "react-datepicker";
import { useDebounce } from "../recruitmentLeader/hook.js"

import "react-datepicker/dist/react-datepicker.css";
import { Skeleton } from "@progress/kendo-react-indicators";
import Badge from '@material-ui/core/Badge';
import getCandidateStatusByStatusCode from "../../apis/getCandidateStatusByStatusCode/getCandidateStatusByStatusCode";
import Tooltip from '@material-ui/core/Tooltip';
import getTrackCandidateInfo from "../../apis/getTrackCandidateInfo/getTrackCandidateInfo";

const useStyles = makeStyles({
  fontRoboto: {
    fontFamily: "Roboto,sans-serif",
  },
  button: {
    marginBottom: "10px",
  },
  interViewTableContainer: {
    height: "280px",
  }
});


const UpcomingStarts = (props) => {
  const {
    id, userType, userRecruitmentpartnerId, userRole
  } = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewData, setInterviewData] = useState([]);
  const [totalInterViewDataCount, setTotalInterviewDataCount] = useState(0)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const jsonConfig = require("../../Config.json");
  const [currentEventCount, setCurrentEventCount] = useState(0)

  const [page, setPage] = useState(0);
  const rowsPerPage= 5;

  const debouncedSearchStartDate = useDebounce(startDate, 300);
  const debouncedSearchEndtDate = useDebounce(endDate, 300);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function convertDate(dateString) {
    if (dateString !== "") {
      let date = new Date(dateString),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    else return ""
  }

  const startDateChange = (e) => {
    setStartDate(e)
    if (endDate === "") {
      setEndDate(e)
    }
  }

  const EndDateChange = (e) => {
    setEndDate(e)
    if (startDate === "") {
      setStartDate(e)
    }
  }

  const columns = [
    { id: 'candidateName', label: 'Candidate Name', minWidth: 100 },
    { id: 'referenceId', label: 'Reference ID', minWidth: 100 },
    { id: 'clientName', label: 'Client Name', minWidth: 100 },
    { id: 'interviewDate', label: 'Starting Date', minWidth: 100 },
  ];


  const getInterviewDetails = async (isScroll) => {
    let response;
    try {
      setIsDataLoading(true)

      let payloadData = {
        isCurrentEventCount: true,
        candidateStatusCode: jsonConfig.candidateRequisitionStatusCode.SELECTED,
        searchData: {}
      }
      if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
        payloadData.orgId = userRecruitmentpartnerId
      }
      else if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
        payloadData.misOperatorId = id
      }
      else if (userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) {
        payloadData.recruiterId = id
      }
      else if (userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")) {
        payloadData.recruitmentLeaderId = id
      }

      let candidateInterviewData = isScroll ? interviewData : []
      let today = new Date();
      let threeDaysLater = new Date();
      let twoDaysLater = new Date();
      let oneDaysLater = new Date();
      threeDaysLater = formatDate(threeDaysLater.setDate(today.getDate() + 3));
      twoDaysLater = formatDate(twoDaysLater.setDate(today.getDate() + 2));
      oneDaysLater = formatDate(oneDaysLater.setDate(today.getDate() + 1));

      let pageCount = isScroll ? page + 1 : 1
      setPage(pageCount)

      if (startDate && endDate) {
        payloadData.fromdate = convertDate(startDate)
        payloadData.todate = convertDate(endDate)
      }

      response = await getCandidateStatusByStatusCode(pageCount, rowsPerPage, payloadData);
      setCurrentEventCount(response.data?.currentEventCount || 0)
      setTotalInterviewDataCount(response.data?.totalCount)
      response = response.data?.data
      response.map((details) => {
        let candidateInterviewDate = formatDate(details.eventDate)
        candidateInterviewData.push({
          candidateName: details.name,
          requisitionId: details?.requisitionId.slice(-4),
          referenceId: details?.requisitiondetails?.reqreferenceId,
          clientName: details.clientdata?.clientName,
          interviewDate: formatDate(details.eventDate),
          email: details.email,
          interviewAlert: (candidateInterviewDate === formatDate(today) || candidateInterviewDate === oneDaysLater || candidateInterviewDate === twoDaysLater || candidateInterviewDate === threeDaysLater) ? true : false
        })
      })
      setInterviewData(candidateInterviewData);
      setIsDataLoading(false)
    } catch (error) {
      console.log("Error", error);
    }

  }

  const goToCandidateProfile = async (itemValue) => {
    const response = await getTrackCandidateInfo({
      candidateEmail: itemValue.email,
      recruitmentPartnerId: userRecruitmentpartnerId,
    });
    const userData = response?.data?.data?.userDetails[0]
    let url = '';
    if (userData?.powerResumeId) {
      url = 'candidate/profile/' + userData?.powerResumeId + '/' + userData.recruitmentOrgId
    }
    else {
      url = 'only-candidate-profile/' + userData?.candidateId
    }
    window.open(url, "_blank")
  }

  useEffect(() => {
    const loadUsers = async () => {
      await getInterviewDetails(false);
    };
    loadUsers();
  }, [debouncedSearchStartDate, debouncedSearchEndtDate]);

  return (
    <div>
      <div className="box-shadow candidateInterviewTable">
        <div className="table-header-recruitment-leader">
          <div className="recruitment-leader-left">
            <img src={interview} />
            {currentEventCount === 0 ? <p className="interview-text">Upcoming Starts</p> :
              <Tooltip title="Number of candidate having startdate as today" placement="top">
                <Badge badgeContent={currentEventCount} color="secondary">
                  <p>Upcoming Starts</p>
                </Badge>
              </Tooltip>
            }
          </div>
          <div className="datePickerContainer_recruitment-leader-left">
            <DatePicker
              selected={startDate}
              onChange={(e) => startDateChange(e)}
              dateFormat="dd-MMM-yyyy"
              showIcon
              placeholderText="Select Date"
            />
            <p className="date-inner-text">to</p>
            <DatePicker
              selected={endDate}
              onChange={(e) => EndDateChange(e)}
              dateFormat="dd-MMM-yyyy"
              showIcon
              placeholderText="Select Date"
              minDate={startDate ? startDate : new Date()}
            />
          </div>
        </div>

        <TableContainer
          className={classes.interViewTableContainer}
          onScroll={(e) => {
            if (
              e.target.scrollHeight - e.target.offsetHeight ===
              e.target.scrollTop
            ) {
              if (totalInterViewDataCount > interviewData.length && !isDataLoading)
                getInterviewDetails(true)
            }
          }}
        >

          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {interviewData.map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.code} className={row.interviewAlert ? "rec_interview_today" : "rec_upcomingDate"} onClick={(e) => goToCandidateProfile(row)}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              {!isDataLoading && interviewData.length === 0 ?
                <TableRow>
                  <TableCell colSpan={4} align="center">No data found !!!</TableCell>
                </TableRow>
                : ""}

              {isDataLoading ?
                [0, 1, 2, 3, 4].map((item) => (
                  <TableRow role="checkbox">
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                  </TableRow>
                ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UpcomingStarts