import axios from "axios";
const jsonConfig = require("../../Config.json");

const deleteCandidateRequisition = async (id) => {
    try {
        const resp = await axios.post(jsonConfig.apiUrl + `deleteCandidateRequisition/${id}`, {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(resp);
        console.log("resp",resp)
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default deleteCandidateRequisition;