import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllRecruiterRequisitions = async(offset, limit, statusCode, selectedFilter,recruiterId,searchText) => {
    try
    {
        const payload = {
            statusCode : statusCode === "ALL" ? "" : statusCode,
            filters : selectedFilter,
            recruiterId : recruiterId,
            searchText: searchText
        }
        const response = await axios.post(
            `${jsonConfig.apiUrl}getAllRecuiterRequisitions?offset=${offset}&limit=${limit}`, payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                }
            }
        );
        return Promise.resolve(response);
    }
    catch(error)
    {
        return Promise.reject(error);
    }
}

export default getAllRecruiterRequisitions