import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Badge, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import "./recruiters.css";
import { Loader } from '@progress/kendo-react-indicators';
const AllapprovedRecruitersKendoTable = ({ allapprovedRecruiters, isDataLoaded }) => {
  const history = useHistory();

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [result, setResult] = React.useState(allapprovedRecruiters.result);

  const dataStateChange = (event) => {
    setResult(process(allapprovedRecruiters, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {

    setResult(process(allapprovedRecruiters, dataState));

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const country = "country";
    const communitySize = "communitySize";
    // const userRole = "userRole";
    const agencyName = "agencyName";
    for (let i = 0; i < allapprovedRecruiters.length; i++) {
      allapprovedRecruiters[i][country] = allapprovedRecruiters[i].profile
        ? allapprovedRecruiters[i].profile.locationCountry
          ? JSON.parse(allapprovedRecruiters[i].profile.locationCountry).name
            ? JSON.parse(allapprovedRecruiters[i].profile.locationCountry).name
            : ""
          : ""
        : "";
      if (
        allapprovedRecruiters[i].role.roleName === "Admin"
        // allapprovedRecruiters[i].role.roleName == "Organization Admin"
      ) {
        allapprovedRecruiters[i][communitySize] = "N/A";
        allapprovedRecruiters[i][agencyName] = "N/A";
      } else {
        const candidates = allapprovedRecruiters[i].myCandidates;
        //Mapping for unique Candidates
        const key = "candidateid";
        const uniqueCandidates = [
          ...new Map(candidates.map((item) => [item[key], item])).values(),
        ];
        allapprovedRecruiters[i][communitySize] = uniqueCandidates.length;

        const rqDate = "requestDate";
        allapprovedRecruiters[i][rqDate] =
          allapprovedRecruiters[i].approvalDate != null
            ? handleRequestDateLuxon(allapprovedRecruiters[i])
            : "N/A";
        if (allapprovedRecruiters[i].agency) {
          allapprovedRecruiters[i][agencyName] =
            allapprovedRecruiters[i]?.agency.agencyName;
        } else allapprovedRecruiters[i][agencyName] = "N/A";
      }
    }

  }, [allapprovedRecruiters]);

  const handleRequestDateLuxon = (data) => {
    const date = data.approvalDate;
    const formatted = DateTime.fromISO(date)
      .toLocal()
      .startOf("day")
      .toJSDate();

    return formatted ? formatted : "N/A";
  };

  // const handlePhoneNumber = (props) => {
  //   return (
  //     <td>{props.dataItem.phoneNumber ? props.dataItem.phoneNumber : ""}</td>
  //   );
  // };

  // const handleOrganization = (props) => {
  //   return (
  //     <td>
  //       {props.dataItem.organization ? props.dataItem.organization.name : ""}
  //     </td>
  //   );
  // };

  const stylesforBadge = {
    textAlign: "center",
  };

  const handleAction = (props) => {
    return (
      <td>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleRowClick(e, props.dataItem)}
        >
          Go To Profile
        </Button>
      </td>
    );
  };

  const handleDate = (props) => {
    if (props.dataItem.approvalDate) {
      return (
        <td>
          {DateTime.fromISO(props.dataItem.approvalDate)
            .toFormat("MM/dd/yyyy")
            .toString()}
        </td>
      );
    } else {
      return (
        <td>
          N/A
        </td>
      )
    }

  };

  const handleActiveInactive = (props) => {
    return (
      <td style={stylesforBadge}>
        {props.dataItem.isActive ? (
          <Badge
            color="primary"
            badgeContent="Active"
            className="recruiter_badge"
          ></Badge>
        ) : (
          <Badge
            color="secondary"
            badgeContent="Inactive"
            className="recruiter_badge"
          ></Badge>
        )}
      </td>
    );
  };

  // const handleCountry = (props) => {
  //   return (
  //     <td>
  //       {props.dataItem?.profile?.locationCountry
  //         ? JSON.parse(props.dataItem?.profile?.locationCountry).name
  //         : ""}
  //     </td>
  //   );
  // };

  let columns = [
    { id: 0, name: "expanded", title: " ", filterable: true, width: "200px" },
    {
      id: 1,
      name: "action",
      title: "Action",
      cell: handleAction,
      filterable: false,
      width: "150px",
    },
    {
      id: 2,
      name: "firstName",
      title: "First Name",
      filterable: true,
      width: "200px",
    },
    {
      id: 3,
      name: "lastName",
      title: "Last Name",
      filterable: true,
      width: "200px",
    },
    { id: 4, name: "email", title: "Email", filterable: true, width: "250px" },
    {
      id: 5,
      name: "role.roleName",
      title: "User role",
      filterable: true,
      width: "200px",
    },
    {
      id: 6,
      name: "recruitmentpartner.name",
      title: "Recruitment Organization",
      filterable: true,
      width: "200px",
    },
    {
      id: 7,
      name: "agencyName",
      title: "Agency",
      filterable: true,
      width: "200px",
    },
    {
      id: 8,
      name: "agency.agencyType.name",
      title: "Agency Type",
      filterable: true,
      width: "200px",
    },
    {
      id: 9,
      name: "communitySize",
      title: "Community Size",
      filterable: true,
      width: "200px",
    },
    {
      id: 10,
      name: "phoneNumber",
      title: "Phone Number",
      filterable: true,
      width: "200px",
    },
    {
      id: 11,
      name: "country",
      filterable: true,
      title: "Country",
      width: "200px",
    },
    {
      id: 12,
      name: "requestDate",
      filterable: true,
      title: "Approved On",
      width: "200px",
      filter: "date",
      format: "{0:d},{0:t}",
      cell: handleDate,
    },
    {
      id: 13,
      name: "isActive",
      title: "Status",
      filterable: true,
      cell: handleActiveInactive,
      filter: "boolean",
      width: "200px",
    },
  ];

  const handleRowClick = (rowData = {}, data) => {
    if (data.role.roleName === "MIS Operator") {
      history.push({
        pathname: "/requisition-dashboard",
        state: { id: data?.id, recruitementPartnerId: data?.recruitmentpartner?.id, userType: "Admin", userRecords: data, userRole: data?.role?.roleName },
      });
    } else {
      history.push({
        pathname: "/dashboard",
        state: { id: data?.id, roleName: data?.role?.roleName, recruitmentPartnerId: data?.recruitmentpartner?.id, userRecords: data },
      });
    }
  };

  const CellRender = (props) => {
    // const dataItem = props.originalProps.dataItem;
    // const cellField = props.originalProps.field;
    const additionalProps = {};
    const clonedProps = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
  };

  const customCellRender = (td, props) => (
    <CellRender originalProps={props} td={td} />
  );

  return (
    <div className="containerMain">
      <Grid
        // className="hK"
        style={{
          maxHeight: "75Vh",
          width: "100%",
          overflow: "hidden"
        }}
        sort={dataState.sort}
        sortable={true}
        filter={dataState.filter}
        filterable={true}
        pageable={{
          buttonCount: 10,
          info: true,
          previousNext: true,
          pageSizes: true,
        }}
        resizable={false}
        skip={dataState.skip}
        take={dataState.take}
        data={result}
        reorderable={true}
        onDataStateChange={dataStateChange}
        cellRender={customCellRender}
      >
        {
          !isDataLoaded && (
            <GridNoRecords>
              <div className='col-4'>
                <br />
                <Loader size='large' themeColor="info" type="converging-spinner" />
              </div>
            </GridNoRecords>
          )}
        {columns.slice(1).map((column) => {
          return (
            <Column
              key={column.id}
              field={column.name}
              title={column.title}
              cell={column.cell}
              filter={column.filter}
              filterable={column.filterable}
              width={column.width}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default AllapprovedRecruitersKendoTable;
