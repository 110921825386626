import axios from "axios";
const jsonConfig = require("../../Config.json");

  export const getAllClients = async () => {
    try {
      const getResponse = await axios.get(
        jsonConfig.apiUrl + "clients",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const addClient = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "clients",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateClient = async (id, data) => {
    try {
      const getResponse = await axios.put(
        jsonConfig.apiUrl + "clients/" + id,
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getClientByOrg = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "clientByOrg",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };