import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from 'react-router-dom';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import './RequisitionDetails.css';
import { Autocomplete } from '@material-ui/lab';
import { Button, CircularProgress, Backdrop, Typography, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, TextField, TextareaAutosize, makeStyles } from '@material-ui/core';
import { CloudUpload, PersonAdd, Search } from '@material-ui/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RecentActivities from './RecentActivities';
import countriesList from "../../utilities/country-state-city/dist/lib/country.json"
import csc from "../../utilities/country-state-city/dist";
import getTotalYearsExperience from '../../apis/experience/getTotalYearsOfExp';
import GetAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus"
import getRequisitionDetailsbyId from '../../apis/getRequisitionDetailsbyId/getRequisitionDetailsbyId';
import getAllDivionByOrg from '../../apis/getAllDivionByOrg/getAllDivisionByOrg';
import recruiterLeadernameByDivision from '../../apis/recruiterLeadernameByDivision/recruiterLeadernameByDivision';
import updateRequisitionById from '../../apis/updateRequisitionById/updateRequisitionById';
import userAdd from "../../pages/myTalentCommunity/icons/userAdd.png";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import recruiterByLeaderAndDivision from '../../apis/recruiterByLeaderAndDivision/recruiterByLeaderAndDivision';
import assignRecruiterRequisition from '../../apis/assignRecruiterRequisition/assignRecruiterRequisition';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import configJSON from "../../Config.json";
import countallstatus from "../../apis/countallstatusforrequitionforcandidates/countallstatusforrequitionforcandidates";
import CancelIcon from '@material-ui/icons/Cancel';
import crypto from 'crypto';
import {
    getDivisionByOrg,
}
    from "../../apis/organisationAdminAPI/manageDivision";
import countNoOfFilesParsed from "../../apis/parsing/countNoOfFilesParsed";
import pdfIcon from "../../pages/myTalentCommunity/icons/PDFIcon.png"
import docIcon from "../../pages/myTalentCommunity/icons/doc.png";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Alert } from '@material-ui/lab';
import { BlobServiceClient } from '@azure/storage-blob';
import { DateTime } from "luxon";
import "../ATS_Dashboard/RecLead.css";
import getLocations from '../../apis/getLocations/getLocations';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const jsonConfig = require("../../Config.json");

const useStyles = makeStyles((theme) => ({
    customAutocomplete: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '8px', // Set the padding to 8px
        },
    },

    customDropdown: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '6.5px',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '&.MuiInput-underline:before': {
            border: 'none',
        },
        '&.MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent', // Set the background color to transparent when focused
        },

    },

    customTextField: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '3.5px',
        fontFamily: 'Segoe UI',
        // Remove the border
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:focus-visible fieldset': {
                border: 'none',
            },
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '&.MuiInput-underline:before': {
            border: 'none',
        },
        '&.MuiInput-underline:after': {
            border: 'none',
        },
        '&.MuiSelect-select:focus': {
            backgroundColor: 'transparent', // Set the background color to transparent when focused
        },



    },
    customNumberField: {
        '& .MuiOutlinedInput-root': {

            '& .MuiOutlinedInput-input': {
                padding: '13px',
            },
            //remove onactive border
            '&.MuiOutlinedInput-notchedOutline': {
                border: '1px solid lightgrey',
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid lightgrey',
            },
        },
    },
    customTextArea: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '8px',
        fontSize: '16px',
        fontFamily: 'Segoe UI',
        maxHeight: '100%',
        maxWidth: '100%',
        marginBottom: '10px',
        '&:focus': {
            outline: 'none',
            border: '1px solid lightgrey',
        },
    },
    customReqDetailsBtn: {
        backgroundColor: '#009EFD',
        color: 'white',
        padding: "7px 40px",
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: '#2D62ED',
            color: 'white',
        },
        '&:focus': {
            backgroundColor: '#2D62ED',
            color: 'white',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    AssignRecruiterBtn: {
        backgroundColor: '#2ECA75',
        color: 'white',
        marginBottom: '10px',
        width: '100%',
        padding: '10px',
        fontFamily: 'Segoe UI',
        display: 'flex', // Added display flex
        flexDirection: 'row',
        '& .MuiButton-label': {
            display: 'flex', // Added display flex
            flexDirection: 'row',
            justifyContent: 'center'
        },
        '&:hover': {
            //make background color a little darker
            backgroundColor: '#32c173',
            color: 'white',
        },

    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: '"Segoe UI Semibold", sans-serif !important',
        fontWeight: 'bold',
        fontSize: "10px",
        color: "#ffffff !important",
        textShadow: "0px 1px 2px black !important"
    },

}
));
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1A8FFF",
    },
    tooltip: {
        backgroundColor: "#1A8FFF",
        padding: "4px 13px",
        fontFamily: "Segoe-Semibold",
        fontSize: "13px",
    },
    buttonAlign: {
        display: "flex",
        alignItems: "center",
        padding: "24px 42px 24px 42px !important",
    },
}));


function RequisitionDetailsBody(props) {
    const { requisitionId, idContextTest, userType, userRecruitmentpartnerId, userRecords, userRole } = props;
    const history = useHistory();
    const [experienceLevel, setExperienceLevel] = useState([])
    const [requisitionResponseData, setRequisitionResponseData] = useState([])
    const [isClientChanged, setisClientChanged] = useState(false)
    const [status, setStatus] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [recruiterDetails, setRecruiterDetails] = useState([])
    const [defaultDivision, setDefaultDivision] = useState('')
    const [recruiterInfo, setRecruiterInfo] = useState([{
        "recrtuiterDetails": "",
        "openings": "",
        "division": "",
        "isBorrowed": false
    }]);
    const [sourceResume, setsourceResume] = useState("");
    const [recruiterInfoOld, setRecruiterInfoOld] = useState([{
        "recrtuiterDetails": "",
        "openings": "",
        "division": "",
        "isBorrowed": false
    }
    ]);
    const [divisionByOrgData, setDivisionByOrgData] = useState([])
    const [divisionWithRL, setDivisionWithRL] = useState([])
    const classes = useStyles();
    const [placementTypeOption, setPlacementTypeOption] = useState([
        { PositionType: "Direct Placement" }, { PositionType: "Contract" }, { PositionType: "Right To Hire" }, { PositionType: "Full Time" }, { PositionType: "Contract" }
    ]);
    const [clientNameOption, setClientNameOption] = useState([])
    const [divisionName, setDivisionName] = useState([]);
    const [divisionstate, setDivisionstate] = useState(false);
    const [priority, setPriority] = useState([
        { name: "High" }, { name: "Low" }
    ]);
    const [allRecruiterLeader, setallRecruiterLeader] = useState([]);
    const [countryList, setCountryList] = useState(countriesList);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [currencyBillRate, setCurrencyBillRate] = useState([
        { value: "INR/HOUR" }, { value: "INR/DAY" }, { value: "INR/YEAR" }
    ]);
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [cloneRequisitionModal, setCloneRequisitionModal] = useState(false)
    const [progressOpen, setprogressOpen] = useState(false)
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [progress, setProgress] = useState(0)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState({ text: '', visible: false });
    const [notificationText, setNotifcationText] = useState("")
    const [notificationType, setNotifiactionType] = useState("")
    const uploadedFileParseCount = useRef(0);
    const [locationSearchText, setLocationSearchText] = useState("")
    const [locationLoading, setLocationLoading] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [isReferenceIdCopied, setisReferenceIdCopied] = useState(false);
    const [oldAssignedRecruiterDetails, setOldAssignedRecruiterDetails] = useState([])
    const [atvBtn, setAtvBtn] = useState(false);
    const [requisitionDetailsData, setrequisitionDetailsData] = useState(
        {
            reqreferenceId: "",
            primarySkill: "",
            placementType: "",
            clientName: "",
            division: "",
            priority: "",
            location: [],
            minimumBillRate: "",
            maxiMumBillRate: "",
            currency: "",
            fee: "",
            startDate: "",
            endDate: "",
            openings: "",
            minimumExperienceLevel: "",
            maximumExperienceLevel: "",
            status: "",
            filledBy: "",
            assignedTo: "",
            jobDescription: "",
            documentLink: "",
        }
    )

    console.log({ requisitionDetailsData })

    const changeCopyText = () => {
        setisReferenceIdCopied(false);
    };
    const copyReferenceId = (e, referenceId) => {
        setisReferenceIdCopied(true);
        navigator.clipboard.writeText(referenceId);
        setTimeout(changeCopyText, 3000);
    };

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    const getWrappedReferenceId = (refId) => {
        return (
            <BootstrapTooltip title={isReferenceIdCopied ? "Copied" : "Click to copy Reference Id"}>
                <div
                    className='reqDetails-referenceId'
                    title={isReferenceIdCopied ? "Copied" : "Click to copy Email"}
                    onClick={(e) => copyReferenceId(e, refId)}
                >
                    {refId}
                    <FileCopyIcon style={{ marginRight: '5px', color: '#31B3F8' }} />

                </div>
            </BootstrapTooltip>
        );
    }


    const goToDashBoard = () => {
        if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
            history.push({
                pathname: "/requisition-dashboard",
                state: { id: idContextTest, recruitmentPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
            });
        } else {
            history.push({
                pathname: "/dashboard",
                state: {
                    userType: userType,
                    recruitmentPartnerId: userRecruitmentpartnerId,
                    id: idContextTest,
                    userRecords: userRecords,
                    roleName: userRole
                }
            });
        }
    };

    const goToSearchCandidate = () => {
        history.push({
            pathname: `/candidate-search-talent-community/${requisitionId}`,
            state: { id: idContextTest, recruitementPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
        });
    }

    const openModal = async () => {
        try {
            let recruiterResponse;
            if (requisitionResponseData.divisionassigneForReq.length > 0) {
                let divisionIdArr = []
                let index = requisitionResponseData.divisionassigneForReq.indexOf(requisitionResponseData.divisionId)
                if (index === -1) {
                    divisionIdArr.push(requisitionResponseData.divisionId)
                }
                requisitionResponseData.divisionassigneForReq.forEach((item) => {
                    divisionIdArr.push(item)
                })
                recruiterResponse = await recruiterByLeaderAndDivision({ "divisionIds": divisionIdArr });
            }
            else {
                recruiterResponse = await recruiterByLeaderAndDivision({ "divisionIds": [requisitionResponseData.divisionId] });
            }
            recruiterResponse = recruiterResponse.data.data
            setRecruiterDetails(recruiterResponse || [])
            let recruiterDetailsData = requisitionResponseData.recruiterRequisitionDetails
            let recruiterModelData1 = []
            let recruiterModelData2 = []
            if (recruiterDetailsData.length > 0) {
                let recruiterDetailsData_all = recruiterDetailsData
                if (userType === 'Recruiter') {
                    recruiterDetailsData_all = recruiterDetailsData.filter((item) => item.recruiterId === idContextTest)
                }
                recruiterDetailsData_all.forEach((item) => {
                    const divDetails = divisionWithRL?.filter((division) => division.divisionId === item.division.divisionId)[0]
                    let selectedDivisionsRecruiter = recruiterResponse.filter((item) => item._id === divDetails.divisionId)[0]
                    let recruiterObj = {
                        "recrtuiterDetails": selectedDivisionsRecruiter.recruiterDetails.filter((recruiter) => recruiter.id === item.recruiterId)[0],
                        "openings": item?.no_of_positions,
                        "division": divDetails,
                        "isBorrowed": item.isBorrowed
                    }
                    recruiterModelData1.push(recruiterObj)
                    recruiterModelData2.push(recruiterObj)
                })
                setRecruiterInfo(recruiterModelData1)
                setRecruiterInfoOld(recruiterModelData2)
            }
            setmodalIsOpen(true);
        } catch (error) {
            console.log(error, "error");
        }
    }
    const closeModal = () => {
        setmodalIsOpen(false);
        setRecruiterDetails([])
        setRecruiterInfo([{
            "recrtuiterDetails": "",
            "openings": "",
            "division": defaultDivision ? defaultDivision : "",
            "isBorrowed": false
        }])
    }

    const addMoreField = () => {
        const recruiterObj = {
            "recrtuiterDetails": "",
            "openings": "",
            "division": defaultDivision ? defaultDivision : "",
            "isBorrowed": false
        }
        if (recruiterInfo) {
            setRecruiterInfo([...recruiterInfo, recruiterObj])
        }
    };
    const closeExtraForms = (index) => {

        const currRecData = recruiterInfo.filter((o, i) => i === index);
        const checkRecruiterCandidates = checkRecruiterCandidateCount(currRecData[0].recrtuiterDetails.id);

        checkRecruiterCandidates.then((res) => {
            if (res) {
                NotificationManager.error("Candidates are already assigned to this recruiter", "Error", 2000);
                return;
            } else {
                const filteredData = recruiterInfo.filter((o, i) => i !== index);
                setRecruiterInfo(filteredData);
            }
        })




    };

    const handleUploadResumes = () => {
        setShowResumeModal(true);
    }

    const closeEditModal = () => {
        setShowResumeModal(false);
        setSelectedFiles([]);
        setErrorMessage('');
    };

    const cloneConfirmRequisitionHandler = () => {
        setCloneRequisitionModal(true);
    }

    const closeCloneRequisitionModal = () => {
        setCloneRequisitionModal(false);
    }

    const handleFileChange = (event) => {

        const files = event.target.files;

        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const selectedFilesArray = [...selectedFiles];

        for (let i = 0; i < files.length; i++) {
            const fileExtension = files[i].name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage({ text: 'Only PDF and DOC files are allowed.', visible: true });
                setTimeout(() => {
                    setErrorMessage({ text: '', visible: false });
                }, 5000);
                return;
            }

            if (selectedFilesArray.length + 1 > 5) {
                setErrorMessage({ text: 'You can only select up to 5 files.', visible: true });
                setTimeout(() => {
                    setErrorMessage({ text: '', visible: false });
                }, 5000);
                return;
            }
            selectedFilesArray.push(files[i]);
        }

        setErrorMessage({ text: '', visible: false });
        setSelectedFiles(selectedFilesArray);
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();

        if (extension === 'pdf') {
            return <img src={pdfIcon} alt="PDF Icon" className="custom-icon" />;
        } else if (extension === 'doc' || extension === 'docx') {
            return <img src={docIcon} alt="DOC Icon" className="custom-icon" />;
        } else {
            return <InsertDriveFileIcon />;
        }
    };

    const removeSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    }

    const uploadResumeToBlobStorage = async (filesArray) => {

        if (filesArray.length === 0) {
            setErrorMessage({ text: 'Select a file first.', visible: true });
            setTimeout(() => {
                setErrorMessage({ text: '', visible: false });
            }, 5000);
            return;
        }
        else {
            if (sourceResume === "")
                NotificationManager.error("Source field can not be blank", "Error", 2000);
            else {
                setBackDropOpen(true)
                closeEditModal()
                setprogressOpen(true)
                try {
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
                    }, 800);

                    const sas = decodeURI(jsonConfig.sasResumeToken);


                    const bsClient = new BlobServiceClient(
                        `https://${jsonConfig.blobStorageResumeAccountName}.blob.core.windows.net${sas}`
                    );

                    const containerClient = bsClient.getContainerClient("staging");
                    if (userRecruitmentpartnerId) {
                        let filnameWithtimearr = []
                        filesArray.map(async (blob) => {
                            let date = DateTime.now();
                            let currentDate = date.toFormat("yyyy-MM-dd");
                            let formattedDate = date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");
                            var v = blob.name.split(".");
                            let uuid = create_UUID();
                            let hashUuid = hash_UUID(uuid);
                            let blobName = currentDate + "/" + idContextTest + "/" + hashUuid + "." + v[v.length - 1];
                            const blobClient = containerClient.getBlockBlobClient(blobName);
                            const blobUploadOptions = {
                                blobHTTPHeaders: {
                                    blobContentType: blob.type,
                                },
                                metadata: {
                                    "createdtime": formattedDate,
                                    "fullname": blob.name,
                                    "uploadedtype": "Recruiter Upload",
                                    "organizationid": userRecruitmentpartnerId,
                                    "blobfilename": blobName,
                                    "source": sourceResume
                                },
                            };
                            filnameWithtimearr.push({ "blobfilename": blobName, "createdtime": formattedDate, "organizationid": userRecruitmentpartnerId })
                            await blobClient.uploadBrowserData(blob, blobUploadOptions);

                        });
                        // await Promise.all(uploadPromises);
                        if (filesArray.length === filnameWithtimearr.length) {
                            const intervalID = setInterval(() => {
                                if (stopCondition(filnameWithtimearr)) {
                                    delayedFunction(filnameWithtimearr);
                                } else {
                                    clearInterval(intervalID);
                                    setprogressOpen(false)
                                    setBackDropOpen(false)

                                    history.push({
                                        pathname: "/pending-resume-validations",
                                        state: { id: idContextTest },
                                    });
                                }
                            }, filesArray.length * 5000);
                            // return () => clearInterval(interval);  // Clear the interval when the component unmounts
                        }

                    }



                    setNotifiactionType('success')
                    setNotifcationText('Resume uploaded')
                    setSelectedFiles([]);
                } catch (error) {
                    setNotifiactionType('error')
                    setNotifcationText('Resume upload failed')
                    setSelectedFiles([]);
                    console.error("An error occurred during upload:", error);
                }
            }
        }

    };

    function create_UUID() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt >>>= 4; // unsigned right shift to ensure a 32-bit integer
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }

    function hash_UUID(uuid) {
        const sha256 = crypto.createHash('sha256');
        sha256.update(uuid);
        return sha256.digest('hex');
    }

    function stopCondition(filnameWithtimearr) {

        if (uploadedFileParseCount.current === filnameWithtimearr.length) {
            return false
        } else {
            return true
        }
    }

    const delayedFunction = async (filnameWithtimearr) => {
        //call api
        const response = await countNoOfFilesParsed(filnameWithtimearr);

        if (response.status === 200) {

            uploadedFileParseCount.current = response.data.count;
        }
    }

    const checkRecruiterCandidateCount = async (recruiterId) => {
        if (recruiterId) {
            const res = await countallstatus(userType, recruiterId, requisitionId, true);

            function isDataAvailable(data) {
                for (const key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        if (data[key].count !== 0) {
                            return true;
                        }
                    }
                }
                return false;
            }

            const checkDataAvailable = isDataAvailable(res.data.statusCounts);
            return checkDataAvailable;
        } else
            return false;
    }
    const getRequisitionDetail = async () => {
        try {
            let requisitionResponse = await getRequisitionDetailsbyId(requisitionId, { userType: userType === 'Admin' ? userRole : userType })
            requisitionResponse = requisitionResponse.data.data[0]
            setRequisitionResponseData(requisitionResponse)

            let ClientResponse = await getClientByOrg({ recruitmentPartnerId: userRecruitmentpartnerId });
            setClientNameOption(ClientResponse.data.data)

            const TotalExpYearresponse = await getTotalYearsExperience();
            setExperienceLevel(TotalExpYearresponse);

            let requisitionStatusResponse = await GetAllRequisitionStatus();
            setStatus(requisitionStatusResponse.data.data)

            let payload = {
                isManageClient: false
            }
            let divisionResponse = await getAllDivionByOrg(userRecruitmentpartnerId, payload);
            recruiterLeaderByDivision(requisitionResponse?.divisionId)


            let isDisabled = true;

            if (userType === 'MIS Operator') {
                let requisitionStatus_id = requisitionResponse.recruiterLeaderRequisitionDetails.status.subCodeId
                let OpenStatus = (requisitionStatusResponse.data.data.filter((item) => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN))[0].subList
                let unassignedStatus = OpenStatus.filter((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED)[0]
                if (unassignedStatus._id === requisitionStatus_id) {
                    isDisabled = false
                }
            }
            let clientNameData = (ClientResponse.data.data.filter((item) => item._id === requisitionResponse.clientDetails._id))[0];
            let divisionNameData = (divisionResponse.data.data.filter((item) => item._id === requisitionResponse.divisionId))[0];
            setrequisitionDetailsData({
                ...requisitionDetailsData,
                primarySkill: requisitionResponse.primarySkill,
                reqreferenceId: requisitionResponse.reqreferenceId,
                placementType: (placementTypeOption.filter((item) => item.PositionType === requisitionResponse.placementType))[0],
                clientName: clientNameData,
                priority: priority.filter((item) => item.name === requisitionResponse.priority)[0],
                minimumBillRate: requisitionResponse.billRate.minimum,
                maxiMumBillRate: requisitionResponse.billRate.maximum,
                currency: currencyBillRate.filter((item) => item.value === requisitionResponse.currency)[0],
                fee: requisitionResponse.fee,
                location: requisitionResponse.location,
                startDate: new Date(requisitionResponse.startDate) || "",
                endDate: new Date(requisitionResponse.endDate) || "",
                openings: requisitionResponse.openings,
                jobDescription: requisitionResponse.jobDescription,
                minimumExperienceLevel: requisitionResponse?.minExperience,
                maximumExperienceLevel: requisitionResponse?.maxExperience,
                status: (requisitionStatusResponse.data.data.filter((item) => item._id === requisitionResponse.status.statusCodeId))[0],
                filledBy: requisitionResponse.filledBy,
                division: divisionNameData,
                assignedTo: requisitionResponse.recruiterLeaderDetails,
                isDisabled: isDisabled,
                documentLink: requisitionResponse.uploadDocuments,
                oldAssignedTo: requisitionResponse.recruiterLeaderDetails,
            });
            setOldAssignedRecruiterDetails(requisitionResponse.recruiterDetails)
            setIsDataLoading(false)

            const divi1 = clientNameData.assignedDivisions;
            const arr1 = divi1.flatMap(item => item);
            setDivisionName(arr1);
        } catch (error) {
            console.log(error)
        }

    }

    const allDivisonData = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId,
            isActive: true
        }
        try {
            let response = await getDivisionByOrg(payload);
            setDivisionByOrgData(response.data.data)
            const divisionFilteredData = await response?.data?.data?.flatMap(entry => {
                if (entry.recruiterLeader.length === 1) {
                    return { divisionName: entry.divisionName, divisionId: entry._id, recruitmentLeader: entry.recruiterLeader[0] };
                } else {
                    return entry.recruiterLeader.map(leader => ({
                        divisionName: entry.divisionName,
                        divisionId: entry._id,
                        recruitmentLeader: leader
                    }));
                }
            })
            setDivisionWithRL(divisionFilteredData)

            const defaultDivisionValue = divisionFilteredData?.filter((v) => {
                return v.recruitmentLeader?.id === localStorage.getItem("userID");
            })[0]
            setDefaultDivision(defaultDivisionValue)


            const originalData = [...recruiterInfo];
            const changingData = originalData[recruiterInfo.length - 1];

            if (defaultDivisionValue) {
                changingData["recrtuiterDetails"] = '';
                changingData["openings"] = '';
                changingData["maxAllowedSubmission"] = '';
                changingData["division"] = defaultDivisionValue;
                changingData["isBorrowed"] = false;

            }
            else {
                changingData["division"] = "";
            }
            originalData[recruiterInfo.length - 1] = changingData;
            setRecruiterInfo(originalData || []);


        } catch (error) {

            console.log(error);
        }
    };
    useEffect(() => {
        getRequisitionDetail()
        allDivisonData()
    }, [])



    //----------- Field Handlers ------------

    const primarySkillHandler = (e) => {
        const value = e.target.value;
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, primarySkill: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, primarySkill: "" });
        }
    }
    const placementTypeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, placementType: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, placementType: "" });
        }
    }

    const clientNameChangeHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, clientName: value, division: "", assignedTo: "" });
            setDivisionstate(true);
            const divi = value.assignedDivisions;
            const arr = divi.flatMap(item => item);
            setDivisionName(arr);
            setisClientChanged(true);
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, clientName: "", division: "", assignedTo: "" });
        }
    }

    const divisionChangeHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, division: value, assignedTo: "" });
            recruiterLeaderByDivision(value._id)
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, division: "", assignedTo: "" });
        }
    }

    const recruiterLeaderByDivision = async (id) => {
        try {
            let recruiterLeaderData = await recruiterLeadernameByDivision(id);
            recruiterLeaderData = recruiterLeaderData.data.data[0]?.recruiterLeader
            setallRecruiterLeader(recruiterLeaderData ? [recruiterLeaderData] : [])

        } catch (error) {
            console.log(error);
        }
    }

    const priorityChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, priority: value });

        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, priority: "" });
        }
    }







    const currencyBillRateChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, currency: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, currency: "" });
        }
    }

    const feeChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, fee: event.target.value });
    }


    const startDateChangeHandler = (date) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, startDate: date });
    };

    const endDateChangeHandler = (date) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, endDate: date });
    }

    const openingChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, openings: event.target.value });
    }

    const minimumExperienceHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, minimumExperienceLevel: event.target.value });
    }

    const maximumExperienceHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, maximumExperienceLevel: event.target.value });
    }


    const getAllTotalExperience = async () => {
        try {
            const TotalExpYearresponse = await getTotalYearsExperience();
            setExperienceLevel(TotalExpYearresponse);
        } catch (error) {
            console.error(error?.response?.data);
        }
    }

    const allRequisitionStatus = async () => {
        try {
            let response = await GetAllRequisitionStatus();
            setStatus(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const statusChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, status: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, status: "" });
        }
    }

    const filledByChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, filledBy: event.target.value });

    }

    const assignToChangeHandler = (e, value) => {
        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, assignedTo: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, assignedTo: "" });
        }

    }

    const jobDescriptionChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, jobDescription: event.target.value });
    }

    const minimumbillRateChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, minimumBillRate: event.target.value });
    }

    const MaximumbillRateChangeHandler = (event) => {
        setrequisitionDetailsData({ ...requisitionDetailsData, maxiMumBillRate: event.target.value });
    }

    const openResume = (resumeUrl) => {
        window.open(resumeUrl, "_blank");
    };

    const recruiterEmailChangeHandler = (value, index) => {



        const currRecData = recruiterInfo.filter((o, i) => i === index);
        const checkRecruiterCandidates = checkRecruiterCandidateCount(currRecData[0].recrtuiterDetails.id);

        checkRecruiterCandidates.then((res) => {
            if (res) {
                NotificationManager.error("Candidates are already assigned to this recruiter", "Error", 2000);
                return;
            } else {
                emailUpdateFunc(value, index);
            }
        })



        const emailUpdateFunc = async (value, index) => {
            const originalData = [...recruiterInfo];
            const changingData = originalData[index];
            if (value) {
                changingData["recrtuiterDetails"] = value;
            }
            else {
                changingData["recrtuiterDetails"] = "";
            }
            originalData[index] = changingData;
            setRecruiterInfo(originalData || []);
        }
    }

    const recruiterDivisionChangeHandler = async (value, index) => {

        const currRecData = recruiterInfo.filter((o, i) => i === index);
        const checkRecruiterCandidates = checkRecruiterCandidateCount(currRecData[0].recrtuiterDetails.id);

        checkRecruiterCandidates.then((res) => {
            if (res) {
                NotificationManager.error("Candidates are already assigned to this recruiter", "Error", 2000);
                return;
            } else {
                DataUpdateFunc(value, index);
            }
        })


        const DataUpdateFunc = async (value, index) => {
            const originalData = [...recruiterInfo];
            const changingData = originalData[index];

            let divisionIdList = [];

            if (recruiterInfo.length > 1) {
                recruiterInfo.forEach((item) => {
                    divisionIdList.push(item.division.divisionId)
                })
                divisionIdList.push(value.divisionId)
            }
            else {
                divisionIdList.push(value.divisionId)
            }


            if (value) {
                changingData["recrtuiterDetails"] = '';
                changingData["openings"] = '';
                changingData["maxAllowedSubmission"] = '';
                changingData["division"] = value;
                if (value !== defaultDivision) {
                    changingData["isBorrowed"] = true;
                } else {
                    changingData["isBorrowed"] = false;
                }

            }
            else {
                changingData["division"] = "";
            }
            originalData[index] = changingData;
            setRecruiterInfo(originalData || []);

            let recruitersOnDivision = await recruiterByLeaderAndDivision({ "divisionIds": divisionIdList });
            recruitersOnDivision = recruitersOnDivision.data.data
            setRecruiterDetails(recruitersOnDivision || [])
        }


    }

    const openingHandleChange = (e, index) => {

        const originalData = [...recruiterInfo];
        const changingData = originalData[index];
        if (e.target.value) {
            changingData["openings"] = parseInt(e.target.value);
        } else {
            changingData["openings"] = "";
        }
        originalData[index] = changingData;
        setRecruiterInfo(originalData || []);
    }

    const getFilteredRecruiterDetails = (selectedData) => {

        let recruiterOptions = [];
        if (selectedData.division) {
            recruiterOptions = recruiterDetails.filter((item) => item._id === selectedData.division.divisionId)[0]?.recruiterDetails;
        }
        else {
            recruiterOptions = recruiterDetails[0]?.recrtuiterDetails;
        }
        let selectedRecruiters = [];
        if (recruiterOptions) {
            recruiterOptions.forEach(element => {
                let index = recruiterInfo.findIndex((item) => item?.recrtuiterDetails?.id === element?.id)
                if (index === -1) {
                    selectedRecruiters.push(element)
                }
            });
        }
        return selectedRecruiters;
    }

    const addAssignRequisition = async () => {

        let totalnoOfOpenings = 0;
        let isBlank = false;
        recruiterInfo.forEach((item) => {
            if (item.recrtuiterDetails === "" || item.openings === "") {
                NotificationManager.error("Please fill in all the fields", "Error", 2000);
                isBlank = true
                return;
            }
            else if (item.openings <= 0) {
                NotificationManager.error("Openings should be greater than 0", "Error", 2000);
                isBlank = true
                return;
            }
            totalnoOfOpenings += parseInt(item.openings)
        })
        // console.log(totalnoOfOpenings,"totalnoOfOpeningstotalnoOfOpenings")
        if (!isBlank) {
            if (requisitionDetailsData.openings < totalnoOfOpenings) {
                NotificationManager.error(`Total openings should not be more than ${requisitionDetailsData?.openings}`, "Error", 2000);
                return;
            }
            else {
                const removedRecruiters = findRemovedRecruiters();

                let payload = {
                    requisitionId: requisitionId,
                    referenceId: requisitionDetailsData?.reqreferenceId,
                    recruiterDetails: recruiterInfo,
                    requisitionDetailId: requisitionResponseData._id,
                    requisitionLeaderId: requisitionResponseData.recruiterLeaderRequisitionDetails._id,
                    removedRecruiters: removedRecruiters || []
                }
                let subStatus = (requisitionDetailsData.status.subList.filter((item) => item.subName === "UNCOVERED"))[0]
                payload.status = {
                    subCode: subStatus.subCode, statusCode: requisitionDetailsData.status.statusCode,
                    statusCodeId: Object(requisitionDetailsData.status._id), subCodeId: Object(subStatus._id)
                }
                try {
                    let noticationArray = [];

                    if (requisitionResponseData?.recruiterRequisitionDetails?.length > 0) {

                        for (let i = 0; i < oldAssignedRecruiterDetails.length; i++) {
                            let isPresent = false;
                            for (let j = 0; j < recruiterInfo.length; j++) {
                                if (oldAssignedRecruiterDetails[i].id === recruiterInfo[j].recrtuiterDetails.id) {
                                    isPresent = true;
                                    break;
                                }
                            }
                            if (isPresent === false) {
                                let notifObj = {};
                                notifObj.id = oldAssignedRecruiterDetails[i].id;
                                notifObj.email = oldAssignedRecruiterDetails[i].email;
                                notifObj.name = oldAssignedRecruiterDetails[i].displayName;
                                notifObj.isAssigned = false;
                                noticationArray.push(notifObj)
                            }
                        }

                        for (let i = 0; i < recruiterInfo.length; i++) {
                            let isPresent = false;
                            for (let j = 0; j < oldAssignedRecruiterDetails.length; j++) {
                                if (recruiterInfo[i].recrtuiterDetails.id === oldAssignedRecruiterDetails[j].id) {
                                    isPresent = true;
                                    break;
                                }
                            }
                            if (isPresent === false) {
                                let notifObj = {};
                                notifObj.id = recruiterInfo[i].recrtuiterDetails.id;
                                notifObj.email = recruiterInfo[i].recrtuiterDetails.email;
                                notifObj.name = recruiterInfo[i].recrtuiterDetails.displayName;
                                notifObj.isAssigned = true;
                                noticationArray.push(notifObj)
                            }

                        }
                        payload.notification_to_rec = noticationArray;
                    } else {

                        recruiterInfo.forEach((item) => {
                            let notifObj = {};
                            notifObj.id = item.recrtuiterDetails.id;
                            notifObj.email = item.recrtuiterDetails.email;
                            notifObj.name = item.recrtuiterDetails.displayName;
                            notifObj.isAssigned = true;
                            noticationArray.push(notifObj)
                        })
                        payload.notification_to_rec = noticationArray
                    }

                    if (noticationArray.length > 0) {
                        payload.misOperdetails = { "id": requisitionResponseData.misOperatorDetails.id, "email": requisitionResponseData.misOperatorDetails.email, "name": requisitionResponseData.misOperatorDetails.displayName };
                        payload.senderDetail = { "id": requisitionResponseData.recruiterLeaderDetails.id, "name": requisitionResponseData.recruiterLeaderDetails.displayName }
                    }


                    await assignRecruiterRequisition(payload);


                    if (requisitionResponseData?.recruiterRequisitionDetails?.length > 0) {
                        NotificationManager.success("Recruiter assignment updated successfully", "Success", 2000);
                    }
                    else {
                        NotificationManager.success("Recruiters assignment done successfully", "Success", 2000);
                    }
                    setmodalIsOpen(false);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } catch (error) {
                    console.log(error, "error");
                }

            }
        }
    }

    const getLocationsBreakDown = (locations) => {
        let city = []
        let state = []
        let country = []
        locations.forEach((item) => {
            let itemArr = item.split(",")
            if (itemArr.length === 1) {
                country.push(itemArr[0].trim())
                state.push(null)
                city.push(null)
            }
            else if (itemArr.length === 2) {
                state.push(itemArr[0].trim())
                country.push(itemArr[1].trim())
                city.push(null)
            }
            else {
                city.push(itemArr[0].trim())
                state.push(itemArr[itemArr.length - 2].trim())
                country.push(itemArr[itemArr.length - 1].trim())
            }
        })
        let locationObj = {
            city: city,
            state: state,
            country: country
        }
        return locationObj;
    }



    const saveHandler = async () => {
        setAtvBtn(true)
        if (requisitionDetailsData.primarySkill === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Primary Skill",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.clientName === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Client Name",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.location.length === 0) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Please enter Location",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.openings === 0 ) {
            setAtvBtn(false)
            NotificationManager.error(
                "Openings should be greater than 0",
                "Error",
                2500
            );
        }
        else if (!requisitionDetailsData.openings ) {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter a value in openings",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.assignedTo === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Assign To",
                "Error",
                2500
            );
        } else if (requisitionDetailsData.division === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Division",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.priority === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Priority",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.placementType === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Placement Type",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionDetailsData.maxiMumBillRate) < parseInt(requisitionDetailsData.minimumBillRate)) {
            setAtvBtn(false)
            NotificationManager.error(
                "Minimum Bill Rate cannot be more than Maximum Bill Rate.",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.maximumExperienceLevel === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Maximum Experience",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.minimumExperienceLevel === "") {
            setAtvBtn(false)
            NotificationManager.error(
                "Please enter Minimum Experience",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionDetailsData.maximumExperienceLevel) < parseInt(requisitionDetailsData.minimumExperienceLevel)) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Minimum Experience cannot be more than Maximum Experience.",
                "Error",
                2500
            );
        }
        else if (requisitionDetailsData.maximumExperienceLevel < 1) {
            setAtvBtn(false)
            await NotificationManager.error(
                "Maximum years of Experience cannot be less than 1 year"
            )
        }
        else {
            let isRecruiterLeaderChanged = false;
            if (requisitionDetailsData.assignedTo.id !== requisitionDetailsData.oldAssignedTo.id) {
                isRecruiterLeaderChanged = true
            }
            let locationObj = getLocationsBreakDown(requisitionDetailsData.location)
            let payload = {
                primarySkill: requisitionDetailsData.primarySkill,
                placementType: requisitionDetailsData.placementType.PositionType,
                clientId: requisitionDetailsData.clientName._id,
                divisionId: requisitionDetailsData.division._id,
                city: locationObj.city,
                state: locationObj.state,
                country: locationObj.country,
                location: requisitionDetailsData.location,
                minimumBillRate: requisitionDetailsData.minimumBillRate,
                maxiMumBillRate: requisitionDetailsData.maxiMumBillRate,
                currency: requisitionDetailsData.currency?.value,
                fee: requisitionDetailsData.fee,
                startDate: requisitionDetailsData.startDate,
                endDate: requisitionDetailsData.endDate,
                openings: requisitionDetailsData.openings,
                maxExperience: requisitionDetailsData?.maximumExperienceLevel,
                minExperience: requisitionDetailsData?.minimumExperienceLevel,
                priority: requisitionDetailsData.priority.name,
                filledBy: requisitionDetailsData?.status?.statusCode === configJSON?.requisitionStatusCodes?.FILLED ? requisitionDetailsData.filledBy : "",
                recruitmentLeaderId: requisitionDetailsData.assignedTo.id,
                recruitmentLeaderEmailId: requisitionDetailsData.assignedTo.email,
                jobDescription: requisitionDetailsData.jobDescription,
                misOperatorDetails: { id: userRecords.id, name: userRecords.displayName },
                oldAssignedTo: requisitionDetailsData.oldAssignedTo,
                isRecruiterLeaderChanged: isRecruiterLeaderChanged,
                isClientChanged: isClientChanged,
                reqreferenceId: requisitionDetailsData?.reqreferenceId
            }
            if (requisitionDetailsData.status.statusCode === configJSON?.requisitionStatusCodes?.OPEN) {
                let subStatus = (requisitionDetailsData.status.subList.filter((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED))[0]
                payload.status = {
                    subCode: subStatus.subCode, statusCode: requisitionDetailsData.status.statusCode,
                    statusCodeId: Object(requisitionDetailsData.status._id), subCodeId: Object(subStatus._id)
                }
            } else {
                payload.status = { statusCode: requisitionDetailsData.status.statusCode, statusCodeId: Object(requisitionDetailsData.status._id) }
            }
            try {
                await updateRequisitionById(requisitionId, payload)
                setAtvBtn(false)
                NotificationManager.success("Requisition updated succsesfully", "Success", 2000);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (error) {
                console.log(error, "error")
                setAtvBtn(false)
            }
        }
    }

    const cloneRequistion_data = {
        reqreferenceId: requisitionDetailsData.reqreferenceId,
        primarySkill: requisitionDetailsData.primarySkill,
        placementType: requisitionDetailsData.placementType,
        clientName: requisitionDetailsData.clientName,
        division: requisitionDetailsData.division,
        priority: requisitionDetailsData.priority,
        location: requisitionDetailsData.location,
        minimumBillRate: requisitionDetailsData.minimumBillRate,
        maxiMumBillRate: requisitionDetailsData.maxiMumBillRate,
        currency: requisitionDetailsData.currency,
        fee: requisitionDetailsData.fee,
        startDate: requisitionDetailsData.startDate,
        endDate: requisitionDetailsData.endDate,
        openings: requisitionDetailsData.openings,
        minimumExperienceLevel: requisitionDetailsData.minimumExperienceLevel,
        maximumExperienceLevel: requisitionDetailsData.maximumExperienceLevel,
        status: requisitionDetailsData.status,
        filledBy: requisitionDetailsData.filledBy,
        assignToValue: requisitionDetailsData.assignedTo,
        jobDescription: requisitionDetailsData.jobDescription,
        documentLink: requisitionDetailsData.documentLink,
    }

    const cloneRequisitionHandler = async () => {
        history.push({
            pathname: "/create-requisition",
            state: { requisitionData: cloneRequistion_data, divisionName: divisionName, isClone: true }
        });
    }
    function findRemovedRecruiters() {

        const removedRecruiters = [];


        let oldRecruiterIds = requisitionResponseData.recruiterDetails;
        oldRecruiterIds.forEach((item) => {
            let index = -1
            for (let i = 0; i < recruiterInfo.length; i++) {
                if (item.id === recruiterInfo[i].recrtuiterDetails.id) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                removedRecruiters.push(item.id)
            }
        })

        return removedRecruiters;
    }

    const locationTextFieldChangeHandler = (e) => {
        setLocationLoading(true)
        setLocationList([])
        setLocationSearchText(e.target.value)
    }
    const locationChangeHandler = (e, value) => {

        if (value) {
            setrequisitionDetailsData({ ...requisitionDetailsData, location: value });
        } else {
            setrequisitionDetailsData({ ...requisitionDetailsData, location: [] });
        }
        setLocationList([])
        setLocationSearchText("")
    }

    const getLocationResult = async (searchText) => {
        try {
            let response = await getLocations(searchText)
            response = response.data.data
            let locationArr = []
            response.forEach((item) => {
                let str = ""
                str += (item.name).trim()
                if (item.state) {
                    str += ", " + (item?.state?.name).trim()
                }
                if (item.country) {
                    str += ", " + (item?.country?.name).trim()
                }
                locationArr.push(str)
            })
            setLocationList(locationArr)
            setTimeout(() => {
                setLocationLoading(false);
            }, 1000);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (locationSearchText.length > 0) {
                setLocationLoading(true)
                getLocationResult(locationSearchText)
            }
            else {
                setLocationLoading(false)
                setLocationList([])
            }
        }, 700);

        return () => clearTimeout(timeout);
    }, [locationSearchText])

    useEffect(() => {
        getAllTotalExperience();
        allRequisitionStatus();
        if (userType === 'MIS Operator') {
            setrequisitionDetailsData([]);
        }
    }
        , []);
    return (
        <div className='reqDetailsContainer'>
            <div className='titlesofCommunity'>Requisition Details</div>
            <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashBoard(e)}>
                <ArrowBackIcon />&nbsp; Go Back to Dashboard
            </div>
            <div className='reqDetailsGrid'>
                {isDataLoading ? <div className='loader_requisitionDetails'>
                    <CircularProgress />
                </div> :
                    <div className='reqDetailsLeftFormContainer'>
                        <div className='reqDetailsLeftCardGrid'>
                            {
                                requisitionDetailsData?.reqreferenceId ?
                                    <div className='reqDetailsBasicInfo'>
                                        <div>
                                            <div className='reqDetails_headings'>Reference ID</div>
                                            <div className="candidateDetails">{requisitionDetailsData?.reqreferenceId ?
                                                getWrappedReferenceId(requisitionDetailsData.reqreferenceId)
                                                : ""}</div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Primary Skill*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Primary Skills"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.primarySkill}
                                        onChange={primarySkillHandler}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Placement Type*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={placementTypeOption}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    getOptionLabel={(option) => option.PositionType || ""}
                                    value={requisitionDetailsData?.placementType || ''}
                                    onChange={placementTypeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Placement Type"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Client Name*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={clientNameOption}
                                    disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled : true}
                                    getOptionLabel={(option) => option.clientName || ""}
                                    value={requisitionDetailsData?.clientName || ''}
                                    onChange={clientNameChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Client Name"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Division*</div>

                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={divisionName}
                                    getOptionLabel={(option) =>
                                        option.divisionName ? option.divisionName : ""
                                    }
                                    disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled : true}
                                    value={requisitionDetailsData?.division || ''}
                                    freeSolo={requisitionDetailsData.isDisabled}
                                    onChange={divisionChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Division"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Priority*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={priority}
                                    getOptionLabel={(option) => option.name || ""}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    value={requisitionDetailsData?.priority || ''}
                                    onChange={priorityChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Priority"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Minimum Bill Rate</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Bill Rate"
                                        className="createRequisition_textbox_outline"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        type='number'
                                        value={requisitionDetailsData?.minimumBillRate}
                                        onChange={minimumbillRateChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Maximum Bill Rate</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Bill Rate"
                                        disabled={userType === "MIS Operator" ? false : true}
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        value={requisitionDetailsData?.maxiMumBillRate}
                                        onChange={MaximumbillRateChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Currency Unit</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    disabled={userType === "MIS Operator" ? false : true}
                                    options={currencyBillRate}
                                    getOptionLabel={(option) =>
                                        option.value ? option.value : ""
                                    }
                                    value={requisitionDetailsData?.currency || ''}
                                    onChange={currencyBillRateChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Currency Unit"
                                        />
                                    }
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Fee(%)</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Fee"
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.fee}
                                        onChange={feeChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>

                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Opening*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Openings"
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.openings}
                                        onChange={openingChangeHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>

                            <div className='reqDetailsBasicInfo_location'>
                                <div className='reqDetails_headings'>Location*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    multiple={true}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    loading={locationLoading}
                                    loadingText={<CircularProgress />}
                                    options={locationList}
                                    getOptionLabel={(option) =>
                                        option ? option : ""
                                    }
                                    filterSelectedOptions
                                    autoHighlight
                                    value={requisitionDetailsData?.location || []}
                                    onChange={locationChangeHandler}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            value={locationSearchText}
                                            onChange={(e) => locationTextFieldChangeHandler(e)}
                                            required={true}
                                            placeholder="Select locations"
                                        />
                                    }
                                />
                            </div>


                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Start Date</div>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker"
                                    selected={requisitionDetailsData?.startDate}
                                    onChange={startDateChangeHandler}
                                    dateFormat="MM/dd/yyyy"
                                    disabled={userType === "MIS Operator" ? false : true}
                                    showIcon
                                    minDate={new Date()}
                                />
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>End Date</div>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker"
                                    selected={requisitionDetailsData?.endDate}
                                    onChange={endDateChangeHandler}
                                    disabled={userType === "MIS Operator" ? false : true}
                                    dateFormat="MM/dd/yyyy"
                                    showIcon
                                    minDate={new Date()}
                                />
                            </div>

                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Minimum Experience*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Minimum Experience"
                                        className={"createRequisition_textbox_outline"}
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.minimumExperienceLevel}
                                        onChange={minimumExperienceHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Maximum Experience*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Maximum Experience"
                                        className="createRequisition_textbox_outline"
                                        type='number'
                                        disabled={userType === "MIS Operator" ? false : true}
                                        value={requisitionDetailsData?.maximumExperienceLevel}
                                        onChange={maximumExperienceHandler}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>

                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Status</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    className="createRequisition_textbox"
                                    options={status}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    disabled={userType === "Recruiter" || userType === 'Admin' ? true : false}
                                    value={requisitionDetailsData.status || ""}
                                    onChange={statusChangeHandler}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            placeholder="Status"
                                        />
                                    }
                                />
                            </div>
                            {requisitionDetailsData?.status?.name === 'FILLED' ?
                                <div className='reqDetailsBasicInfo'>
                                    <div className='reqDetails_headings'>Filled By</div>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            placeholder="Filled By"
                                            className="createRequisition_textbox_outline"
                                            value={requisitionDetailsData?.filledBy}
                                            type='text'
                                            onChange={filledByChangeHandler}
                                        />
                                    </FormControl>
                                </div> : ""
                            }
                            <div className='reqDetailsBasicInfo'>
                                <div className='reqDetails_headings'>Assigned To*</div>
                                <FormControl fullWidth variant="outlined">
                                    {requisitionDetailsData?.assignedTo ?
                                        <Tooltip

                                            title={< React.Fragment >
                                                <h3 >{`${requisitionDetailsData?.assignedTo?.email || ""}`}</h3>
                                            </React.Fragment>}>
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className="createRequisition_textbox"
                                                options={allRecruiterLeader}
                                                getOptionLabel={(option) =>
                                                    option.email ? option.email : ""
                                                }
                                                filterSelectedOptions
                                                autoHighlight
                                                freeSolo={requisitionDetailsData.isDisabled}
                                                disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled : true}
                                                value={requisitionDetailsData?.assignedTo || ""}
                                                onChange={assignToChangeHandler}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Assign To"
                                                    />
                                                }
                                            />
                                        </Tooltip> :
                                        <Autocomplete
                                            id="multiple-limit-tags"
                                            className="createRequisition_textbox"
                                            options={allRecruiterLeader}
                                            getOptionLabel={(option) =>
                                                option.email ? option.email : ""
                                            }
                                            filterSelectedOptions
                                            autoHighlight
                                            freeSolo={requisitionDetailsData.isDisabled}
                                            disabled={userType === "MIS Operator" ? requisitionDetailsData.isDisabled : true}
                                            value={requisitionDetailsData?.assignedTo || ""}
                                            onChange={assignToChangeHandler}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Assign To"
                                                />
                                            }
                                        />}

                                </FormControl>
                            </div>

                        </div>
                        <div className='reqDetailsBasicInfo_jobDesCription textAreaJD'>
                            <div className='reqDetails_headings'>Job Description</div>
                            <TextareaAutosize
                                minRows={7}
                                maxRows={7}
                                aria-label="maximum height"
                                placeholder="Job Description"
                                className={classes.customTextArea}
                                disabled={userType === "MIS Operator" ? false : true}
                                value={requisitionDetailsData?.jobDescription || ''}
                                onChange={jobDescriptionChangeHandler}
                            />
                        </div>
                        <div className='reqDetailsBasicInfo_ActionBtnsContainer'>
                            <div className='buttonContainer_viewRequisition_outside'>
                                {
                                    userType === "Recruiter" ? "" :
                                        <>
                                            {userType === "Admin" ? "" :
                                            
                                                <button variant="contained" className={atvBtn?"saveButton_viewRequisition_disable":"saveButton_viewRequisition"} onClick={(e) => saveHandler()} 
                                                disabled={atvBtn}
                                                
                                                >
                                                    <span>Save</span>
                                                    {atvBtn ? (<CircularProgress />) : null}                                       
                                                </button>
                                            }
                                            <Button variant='outlined' className="cancelButton_viewRequisition" onClick={(e) => goToDashBoard(e)}>
                                                Cancel
                                            </Button>
                                        </>
                                }
                                {
                                    userType === "MIS Operator" ?
                                        <Button variant='contained' className="cloneRequisitionButton_viewRequisition" onClick={(e) => cloneConfirmRequisitionHandler(e)}>
                                            Clone Requisition
                                        </Button> : ""
                                }
                            </div>


                            {requisitionDetailsData.documentLink ?
                                <div className='viewButton_container_viewRequisition'>
                                    <Button variant="contained" className="viewDocumentButton_viewRequisition" onClick={(e) => openResume(requisitionDetailsData.documentLink)}>View Document</Button>
                                </div> : ""
                            }

                        </div>
                    </div>
                }

                {/* Right Side Activity Card */}
                <div className='reqDetailsRightCard'>
                    <RecentActivities requisitionId={requisitionId} idContextTest={idContextTest} userType={userType} userRecords={userRecords} userRole={userRole} userRecruitmentpartnerId={userRecruitmentpartnerId} />

                    <div className='recentActivities_BtnGroup'>
                        {userType === "Recruitment Leader" || userType === "Recruiter" || userType === "Organization Admin" ?
                            <>
                                {isDataLoading ? "" :
                                    userType === "Recruitment Leader" || userType === "Recruiter" || (userType === "Organization Admin" && requisitionResponseData?.recruiterRequisitionDetails?.length > 0) ?
                                        <Button variant="contained" className='button_AssignRecruiter' onClick={(e) => openModal()}>
                                            <div className='maindivButton_requisitionBody'>
                                                {((userType === "Recruiter") || (requisitionResponseData?.recruiterRequisitionDetails?.length > 0)) ? <VisibilityIcon className='iconDivision_viewRequisition' /> : <PersonAdd className='iconDivision_viewRequisition' />}
                                                {(userType === "Recruiter") ? <span>View Assignment</span> : (requisitionResponseData?.recruiterRequisitionDetails?.length > 0) ? <span>View Assigned Recruiter</span> : <span>Assign Recruiter</span>}
                                            </div>
                                        </Button> : ""}
                                {userType === "Recruitment Leader" || userType === "Recruiter" ?
                                    <Button variant="contained" className='button_candidateSearch' onClick={(e) => goToSearchCandidate()}>
                                        <div className='maindivButton_requisitionBody'>
                                            <Search className='iconDivision_viewRequisition' />
                                            <span>Candidate Search</span>
                                        </div>
                                    </Button>
                                    : ""}
                                {userType === "Recruitment Leader" || userType === "Recruiter" ?
                                    <Button variant="contained" className='button_uploadResume' onClick={(e) => handleUploadResumes()}>
                                        <div className='maindivButton_requisitionBody'>
                                            <CloudUpload className='iconDivision_viewRequisition' />
                                            <span>Upload Resume</span>
                                        </div>
                                    </Button>
                                    : ""}
                            </>
                            : ""
                        }
                    </div>
                </div>

            </div >
            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth='lg'
                open={modalIsOpen}
                className='dialogBox_assignRequisition'
            >
                <DialogTitle id="customized-dialog-title" className="header" onClose={closeModal}>
                    <div className='header_top'>
                        {userType === "Recruiter" || userType === "Organization Admin" ? "" : <img src={userAdd} alt=''></img>}
                        {userType === "Recruiter" || userType === "Organization Admin" ? "Assigned Recruiter" : "Assign Requisition"}
                    </div>
                </DialogTitle>
                <DialogContent >
                    <Table>
                        <TableHead>
                            <TableRow className='header-text-popup'>
                                <TableCell className='header-text-popup-2'>Division</TableCell>
                                <TableCell className='header-text-popup-2'>Email</TableCell>
                                <TableCell className='header-text-popup-2'>Name</TableCell>
                                <TableCell className='header-text-popup-1'>No. of Positions</TableCell>
                            </TableRow>
                        </TableHead>
                        {recruiterInfo.map((recruiter, i) => (
                            <TableBody>
                                <>

                                    <TableCell>
                                        <Autocomplete
                                            id="multiple-limit-tags"
                                            className='input-text-3'
                                            options={divisionWithRL || []}
                                            getOptionLabel={(option) =>
                                                option.divisionName ? option?.divisionName + ' - ' + option?.recruitmentLeader?.firstName + " " + option?.recruitmentLeader?.lastName : ""
                                            }
                                            value={recruiter?.division || "" || defaultDivision}
                                            filterSelectedOptions
                                            disabled={userType === "Recruiter" || userType === "Organization Admin"}
                                            disableClearable
                                            onChange={(e, val) => recruiterDivisionChangeHandler(val, i)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Select Division"
                                                />
                                            }
                                        />

                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            id="multiple-limit-tags"
                                            className='input-text-2'
                                            options={getFilteredRecruiterDetails(recruiter) || []}
                                            getOptionLabel={(option) =>
                                                option.email ? option.email : ""
                                            }
                                            disableClearable
                                            value={recruiter.recrtuiterDetails || ""}
                                            filterSelectedOptions
                                            disabled={userType === "Recruiter" || userType === "Organization Admin"}
                                            onChange={(e, val) => recruiterEmailChangeHandler(val, i)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Select Email"
                                                />
                                            }
                                        />

                                    </TableCell>
                                    <TableCell className="input-text-other">
                                        <FormControl variant="filled">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                placeholder="Enter Name"
                                                className="input-text-2"
                                                disabled
                                                value={recruiter.recrtuiterDetails?.displayName || ""}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell className="input-text">
                                        <FormControl variant="filled">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                type='number'
                                                placeholder="Enter positions"
                                                className="input-text-1"
                                                disabled={userType === "Recruiter" || userType === "Organization Admin"}
                                                value={recruiter?.openings || ""}
                                                onChange={(e) => openingHandleChange(e, i)}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    {userType === "Recruiter" || userType === "Organization Admin" ? "" :
                                        <div className='close_icon_right'>
                                            {(i !== null && recruiterInfo?.length > 1) && (
                                                <CancelRoundedIcon
                                                    onClick={() => {
                                                        closeExtraForms(i);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    }

                                </>
                            </TableBody>
                        ))}
                    </Table>
                    {userType === "Recruiter" || userType === "Organization Admin" ? "" :
                        <div className="invite_candidate_add_refer_icon_wrap cp">
                            <>
                                <div className="df add_btn_style">
                                    <div className="add_btn_assign">
                                        <AddCircleIcon
                                            color="primary"
                                            style={{ fontSize: 50 }}
                                            onClick={addMoreField}
                                            disabled={userType === "Recruiter"}
                                        />
                                    </div>
                                </div>
                            </>
                        </div>}
                </DialogContent>
                <div className={userType === "Recruiter" ? 'assignedRecruiterContainer' : ''} >
                    <div className={userType === "Recruiter" ? "alignment_ActionButton_recruiter" : 'alignment_ActionButton_recruitmentLeader'}>

                        {userType === "Recruiter" || userType === "Organization Admin" ? "" :
                            <Button
                                variant="contained"
                                className="saveButton_viewRequisition"
                                onClick={(e) => addAssignRequisition()}
                            >
                                Save
                            </Button>}
                        <Button
                            variant='outlined'
                            className="cancelButton_viewRequisition"
                            onClick={(e) => closeModal()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>

            {/* Clone Requisition Modal */}
            <Dialog
                onClose={closeCloneRequisitionModal}
                open={cloneRequisitionModal}
                aria-labelledby="customized-dialog-title"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogContent >
                    <div className="fs-semi-bold f-color fs-18">Do you want to clone this requisition?</div>
                </DialogContent>
                <DialogActions>
                    <div className='button_Container_CreateRequisition cloneRequisitionBtnContainer'>
                        <Button variant="contained" color="primary" className="cloneRequisition_createRequisition_dialogBtn" onClick={cloneRequisitionHandler}
                        >
                            Confirm
                        </Button>
                        <Button variant="contained" color="secondary" className='ButtonCancel_createRequisition' onClick={closeCloneRequisitionModal}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            {/* Resume modal */}
            <Dialog
                onClose={!progressOpen ? closeEditModal : ''}
                aria-labelledby="customized-dialog-title"
                open={showResumeModal}
            >
                <div className="upload_resume_dialog_modal"
                >
                    <div className='ats-UploadResumeModal-TitleContainer'>
                        <div>Upload Resume</div>
                    </div>

                </div>
                <DialogContent className='choose_file_dialog_content'>
                    <>
                        <div className="choose_file_dialog">
                            <button className="choose_file_btn">Choose Files</button>
                            <input type='file' name='myFiles' onChange={handleFileChange} multiple />
                        </div>
                        <div className='caution_text'>
                            <h4>PDF or doc</h4>
                        </div>

                        <div className='grid-container'>
                            {selectedFiles.map((file, index) => (
                                <div className='grid-item' key={index}>
                                    <div className='file-info'>
                                        <span className='file-icon'>
                                            {getFileIcon(file.name)}
                                        </span>
                                    </div>
                                    <div className='file-desc'>
                                        {file.name}
                                    </div>
                                    <IconButton className='close-icon' onClick={() => removeSelectedFile(index)}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                        {errorMessage.visible && <Alert severity="error">{errorMessage.text}</Alert>}
                    </>
                </DialogContent>
                {!progressOpen ? <>
                    <Grid item xs={12} md={12} className="user_pop_button rd_UploadResumeActionsContainer">
                        <DialogActions className='action_buttons'>
                            <div className='source_div'>
                                <Typography className='resume_source'> Source*</Typography>
                                <TextField id="standard-basic" variant="filled" disabled={selectedFiles.length === 0} onChange={(e) => { setsourceResume(e.target.value) }} />
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                className='upload_button'
                                onClick={(e) => { uploadResumeToBlobStorage(selectedFiles) }}
                            >
                                Upload
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className='cancel_button'
                                onClick={closeEditModal}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </>
                    : ""}
            </Dialog>
            <Backdrop className={classes.backdrop} open={backDropOpen}>
                <CircularProgress color="inherit" className="circularprogress_mytalent" />
                <Typography className={classes.text} variant="h6"> Uploading Resume</Typography>
            </Backdrop>


            <NotificationContainer />
        </div >
    )
}

export default RequisitionDetailsBody