import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "../basicInfoForm/images/filterIcon.png";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import "./requisitioncard.css";

import getRequisitionsDetails from "../../apis/requisitionDetails/getRequisitionsDetails";
import { DateTime } from "luxon";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import getAllRecruiterRequisitions from "../../apis/requisitionDetails/getAllRecruiterRequisitions";
import getRecruiterLeaderRequisitionsDetails from "../../apis/requisitionDetails/getRecruiterLeaderRequisitionsDetails";
import config from "../../Config.json";
import searchRequisition from "../../apis/requisitionDetails/searchRequisition";
import { useDebounce } from "../recruitmentLeader/hook";

const RequisitionListCard = (props) => {
  const {
    status,
    selectedFilter,
    filterChange,
    requisitionStatus,
    isSearchRequisition,
    userType,
    idContextTest,
    userRecruitmentpartnerId,
    searchpayload,
    isSearching,
    userRole,
    userRecords,
    setSearchComplete
  } = props;
  const limit = isSearchRequisition ? 10 : 5;
  const [reqCard, setRecCard] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoadingInsideScroll, setIsDataLoadingInsideScroll] = useState(false);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText, 700);


  const history = useHistory();

  const goToRequisitionDetails = (item) => {
    history.push({
      pathname: `/requisition-details/${item.requisitionId}`,
      state: { id: idContextTest, userRecruitmentpartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
    });
  };


  function convertDate(str) {
    if (str === "") {
      return "";
    }
    else {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
  }


  const API = async (paramStr) => {
    try {

      if (isSearchRequisition) {
        let responseObj = getSearchRequisitionResult(paramStr);
        return responseObj;
      }
      else {

        let response;

        if (userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) {
          response = await getAllRecruiterRequisitions(
            paramStr === "initial" ? 0 : reqCard.length,
            limit,
            status === ""
              ? requisitionStatus?.filter((item, i) => item.statusCode === config?.requisitionStatusCodes?.OPEN)[0]
                ?._id
              : status,
            selectedFilter,
            idContextTest,
            searchText
          );
        } else if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
          let isOrgAdmin = false;
          response = await getRequisitionsDetails(
            paramStr === "initial" ? 0 : reqCard.length,
            limit,
            status === ""
              ? requisitionStatus?.filter((item, i) => item.name === "OPEN")[0]
                ?._id
              : status,
            selectedFilter,
            idContextTest,
            isOrgAdmin,
            searchText
          );
        } else if (userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")) {
          response = await getRecruiterLeaderRequisitionsDetails(
            paramStr === "initial" ? 0 : reqCard.length,
            limit,
            status === ""
              ? requisitionStatus?.filter((item, i) => item.name === "OPEN")[0]
                ?._id
              : status,
            selectedFilter,
            idContextTest,
            searchText
          );
        }
        else if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
          let isOrgAdmin = true;
          response = await getRequisitionsDetails(
            paramStr === "initial" ? 0 : reqCard.length,
            limit,
            status === ""
              ? requisitionStatus?.filter((item, i) => item.name === "OPEN")[0]
                ?._id
              : status,
            selectedFilter,
            userRecruitmentpartnerId,
            isOrgAdmin,
            searchText
          );
        }
        let responseObj = {};
        responseObj.data = response.data.data.requisitions
        responseObj.totalCount = response.data.data.totalCount

        return responseObj;
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getSearchRequisitionResult = async (paramStr) => {
    try {

      let searchpayload_init = searchpayload;

      let payload = searchpayload_init;
      if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
        payload.organizationId = userRecruitmentpartnerId;
        payload.role = "Organization Admin"
      }
      else if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
        payload.organizationId = userRecruitmentpartnerId;
        payload.role = userType === "Admin" ? userRole : userType
      }
      else if (userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")) {
        payload.recruitmentLeaderId = idContextTest;
        payload.role = userType === "Admin" ? userRole : userType
      }

      else if (userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) {
        payload.recruiterId = idContextTest;
        payload.role = userType === "Admin" ? userRole : userType
      }

      payload.createdOn_from = payload.createdOn_from ? convertDate(payload.createdOn_from) : "";
      payload.createdOn_to = payload.createdOn_to ? convertDate(payload.createdOn_to) : "";
      payload.updatedOn_from = payload.updatedOn_from ? convertDate(payload.updatedOn_from) : "";
      payload.updatedOn_to = payload.updatedOn_to ? convertDate(payload.updatedOn_to) : "";

      let response = await searchRequisition(paramStr === "initial" ? 0 : reqCard.length,
        limit,
        payload);
      let responseObj = {};

      responseObj.data = response.data.data.requisitions
      responseObj.totalCount = response.data.data.totalCount

      setSearchComplete(true)

      return responseObj;

    } catch (error) {
      console.log(error, "error");
    }
  }


  const formatDate = (dateString) => {
    // Parse the date string using Luxon
    const dateTime = DateTime.fromISO(dateString);

    // Format the date using Luxon's format method
    const formattedDate = dateTime.toFormat('MMMM dd, yyyy');

    return formattedDate;
  };

  const filterChangeToCover = (str) => {
    let subStatusArr = requisitionStatus?.filter(
      (item, i) => item.statusCode === config?.requisitionStatusCodes?.OPEN
    )[0]?.subList;
    let code = str
      ? subStatusArr.filter((item, i) => item.subName === str)[0]?._id
      : "";
    filterChange("covered", code);
  };

  const getRequisitionStatusName = (statusCode) => {
    return requisitionStatus?.filter((item, i) => item._id === statusCode)[0]
      ?.name;
  };

  const searchTextChangeHandler = (event) => {
    setSearchText(event.target.value);
  }

  const getScrollData = async () => {
    try {
      setIsDataLoadingInsideScroll(true);
      let responseObj = await API("scroll");
      setRecCard((prev) => [...prev, ...responseObj?.data]);
      setTotalCount(responseObj?.totalCount);
      setIsDataLoadingInsideScroll(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchData = async () => {
      try {
        setIsDataLoading(true)
        let responseObj = await API("initial");


        if (isMounted) {
          setRecCard(responseObj?.data)
          setTotalCount(responseObj?.totalCount);
          setIsDataLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (requisitionStatus.length !== 0)
      fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [requisitionStatus, status, selectedFilter, isSearching]);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchData = async () => {
      try {
        setIsDataLoading(true)
        let responseObj = await API("initial");


        if (isMounted) {
          setRecCard(responseObj?.data)
          setTotalCount(responseObj?.totalCount);
          setIsDataLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [debouncedSearchText]);

  return (
    <div
      className={
        isSearchRequisition
          ? "secondCard_searchRequisition cardBlock"
          : "secondCard cardBlock"
      }
    >
      {/* search header and filters */}
      {isSearchRequisition ? (
        " "
      ) : (
        <div className="secCard_header">
          <span className="sec_req_cards">
            {((userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) && status === "ALL" && selectedFilter?.priority === "High") ? "High Priority" :
              !status && !selectedFilter?.priority
                ? "Open"
                : status === "ALL"
                  ? "All"
                  : requisitionStatus
                    ?.filter((item, i) => item._id === status)[0]
                    ?.name?.charAt(0)
                    .toUpperCase() +
                  requisitionStatus
                    ?.filter((item, i) => item._id === status)[0]
                    ?.name?.slice(1)
                    .toLowerCase()
            }{" "}
            Requisitions
          </span>

          <OutlinedInput
            className="searchbar_requisition"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => searchTextChangeHandler(e)}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon className="searchIcon_searchBar" />
              </InputAdornment>
            }
          />
        </div>
      )}
      {/* Filter options */}
      {isSearchRequisition ? (
        " "
      ) :
        (userType != "MIS Operator" &&

          <div className="filterNav">
            <div className="secCard_header_components">
              <button
                className={
                  selectedFilter.covered
                    ? "filterBtnStyle_selected"
                    : "filterBtnStyle"
                }
                onClick={(e) =>
                  selectedFilter.covered
                    ? filterChangeToCover("")
                    : filterChangeToCover("COVERED")
                }
              >
                <span className="filterTexts">Covered</span>
                <img src={FilterIcon} className="filterIcon" />
              </button>
            </div>
            <div className="secCard_header_components">
              <button
                className={
                  selectedFilter?.priority
                    ? "filterBtnStyle_selected"
                    : "filterBtnStyle"
                }
                onClick={(e) =>
                  selectedFilter?.priority
                    ? filterChange("priority", "")
                    : filterChange("priority", "High")
                }
              >
                <span className="filterTexts">High Priority</span>
                <img src={FilterIcon} className="filterIcon" />
              </button>
            </div>
            <div className="secCard_header_components">
              <button
                className={
                  selectedFilter.latestDate
                    ? "filterBtnStyle_selected"
                    : "filterBtnStyle"
                }
                onClick={(e) =>
                  selectedFilter.latestDate
                    ? filterChange("latestDate", false)
                    : filterChange("latestDate", true)
                }
              >
                <span className="filterTexts">Latest Date</span>
                <img src={FilterIcon} className="filterIcon" />
              </button>
            </div>
          </div>
          // }
        )
      }
      {/* requisition cards */}
      <div
        className={
          isSearchRequisition
            ? "sec_card_content_searchRequisition"
            : "sec_card_content"
        }
      >
        {isDataLoading ? (
          <div
            className={
              isSearchRequisition
                ? "sec_card_req_container_searchRequisition"
                : "sec_card_req_container"
            }
          >
            {[1, 2, 3, 4, 5].map((item, i) => {
              return (
                <Skeleton
                  variant="rect"
                  width="98%"
                  height={120}
                  animation="wave"
                  className="skeletonStyle"
                  key={'skeleton' + i}
                />
              );
            })}
          </div>
        ) : reqCard.length > 0 ? (
          <div
            className={
              isSearchRequisition
                ? "sec_card_req_container_searchRequisition"
                : "sec_card_req_container"
            }
            onScroll={(e) => {
              const bottomThreshold = 50;
              const { scrollTop, clientHeight, scrollHeight } = e.target;

              if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                if (totalCount > reqCard.length && !isDataLoadingInsideScroll)
                  getScrollData();
              }
            }}
          >
            {reqCard.map((item, i) => {

              let itemValue;
              isSearchRequisition ? itemValue =
                userType === "MIS Operator" || userType === "Organization Admin" || userType === "Recruitment Leader"
                  || ((userType === "Admin" && userRole === "MIS Operator") || (userType === "Admin" && userRole === "Organization Admin") || (userType === "Admin" && userRole === "Recruitment Leader"))
                  ? item : item?.requisitionDetails
                : itemValue = userType === "MIS Operator" || userType === "Organization Admin"
                  || ((userType === "Admin" && userRole === "MIS Operator") || (userType === "Admin" && userRole === "Organization Admin"))
                  ? item : item?.requisitionDetails;
              return (
                <div key={`reqCard+${i}`}>
                  <div
                    className={`req_card ${itemValue?.priority.toLowerCase() === "low"
                      ? "greenCard"
                      : itemValue?.priority.toLowerCase() === "high"
                        ? "redCard"
                        : "blueCard"
                      }`}
                    onClick={(e) => goToRequisitionDetails(itemValue)}
                  >
                    <div className="reqCardContainer">
                      <div className="card_left_text">
                        <div className="secCard_label">Reference ID :</div>{" "}
                        <div className="valuesFields">
                          {itemValue?.reqreferenceId}
                        </div>
                      </div>
                      {(isSearchRequisition && (userType === "Organization Admin" || userType === "MIS Operator" || (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))))
                        || (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin"))
                        ?
                        <div className="card_left_text">
                          <div className="secCard_label">Created By :</div>{" "}
                          <div className="valuesFields">
                            {itemValue?.misOperatorData?.email}
                          </div>
                        </div>
                        : null}
                      <div className="card_left_text">
                        <div className="secCard_label">Status :</div>{" "}
                        <div className="valuesFields">
                          {getRequisitionStatusName(
                            itemValue?.status.statusCodeId
                          )}
                        </div>
                      </div>
                      <div className="card_left_text">
                        <div className="secCard_label">Client Name :</div>{" "}
                        <div className="valuesFields">
                          {itemValue?.clientDetails?.clientName}
                        </div>
                      </div>
                      <div className="card_left_text">
                        <div className="secCard_label">Date :</div>{" "}
                        <div className="valuesFields">
                          {formatDate(itemValue?.created_at)}
                        </div>
                      </div>
                    </div>
                    <div className="card_right_part">
                      <div
                        className={`priorityDiv ${itemValue?.priority.toLowerCase() === "low"
                          ? "greenPriorityDiv"
                          : itemValue?.priority.toLowerCase() === "high"
                            ? "redPriorityDiv"
                            : "bluePriorityDiv"
                          }`}
                      >
                        Prority{" "}
                        {itemValue?.priority.toLowerCase() === "low"
                          ? "Low"
                          : itemValue?.priority.toLowerCase() === "high"
                            ? "High"
                            : "Medium"}
                      </div>
                      {userType === "MIS Operator" || userType === "Organization Admin" ||
                        ((userType === "Admin" && userRole === "MIS Operator") || (userType === "Admin" && userRole === "Organization Admin"))
                        ?
                        <div className="personName">
                          <div className="contact_label">Contact :</div>
                          <div className="valuesFields">
                            &nbsp;{itemValue?.recruitmentLeaderData?.displayName}
                          </div>
                        </div>
                        : ""
                      }

                    </div>
                  </div>

                </div>
              );
            })}
            {isDataLoadingInsideScroll
              ? [1, 2, 3, 4, 5].map((item, i) => {
                return (
                  <Skeleton
                    variant="rect"
                    width="98%"
                    height={120}
                    animation="wave"
                    className="skeletonStyle"
                    key={'skeleton_loading' + i}
                  />
                );
              })
              : ""}
          </div>
        ) : (
          <div className="noDataFound">No Data Found !!</div>
        )}
      </div>
    </div>
  );
};

export default RequisitionListCard;
