import axios from "axios";
const jsonConfig = require("../../Config.json");


const findCandidatePreviousRequisitionsForClient = async (clientId, email) => {
    try {
        const resp = await axios.get(jsonConfig.apiUrl + `findCandidatePreviousRequisitionsForClient/` + clientId + '/' + email, {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(resp);
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default findCandidatePreviousRequisitionsForClient;