import TrackCandidateBody from './TrackCandidateBody'
import Header from '../../components/headerAdmin/Header'
import Footer from '../../components/adminFooter/Footer'
import BasicHeader from '../../components/basicHeader/BasicHeader'
import React, { useState, useContext } from "react";
import { UserTypeContext } from '../../AppRoute';

function TrackCandidate() {
  const { userType } = useContext(UserTypeContext);
  return (
    <>
      {((userType === "Recruiter") || (userType === "Admin") || (userType === "Recruitment Leader")) ?
        <>
          <div
            style={{ backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "100vh" }}
          >
            <div style={{
              marginTop: '-10px'
            }}>
              <Header />

              <TrackCandidateBody />
            </div>
            <Footer />
          </div>
        </>
        :
        <>
          <div className='Unauthorized_container'>
            <BasicHeader />
            <div className="row padding_80 text-center">
              <div className='UnAuthorized_contain'>
                <p className="fs-semi-bold fs-40 f-color authorize">
                  You Are Not Authorized To Visit This Page
                </p>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default TrackCandidate