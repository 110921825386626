import React, { useState, useEffect } from "react";
import Footer from "../../components/adminFooter/Footer";
import "./landing.css";
import axios from "axios";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/headerAdmin/Header.js";
import profileResponse from "../../apis/profile/profileById";
import getUsersById from "../../apis/users/getUserById";
import createProfile from "../../apis/profile/createProfile";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {},
  },
}));

const jsonConfig = require("../../Config.json");

const Landing = ({ idContextTest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isRecruiter, setisRecruiter] = useState(false);

  const getProfileDataOrRedirect = async () => {
    try {
      const userProfileResponse = await profileResponse(idContextTest);
      let profileData = userProfileResponse.data.data[0];
      if (
        profileData.user &&
        !!profileData.user.firstName &&
        !!profileData.user.lastName &&
        !!profileData.user.email
      ) {
        history.push("/profile");
      } else {
        history.push("/basic-info-form/1");
      }
    } catch (err) {
      //if profile does not exist, redirect to welcome page
      const payLoad = {
        id: idContextTest,
        b2cId: idContextTest,
        createdBy: idContextTest,
        updatedBy: idContextTest,
      };

      const profileData = await createProfile(payLoad);
      history.push("/basic-info-form/1");
    }
  };

  const getUserDataOrRedirect = async () => {
    try {
      const userDataResponse = await getUsersById(idContextTest);

      let roleName = userDataResponse.data.data[0].role?.roleName;
      localStorage.setItem(
        "role",
        roleName
      );

      if (roleName === "Recruiter" ||
        roleName === "Recruitment Leader" ||
        roleName === "Organization Admin") {
        return history.push("/dashboard");
      }
      else if (roleName === "Admin") {
        return history.push("/admin-home");
      }

      else if (roleName === "MIS Operator") {
        return history.push("/requisition-dashboard");
      }

      else {
        await getProfileDataOrRedirect();
      }

    } catch (err) {
      console.error(err);
      //user not found
      localStorage.removeItem("userID");
      localStorage.removeItem("role");
      window.open("/", "_self");
    }
  };

  useEffect(() => {
    getUserDataOrRedirect();
  }, [idContextTest]);
  return (
    <>
      <div>
        <Header />
        <div className="section-wrapper">
          <div className="container w1004 flex-to-footer">
            <div className="row circular-progress-center text-center">
              <CircularProgress />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Landing;
