import React, { useEffect, useState } from 'react'
import Header from '../../components/headerAdmin/Header'
import Footer from '../../components/adminFooter/Footer'
import RecLeadBody from './RecLeadBody'
import BasicHeader from '../../components/basicHeader/BasicHeader';


function Dashboard(props) {

    const [idContextTest, setIdContextTest] = useState()
    const [userRole, setUserRole] = useState()
    const [recruitmentPartnerId, setRecruitmentPartnerId] = useState()
    const [userRecords, setUserRecords] = useState()
    const [userType, setUserType] = useState()


    useEffect(() => {
        setIdContextTest(props?.location?.state?.id ? props?.location?.state?.id : props?.id)
        setUserRole(props?.location?.state?.roleName ? props?.location?.state?.roleName : props?.userType)
        setRecruitmentPartnerId(props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId)
        setUserRecords(props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props.userRecords)
        setUserType(props?.userType)
    }, [props])

    return (
        <div
            style={{ backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "100vh" }}
        >

            {((userType === "Recruiter") || (userType === "Admin") || (userType === "Recruitment Leader") || (userType === "Organization Admin")) ? (
                <div style={{
                    marginTop: '-10px'
                }}>
                    <Header />
                    <RecLeadBody id={idContextTest} userRole={userRole} userRecruitmentpartnerId={recruitmentPartnerId} userRecords={userRecords} userType={userType} />
                </div>
            ) : <>
                <div className='Unauthorized_container'>
                    <BasicHeader />
                    <div className="row padding_80 text-center">
                        <div className='UnAuthorized_contain'>
                            <p className="fs-semi-bold fs-40 f-color authorize">
                                You Are Not Authorized To Visit This Page
                            </p>
                        </div>
                    </div>
                    <div className='mytalentBottomContainer'>
                        <Footer />
                    </div>
                </div>
            </>
            }

            {/* <Footer /> */}
        </div>
    )
}

export default Dashboard