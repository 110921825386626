import React from 'react'
import './AtsDashNav.css';
import { List } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { CloudUpload, Search } from '@material-ui/icons';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useHistory } from 'react-router-dom';
import BarChartIcon from '@material-ui/icons/BarChart';
import { makeStyles } from "@material-ui/core/styles";
import "../../pages/myTalentCommunity/myTalentCommunity.css";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';






function AtsDashNav(props) {
    const { idContextTest, orgReferral, recruitementId, recruiterAgencyType, userRole, userType, userRecords } = props
    const { setShowResumeModal } = props; // Add this line to destructure setShowResumeModal from props

    const history = useHistory();


    const navigateTo = (pageName) => {
        history.push({
            pathname: `/${pageName}`,
            state: { id: idContextTest, recruitmentPartnerId: recruitementId, userType: userType, userRecords: userRecords, userRole: userRole },
        });
    }

    const goToRequisitionReports = () => {
        history.push("/requisition-reports", { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: recruitementId });
    }

    const handleUploadResumes = () => {
        setShowResumeModal(true);
    }

    const gotoPendingInvites = () => {

        history.push({
            pathname: "/pending-invites",
            state: { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: recruitementId },
        });
    }


    return (
        <div className='ats-dash-nav-container'>
            <div className='ats-dash-navTitle' >{userType === 'Admin' ? userRole : userType}'s Dashboard</div>
            &nbsp;
            <List>
                <div className='ats-dash-navItem ats-dash-navItem-selected' onClick={() => navigateTo('Dashboard')}><MenuOpenIcon /> Feed</div>
                <div className='ats-dash-navItem ' onClick={() => navigateTo('candidate-search-talent-community')}><Search /> Candidate Search</div>
                {userType === 'Admin' || userType === 'Organization Admin' ? `` :
                    <div className='ats-dash-navItem ' onClick={(e) => handleUploadResumes()}><CloudUpload /> Upload Resume</div>
                }
                {userType === 'Admin' || userType === 'Organization Admin' ? `` :
                    <div className='ats-dash-navItem ' onClick={() => navigateTo('invite-candidates')}><MarkunreadMailboxIcon /> Invite Candidate To Take Test</div>
                }
                <div className='ats-dash-navItem' onClick={() => gotoPendingInvites()}><HourglassEmptyIcon /> Pending Invites</div>
                {
                    (userType === 'Admin') ? "" :
                        (orgReferral) ?
                            ((recruiterAgencyType !== "internal") ?
                                <></>
                                :
                                <div className='ats-dash-navItem ' onClick={() => navigateTo('mytalent-referral')} ><RecordVoiceOverIcon /> Pending Referral</div>

                            ) :
                            <></>
                }
                <div className='ats-dash-navItem ' onClick={() => navigateTo('pending-resume-validations')}><AssignmentTurnedInIcon /> Pending Resume Validation</div>

                <div className='ats-dash-navItem ' onClick={() => navigateTo('requisition-dashboard')}><DescriptionIcon /> Requisitions</div>

                {userType === "Organization Admin" || (userType === 'Admin' && userRole === 'Organization Admin') ?
                    <>
                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageClient')}><RecentActorsIcon /> Manage Client</div>
                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageSpoc')}><PeopleIcon /> Manage POC</div>
                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageDivision')}><AccountTreeIcon /> Manage Division</div>
                    </>
                    : ""
                }
                {userType === "Organization Admin" || userType === 'Recruitment Leader' || (userType === 'Admin' && (userRole === 'Organization Admin' || userRole === 'Recruitment Leader')) ?
                    <div className='ats-dash-navItem ' onClick={() => goToRequisitionReports()} ><BarChartIcon /> Reports</div>

                    : ""
                }


            </List>


        </div>
    )
}

export default AtsDashNav