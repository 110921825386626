import React from "react";
import "./actionCard_RequisitionDashboard.css";
import AddIcon from "../../components/basicInfoForm/images/add.png";
import ListIcon from "../../components/basicInfoForm/images/list.png";
import ReportsIcon from "../../components/basicInfoForm/images/report.png";
import { useHistory } from "react-router";
import TimelineIcon from '@material-ui/icons/Timeline';


function ActionCard_RequisitionDashboard(props) {

  const { userType, userRole, idContextTest, userRecords, userRecruitmentpartnerId } = props;

  const history = useHistory();

  const goToAddRequisition = () => {
    history.push("/create-requisition");
  }

  const goToRequisitionReports = () => {
    history.push("/requisition-reports", { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId });
  }

  const goToSearchRequisitions = () => {
    history.push("/search-requisitions", { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId });
  }


  const goToCandidateHistory = () => {
    history.push("/candidate-history");
  }

  return (
    <div className="firstCard cardBlock">
      <div className="f_card_heading">
        <div className="colorBox"></div>
        <div className="actionsCardHeading">Actions</div>
      </div>
      {/* icons */}
      <div className="f_card_content">
        {userType === "MIS Operator" ?
          <div className="f_card_content_inner_div" onClick={(e) => goToAddRequisition()}>
            <div>
              <img src={AddIcon} alt="addIcon" className="iconStyle" />
            </div>
            <div className="iconLegendStyle">Add Requisition</div>
          </div>
          : null}

        <div className="f_card_content_inner_div" onClick={(e) => goToSearchRequisitions()}>
          <div>
            <img src={ListIcon} alt="ListIcon" className="iconStyle" />
          </div>
          <div className="iconLegendStyle">Search Requisition</div>
        </div>
        
        {userType === "Recruitment Leader" || userType === "Recruiter" ?
          <div className="f_card_content_inner_div" onClick={(e) => goToCandidateHistory()}>
            <div>
              <TimelineIcon className="candidate-history-icon" />
            </div>
            <div className="iconLegendStyle">Candidate History</div>
          </div>
          : null}

        {userType === "MIS Operator" || (userType === 'Admin' && userRole === 'MIS Operator') ?
          <div className="f_card_content_inner_div" onClick={(e) => goToRequisitionReports()}>
            <div>
              <img src={ReportsIcon} alt="ReportsIcon" className="iconStyle" />
            </div>
            <div className="iconLegendStyle">Reports</div>
          </div>
          : null}

      </div>
    </div>
  );
}

export default ActionCard_RequisitionDashboard;
