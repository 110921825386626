import axios from "axios";
const jsonConfig = require("../../Config.json");

const candidateResumeById = async (Id) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.get(
            jsonConfig.apiUrl + "candidateResume/"+Id,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;
        return Promise.resolve(allResponse)
    }
    catch (error) {
      console.log("Error", error);
        return Promise.reject(error)
    }
}

export default candidateResumeById;