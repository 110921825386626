import axios from "axios";
const jsonConfig = require("../../Config.json");

const getRequisitionsDetails = async(offset, limit, statusCode, selectedFilter, id,isOrgAdmin,searchText) => {
    try
    {
        const payload = {
            statusCode : statusCode === "ALL" ? "" : statusCode,
            filters : selectedFilter,
            userId: id,
            isOrgAdmin: isOrgAdmin,
            searchText: searchText
        }
        const response = await axios.post(
            `${jsonConfig.apiUrl}getAllRequisitions?offset=${offset}&limit=${limit}`, payload,
            {
                headers: {
                  "x-functions-key": jsonConfig.key,
                },
            }
        );
        return Promise.resolve(response);
    }
    catch(error)
    {
        return Promise.reject(error);
    }
}

export default getRequisitionsDetails