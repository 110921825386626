import axios from "axios";
const jsonConfig = require("../../Config.json");

function convertDate(str) {
  if (str === "") {
      return "";
  }
  else {
      let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
  }
}

const getAllRequitionForCandidates = async (id, page, size, status, searchTerms, userType, requi) => {

  try {
    const searchData = {}
    if (userType == "MIS Operator") {
      var payload_data = {
        "MISId": id,
        "candidateStatusCode": status,
        "requisitionId": requi,
        "searchData": searchData,
      };
    }

    if (userType == "Recruiter") {
      var payload_data = {
        "recruiterId": id,
        "candidateStatusCode": status,
        "requisitionId": requi,
        "searchData": searchData,
      };
    }

    if (userType == "Recruitment Leader") {
      var payload_data = {
        "recruitmentLeaderId": id,
        "candidateStatusCode": status,
        "requisitionId": requi,
        "searchData": searchData,
      };
    }
    if (userType == "Organization Admin") {
      var payload_data = {
        "orgId": id,
        "candidateStatusCode": status,
        "requisitionId": requi,
        "searchData": searchData,
      };
    }

    for (let i = 0; i < searchTerms.length; i++) {
      let { field, value, } = searchTerms[i];

      let searchField = field;

      if (field == "name")
        field = "candidateName";
      if (field == "eventDate")
        field = "date";
      payload_data.searchData[field] = searchField === "eventDate" ? convertDate(value) : value;
    }


    const getAllResponse = await axios.post(
      `${jsonConfig.apiUrl}getCandidateActivity?page=${page}&size=${size}`, payload_data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getAllRequitionForCandidates;

