import React, { useEffect, useState } from 'react'
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import './manageDivisionTable.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';

const ManageDivisionTable = (props) => {

    const { divisionData, isDataLoaded, onEdit,userType } = props;
    const [allDivisionData, setAllDivisionData] = useState([]);
    const [result, setResult] = useState([]);

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const dataStateChange = (event) => {
        setResult(process(allDivisionData, event.dataState));
        setDataState(event.dataState);
    };

    useEffect(() => {
        let modifiedData = [];

        if (divisionData.length > 0) {

            let obj = {};
            for (let i = 0; i < divisionData.length; i++) {
                obj = {
                    ...divisionData[i],
                    recruiterleader_email: divisionData[i].recruiterLeader.length > 0 ? divisionData[i].recruiterLeader[0].email : ""
                }

                modifiedData.push(obj);

            }
        }

        setAllDivisionData(modifiedData);
        setResult(process(modifiedData, dataState));
    }, [divisionData]);


    const handleEditClick = (rowData) => {
        onEdit(rowData);
    };

    return (
        <>
            <div className='tableGrid'>
                {!isDataLoaded ?
                    <div className="row padding_80 text-center">
                        <CircularProgress />
                    </div>
                    :
                    <GridDiv
                        filterable={true}
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        resizable={true}
                        reorderable={true}
                        pageable={{
                            pageSizes: [10, 20, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10
                        }}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={result}
                        onDataStateChange={(e) => dataStateChange(e)}
                    >
                        <Column
                            field="divisionName"
                            title="Division"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="recruiterleader_email"
                            title="Recruitment Leader"
                            width="180px"
                            filterable={true}

                        />
                        <Column
                            field='teamSize'
                            title="Team Size"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="isActive"
                            title="Is Active"
                            width="180px"
                            filter={"boolean"}
                            cell={(props) => {
                                const active = props.dataItem.isActive ? "True" : "False";
                                return (
                                    <td>
                                        {active}
                                    </td>
                                );
                            }}
                            filterable={true}
                        />
                        <Column
                            title="Action"
                            filterable={false}
                            width="250px"
                            cell={(props) => {
                                return (

                                    <td >
                                        <div className='actionbutton'>
                                            <button
                                                className='editBtn'
                                                onClick={() => handleEditClick(props.dataItem)}
                                                disabled={userType === 'Admin' ? true : false}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </td>
                                );
                            }}
                        />
                    </GridDiv>
                }
            </div>
        </>
    )
}

export default ManageDivisionTable;