import { Backdrop, Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, Grid, Menu, MenuItem, TextField, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext, useRef } from 'react'
import Header from "../../components/headerAdmin/Header.js";
import "./myTalentReferral.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchReferralByRecruitmentPartnerId from '../../apis/myTallentCommunityByOrgId/searchReferralByRecruitmentPartnerId.js';
import { DateTime } from "luxon";
import { Autocomplete } from '@material-ui/lab';
import { Grid as GridDiv, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import getUsersById from '../../apis/users/getUserById.js';
import pdfIcon from "../myTalentCommunity/icons/pdf.svg"
import docIcon from "../myTalentCommunity/icons/doc.png";
import Footer from "../../components/adminFooter/Footer";
import getallreferralskillResponse from '../../apis/referral/getallreferralskill.js';
import getAllPendingCandidateByInvitedIdResponse from "../../apis/pendingInvites/pendingInvites";
import revokeInviteCandidateResponse from '../../apis/inviteCandidate/revokeInviteCandidate.js';
import UpdateStatusReferralById from '../../apis/myTallentCommunityByOrgId/updateStatusReferralById.js';
import { UserTypeContext } from "../../AppRoute";
import { BlobServiceClient } from '@azure/storage-blob';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BasicHeader from '../../components/basicHeader/BasicHeader.js';
import { DropDownButton } from '@progress/kendo-react-buttons';
// import CircularProgress from '@mui/joy/CircularProgress';
import countNoOfFilesParsed from "../../apis/parsing/countNoOfFilesParsed";
import { hi } from 'date-fns/locale';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: '"Segoe UI Semibold", sans-serif !important',
        fontWeight: 'bold',
        fontSize: "10px",
        color: "#ffffff !important",
        textShadow: "0px 1px 2px black !important"
    },
    sidebar_top_check_section: {},
    sidebar_top_check_section1: {},
    buttonContainer: {
        justifyContent: "flex-end !important",
        paddingRight: "40px",
        paddingBottom: "20px",
        paddingTop: "14px",
    },
    btnNO: {
        width: "25px",
        height: "37px",
        backgroundColor: "#FFFFFF",
        color: "#1885E6",
        border: "1px solid #1885E6",
        borderRadius: "5px",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#D1E8FF"
        }
    },

    btnYES: {
        width: "25px",
        height: "37px",
        backgroundColor: "#1885E6",
        color: "#FFFFFF",
        border: "none",
        borderRadius: "5px",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#1885E6",
        }
    },
    inviteActionBtn: {
        color: "#fff",
        backgroundColor: "#1885e6",
    },
    // backdrop: {
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     backgroundColor: 'rgba(0, 0, 0, 0.7)',
    //     zIndex: 9999,
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    // },
    tyreWrapper: {
        position: 'relative',
        width: '80vw',
        height: '80vw',
        maxWidth: '80vh',
        maxHeight: '80vh',
        // backgroundColor: 'black',
        borderRadius: '50%',
        padding: '20px',
        boxSizing: 'border-box',
    },
}))

const MyTalentReferral = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [filterSkill, setFilterSkill] = useState([]);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterStatus, setFilterStatus] = useState([{ name: "Pending Action" }]);
    const [requirementPartnerId, setRequirementPartnerId] = useState();
    const [company, setCompany] = useState("");
    const [referralJson, setReferralJson] = useState([]);
    const [openMenu, setOpenMenu] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [referralSkills, setReferralSkills] = useState([]);
    const { id } = props;
    const location = useLocation();
    const idContextTest = id ? id : location.state ? location.state.id : "";
    const locationId = location.state ? location.state.id : "";
    const [cancelCandidateDetails, setCancelCandidateDetails] = useState({});
    const [pendingData, setPendingData] = useState([]);
    const [revokeCandidateDetails, setRevokeCandidateDetails] = useState({});
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [allPendingCandidateByInvitedIdArr, setAllPendingCandidateByInvitedIdArr,] = useState([]);
    const [recruiterData, setRecruiterData] = useState([]);
    const { userType, userIdType } = useContext(UserTypeContext);
    const jsonConfig = require("../../Config.json");
    const crypto = require('crypto'); //crypto module for hashing
    const [openCandidateProfileModel, setOpenCandidateProfileModel] = useState(false);
    const [resumeDetail, setResumeDetail] = useState({ "referralId": "", "resumeUrl": "", organizationId: "" });
    const [buttonItem, setButtonItem] = useState([]);
    const [progress, setProgress] = useState(0);
    const [progressLoader, setProgressLoader] = useState(false);
    const uploadedFileParseCount = useRef(0);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "firstName",
                    operator: "startswith",
                    value: "",
                },
                {
                    field: "lastName",
                    operator: "startswith",
                    value: "",
                },
                {
                    field: "test.testName",
                    operator: "startswith",
                    value: "",
                },
                {
                    field: "test.skill.name",
                    operator: "startswith",
                    value: "",
                },

                {
                    field: "email",
                    operator: "startswith",
                    value: "",
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });
    const [dataResult, setDataResult] = useState(process(allPendingCandidateByInvitedIdArr, dataState));
    const [openRevokeDialogue, setOpenRevokeDialogue] = useState(false);
    const [open, setOpen] = useState(false);
    const [indexrow, setIndexRow] = useState(0);

    const goToMyTallent = () => {
        userType === 'Admin' ?
            history.push({
                pathname: "/recruiter-talent-community",
                state: { id: idContextTest },
            })
            : history.push("./recruiter-dashboard");
    };

    const searchFunc = () => {
        search(recruiterData[0].recruitmentpartnerId, company);
    };

    const [referralMenuList, setReferralMenuList] = useState([
        { name: "Skill", checked: true },
        { name: "Country", checked: true },
        { name: "Status", checked: true },
    ]);
    const [menuList, setMenuList] = useState([
        { name: "Primary Skill", checked: true },
        { name: "Years of Experience", checked: true },
        { name: "Active In", checked: true },
        { name: "Locations", checked: true },
        { name: "Percentage Score", checked: true },
        { name: "Additional Skills", checked: true },
    ]);
    const [countryList, setCountryList] = useState([
        {
            currency: "USD",
            flag: "",
            isoCode: "US",
            latitude: "38.00000000",
            longitude: "-97.00000000",
            name: "United States",
            phonecode: "+1",
        },
        {
            currency: "CAD",
            flag: "",
            isoCode: "CA",
            latitude: "60.00000000",
            longitude: "-95.00000000",
            name: "Canada",
            phonecode: "+1",
        },
        {
            currency: "INR",
            flag: "",
            isoCode: "IN",
            latitude: "20.00000000",
            longitude: "77.00000000",
            name: "India",
            phonecode: "+91",
        },
        {
            currency: "MXN",
            flag: "",
            isoCode: "MX",
            latitude: "23.00000000",
            longitude: "-102.00000000",
            name: "Mexico",
            phonecode: "+52",
        }
    ]);
    const [referralStatus, setReferralStatus] = useState([
        { name: "Pending Action" },
        { name: "Invited to Test" },
        { name: "Added to Community" },
        { name: "Referral Cancelled" },
    ]);
    const [dataState2, setDataState2] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "status",
                    operator: "startswith",
                    value: "",
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });


    const [checkedItem, setCheckedItem] = useState([...menuList]);
    const search = async (recruitmentPartnerId_Search, companyName) => {
        let skillArr = [];
        let countryJson = null;
        let statusArr = [];

        if (filterSkill.length !== 0) {
            for (let i = 0; i < filterSkill.length; i++) {
                skillArr.push(filterSkill[i]?.id);
            }
        }
        if (filterCountry !== null) {
            countryJson = JSON.stringify(filterCountry);
        }
        if (filterStatus.length !== 0) {
            for (let i = 0; i < filterStatus.length; i++) {
                statusArr.push(filterStatus[i]?.name);
            }
        }
        const referralPayload = {
            recruitmentPartnerId: recruitmentPartnerId_Search,
            data: {
                skillId: skillArr.length !== 0 ? skillArr : [],
                country: countryJson !== null ? countryJson : "",
                status: statusArr.length !== 0 ? statusArr : [],
            },
        };

        try {
            const res = await SearchReferralByRecruitmentPartnerId(referralPayload);
            const allData = res?.data?.data;

            for (let i = 0; i < allData.length; i++) {
                const change = allData[i];
                // country
                change.location = JSON.parse(change?.country).name;
                // date
                change.referredOn = DateTime.fromISO(change.createdAt)
                    .toLocal()
                    .startOf("day")
                    .toJSDate();
                change.indexnumber = i;
                allData[i] = change;
                //phone number
                change.intlPhone = change.countryCode + " " + change.phoneNumber;
                //company
                change.company = companyName;


            }
            setReferralJson(allData || []);
        } catch (err) {
            console.log(err);
        }
        // }
    };

    const handleOpneMenu = (e) => {
        setOpenMenu(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleReferralFilterSelectItems = (name, checked) => {

        if (checked) {
            setCheckedItem([...checkedItem, { name, checked }]);
        } else {
            const _checkedItem = checkedItem.filter((c) => c.name !== name);
            setCheckedItem(_checkedItem);
            (() => {
                switch (name) {
                    case referralMenuList[0]?.name:
                        return setFilterSkill([]);
                    case referralMenuList[1]?.name:
                        return setFilterCountry(null);
                    case referralMenuList[2]?.name:
                        return setFilterStatus([]);

                    default:
                        return "";
                }
            })();
        }
        const filteredStates = {};
        const newMenusList = referralMenuList.map((m) => {
            const newMenu = {
                ...m,
                checked: m.name === name ? checked : m.checked,
            };
            if (newMenu.checked) {
                filteredStates[m.name] = true;
            }
            return newMenu;
        });
        setReferralMenuList(newMenusList);
    };

    const handleSelectdIndex = (index) => {
        setSelectedIndex(index);
    };
    const RenderSkill = () => {
        return (
            <section className="primary_skills mytalentsidemutiselect mt_10">
                <div className="text_skills">
                    <span className="text-name">Skill</span>
                </div>

                <Autocomplete
                    multiple
                    options={referralSkills}
                    getOptionLabel={(option) => option.name || ""}
                    value={filterSkill}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select Skill" />
                    )}
                    onChange={handleSkill}
                />
            </section>
        );
    };

    const RenderCountry = () => {
        return (
            <section className="primary_skills mytalentsidemutiselect mt_20">
                <div className="text_skills">
                    <span className="text-name">Country</span>
                </div>

                <Autocomplete
                    options={countryList}
                    getOptionLabel={(option) => option.name || ""}
                    value={filterCountry}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select Country" />
                    )}
                    onChange={handleCountry}
                />
            </section>
        );
    };

    const RenderStatus = () => {
        return (
            <section className="primary_skills mytalentsidemutiselect mt_20">
                <div className="text_skills">
                    <span className="text-name">Status</span>
                </div>

                <Autocomplete
                    multiple
                    options={referralStatus}
                    getOptionLabel={(option) => option.name || ""}
                    value={filterStatus}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select Status" />
                    )}
                    onChange={handleStatus}
                />
            </section>
        );
    };
    const handleSkill = (event, value) => {
        setFilterSkill(value || []);
    };
    const handleCountry = (event, value) => {
        setFilterCountry(value || null);
    };
    const handleStatus = (event, value) => {
        setFilterStatus(value || []);
    };

    const getCommunityStaticsData = async () => {
        try {
            const response = await getUsersById(idContextTest);
            const data = response?.data?.data;
            setRequirementPartnerId(data[0]?.recruitmentpartnerId);
            setRecruiterData(data);
            setCompany(data[0]?.recruitmentpartner.name)
            search(data[0]?.recruitmentpartnerId, data[0]?.recruitmentpartner.name);

        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getCommunityStaticsData();
        getReferralOptions();
        getAllPendingCandidateByInvitedId();
    }, []);

    const getReferralOptions = async () => {
        const resp = await getallreferralskillResponse();
        setReferralSkills(resp?.data?.data);

    };

    const openCancelReferralModal = (value) => {
        setCancelCandidateDetails(value);
        const arr = [...pendingData];
        const data = arr.filter((item) => item.email === value.email);
        setRevokeCandidateDetails(data[0]);
        setOpenCancelModal(true);
    };

    const getAllPendingCandidateByInvitedId = async () => {

        var rId = idContextTest;
        let allPendingCandidateByInvitedId =
            await getAllPendingCandidateByInvitedIdResponse(rId);

        if (allPendingCandidateByInvitedId.data.data) {
            let arr = [];
            let data = allPendingCandidateByInvitedId.data.data;

            if (data.length > 0) {
                for (let index = 0; index < data.length; index++) {
                    //Aging
                    let date = getDate(data[index].createdAt);
                    let dateOfInvite = data[index].createdAt;
                    let currentDate = DateTime.now().toISO();
                    const date1 = DateTime.fromISO(dateOfInvite);
                    const date2 = DateTime.fromISO(currentDate);
                    const diff = date2.diff(date1, ["days"]).toObject();
                    const difference = diff.days.toString().split(".")[0].includes("-")
                        ? 0
                        : diff.days.toString().split(".")[0];
                    let expDate = new Date(data[index].expiresOn);

                    // Test Expiry Date
                    const formattedDate = DateTime.fromISO(data[index].expiresOn)
                        .toLocal()
                        .startOf("day")
                        .toJSDate();

                    arr.push(
                        Object.assign(data[index], {
                            dateTime: date,
                            aging: difference,
                            testExpiry: formattedDate,
                        })
                    );
                }

                setPendingData(arr);
                setDataResult(process(arr, dataState));

            }
        } else {
            const emptArr = [];

            setPendingData([]);
            setDataResult(process(emptArr, dataState));

        }

    };

    const getDate = (date) => {
        let fullDate = "";
        fullDate =
            new Intl.DateTimeFormat("en", { day: "2-digit" }).format(new Date(date)) +
            "-" +
            new Intl.DateTimeFormat("en", { month: "long" }).format(new Date(date)) +
            "-" +
            new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                new Date(date)
            ) +
            ", " +
            new Date(date).toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
            });

        return fullDate;
    };

    const cancelAndUpdate = async () => {
        if (cancelCandidateDetails.status === "Invited to Test") {
            var payloadData = {
                isActive: false,
            };

            try {
                let revokeInviteResponse = await revokeInviteCandidateResponse(
                    revokeCandidateDetails.id,
                    payloadData
                );
                getAllPendingCandidateByInvitedId();
                setOpenRevokeDialogue(false);
                setRevokeCandidateDetails("");
            } catch (error) {
                setOpenRevokeDialogue(false);
            }
        }

        const payload = {
            referralId: cancelCandidateDetails?.id,
            data: {
                status: "Referral Cancelled",
            },
        };

        try {
            const res = await UpdateStatusReferralById(payload);
            getReferralWholeData(recruiterData);
            NotificationManager.success("Referral cancelled", "Success", 2500);
            search(recruiterData[0].recruitmentpartnerId, company);
        } catch (err) {
            console.log(err);
            NotificationManager.error(
                "Referral could not be cancelled",
                "Error",
                2500
            );
        }
        closeCancelReferralModal();
    };



    const getReferralWholeData = async (data) => {
        const payloadDataForReferral = {
            recruitmentPartnerId: data[0]?.recruitmentpartnerId,
            data: {
                skillId: [],
                country: "",
                status: ['Pending action'],
            },
        };
        try {
            const res = await SearchReferralByRecruitmentPartnerId(
                payloadDataForReferral
            );
            const allData = res?.data?.data;
            for (let i = 0; i < allData.length; i++) {
                let change = allData[i];
                // country
                change.location = JSON.parse(allData[i]?.country).name;
                // date
                change.referredOn = DateTime.fromISO(change.createdAt)
                    .toLocal()
                    .startOf("day")
                    .toJSDate();
                //phone number
                change.intlPhone = change.countryCode + " " + change.phoneNumber;
                // company
                change.company = company;
                allData[i] = change;

            }
            setReferralJson(allData || []);
        } catch (err) {
            console.log(err);
        }

    };

    const downloadResume = async (event, url) => {
        window.open(url, "_blank");
    };

    const closeCancelReferralModal = () => {
        setOpenCancelModal(false);
        setOpen(!open)
    };
    const cancelReferral = () => {
        cancelAndUpdate();
        setOpen(!open)
    };
    function create_UUID() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt >>>= 4; // unsigned right shift to ensure a 32-bit integer
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }
    function hash_UUID(uuid) {
        const sha256 = crypto.createHash('sha256');
        sha256.update(uuid);
        return sha256.digest('hex');
    }


    const delayedFunction = async (filnameWithtimearr) => {
        //call api
        // console.log("jkkllmmm")
        const response = await countNoOfFilesParsed(filnameWithtimearr);

        if (response.status === 200) {

            uploadedFileParseCount.current = response.data.count;
        }
    }
    const openCreateProfile = async (resumeurl, referralId, organizationId) => {
        const pathAfterDomain = resumeurl.replace(/^.*\/\/[^/]+/, ''); // Extracts everything after the domain
        const sasUrl = jsonConfig.sasToken;
        const sasResumeRepositoryUrl = jsonConfig.sasResumeToken;
        const sourceCdnUrlWithSas = `https://${jsonConfig.blobStorageAccountName}.blob.core.windows.net${pathAfterDomain}${sasUrl}`; // Replace with the CDN URL including SAS for the source blob
        const targetContainerSas = `https://${jsonConfig.blobStorageResumeAccountName}.blob.core.windows.net${sasResumeRepositoryUrl}`; // Replace with the target container SAS URL
        const date = DateTime.now().toFormat("yyyy-MM-dd");
        const uuid = create_UUID();
        const hashUuid = hash_UUID(uuid);
        let fileName = resumeurl.split('/').pop();
        let v = fileName.split(".");
        const targetBlobName = date + "/" + locationId + "/" + hashUuid + "." + v[v.length - 1];
        const targetBlobServiceClient = new BlobServiceClient(targetContainerSas);
        const targetBlobContainerClient = targetBlobServiceClient.getContainerClient("staging"); // Specify the container name
        const targetBlobClient = targetBlobContainerClient.getBlockBlobClient(targetBlobName);

        try {
            // Set metadata for the target blob container
            const currentTime = DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");
            if (organizationId) {


                const metadata = {
                    "createdtime": currentTime,
                    "fullname": fileName,
                    "uploadedtype": "Recruiter Upload",
                    "isreferred": "true",
                    "referralid": referralId,
                    "organizationid": organizationId
                };

                // Copy source blob from CDN URL to target blob
                const response = await targetBlobClient.beginCopyFromURL(sourceCdnUrlWithSas, {
                    metadata: metadata,
                });
                let filnameWithtimearr = [{ "blobfilename": targetBlobName, "createdtime": currentTime, "organizationid": organizationId }];
                // NotificationManager.success("Candidate Profile has been created", "Success", 2500);

                const intervalID = setInterval(() => {
                    if (stopCondition(filnameWithtimearr)) {
                        delayedFunction(filnameWithtimearr);
                    } else {
                        clearInterval(intervalID);
                        setProgressLoader(false)

                        history.push({
                            pathname: "/pending-resume-validations",
                            state: { id: idContextTest },
                        });
                    }
                }, 5000);
                // return () => clearInterval(interval);  // Clear the interval when the component unmounts


            }
            // search(recruiterData[0].recruitmentpartnerId, company);
        } catch (error) {
            console.error("Error copying blob:", error);
        }

    }

    function stopCondition(filnameWithtimearr) {

        if (uploadedFileParseCount.current === filnameWithtimearr.length) {
            // console.log("mmmmm")
            return false
        } else {
            // console.log("nnn")
            return true
        }
    }
    const handleCandidateProfile = (resume_url, referral_id, organizationId) => {
        setResumeDetail({ "referralId": referral_id, "resumeUrl": resume_url, organizationId: organizationId })
        setOpenCandidateProfileModel(true);
    }

    const openModelCreateProfile = () => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        let newReferralJson = [...referralJson]
        newReferralJson.splice(indexrow, 1);
        setReferralJson(newReferralJson);
        setOpenCandidateProfileModel(false);
        setOpen(!open);
        setProgressLoader(true);
        openCreateProfile(resumeDetail.resumeUrl, resumeDetail.referralId, resumeDetail.organizationId);

    }

    const closeModelCreateProfile = () => {
        setOpenCandidateProfileModel(false)
        setOpen(!open);
    }

    const handleActionClick = (event, index, dataItem) => {
        setIndexRow(index);
        if (event.itemIndex === 0) {
            history.push({
                pathname: `/invite-candidates/${dataItem.email}`,
                state: {
                    referralId: dataItem.id,
                },
            });
        }
        else if (event.itemIndex === 1) {
            openCancelReferralModal(dataItem);
        }
        else if (event.itemIndex === 2) {
            handleCandidateProfile(dataItem.resumeurl, dataItem.id, dataItem.referralpartner.recruitmentpartnerId)
        }
        // else if (event.itemIndex === 2 && dataItem.candidateresumeformedbyrecruiters.candidateResumeId ){
        //     history.push({
        //         pathname: `/only-candidate-profile/${dataItem.candidateresumeformedbyrecruiters.candidateResumeId}`,
        //     });
        // }
    }

    const getDropdownItems = (dataItem) => {
        let inviteDisable = (userType === 'Admin' || dataItem.status === "Invited to Test" || dataItem.status === "Added to Community" || dataItem.status === "Referral Cancelled") ? true : false;
        let cancelDisabled = (userType === 'Admin' || dataItem.status === "Added to Community" || dataItem.status === "Referral Cancelled") ? true : false;
        // let isCandidateProfile = dataItem.candidateresumeformedbyrecruiters.candidateResumeId?true : false

        let arr = [
            {
                text: 'Invite',
                disabled: inviteDisable
            },
            {
                text: 'Cancel',
                disabled: cancelDisabled
            },
            {
                text: 'Create Profile',
            }
        ]
        return arr;
    }

    const goToDashboard = (e) => {
        history.push("/")
    }

    return (
        <>
            {(userType === "Candidate" || userType === "MIS Operator") ? (
                <>
                    <BasicHeader />
                    <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color authorize">
                            You Are Not Authorized To Visit This Page
                        </p>
                    </div>
                </>
            ) : <>
                {progressLoader && (
                    <Backdrop className={classes.backdrop} open={progressLoader}>
                        <CircularProgress color="inherit" className="circularprogress_mytalent" />
                        <Typography className={classes.text} variant="h6">Generating Profile...</Typography>
                    </Backdrop>
                )}
                <div className='referralmainContainer'>
                    <div className='referralTopContainer'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Header />
                            </Grid>
                            <Grid item xs={12} container className="referralMainGrid">
                                <div className='titlesofCommunity'>Pending Referrals</div>
                                <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashboard(e)}>
                                    <ArrowBackIcon /> Go Back to Dashboard
                                </div>
                                <div className='referralContent'>
                                    <div className='referralContent_leftSide mytalent_sidebar'>
                                        <div className='referralContent_topContainer'>
                                            <div className='referralContent_header'>
                                                <span className="header-text">
                                                    Showing results for
                                                </span>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="referralsearchButton"
                                                    onClick={searchFunc} // Add the API for search filter here
                                                >
                                                    Search
                                                </Button>
                                            </div>
                                            <div>
                                                <MoreHorizIcon
                                                    onClick={handleOpneMenu}
                                                    className="moreButton"
                                                />
                                                <Menu
                                                    open={Boolean(openMenu)}
                                                    anchorEl={openMenu}
                                                    className={classes.sidebar_top_check_section}
                                                    onClose={handleCloseMenu}
                                                >
                                                    {referralMenuList &&
                                                        referralMenuList?.map((item, index) => (
                                                            <div key={index} onClick={(e) => handleReferralFilterSelectItems(item.name, !(item.checked))} >
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleSelectdIndex(index)
                                                                    }
                                                                    className={
                                                                        classes.sidebar_top_check_section1
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <Checkbox
                                                                        className="check-icon"
                                                                        name={item?.name}
                                                                        checked={item?.checked}
                                                                        inputProps={{
                                                                            "aria-label": "primary checkbox",
                                                                        }}
                                                                    />
                                                                    {item.name}
                                                                </MenuItem>
                                                            </div>
                                                        ))}
                                                </Menu>
                                            </div>

                                        </div>
                                        <div className='referralContent_buttomContainer skills'>
                                            {referralMenuList.length > 0 &&
                                                referralMenuList.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item.checked &&
                                                                (() => {
                                                                    switch (item.name) {
                                                                        case "Skill":
                                                                            return <RenderSkill />;
                                                                        case "Country":
                                                                            return <RenderCountry />;
                                                                        case "Status":
                                                                            return <RenderStatus />;

                                                                        default:
                                                                            return "";
                                                                    }
                                                                })()}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className='referralContent_rightSide'>
                                        <GridDiv
                                            pageable={{
                                                buttonCount: 10,
                                                info: true,
                                                previousNext: true,
                                                pageSizes: true,
                                                serverPaging: true,
                                                serverFiltering: true,
                                            }}
                                            scrollable={"scrollable"}
                                            resizable={true}
                                            reorderable={true}
                                            sortable={true}
                                            filterable={true}
                                            fixedScroll={true}
                                            className="kendoStyle"
                                            data={process(referralJson, dataState2)}
                                            {...dataState2}
                                            onDataStateChange={(e) => {
                                                setDataState2(e.dataState);
                                            }}
                                        >
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="id"
                                                title="Action"
                                                width="150px"
                                                filterable={false}
                                                sortable={false}
                                                cell={(event) => {
                                                    return (
                                                        <td>
                                                            <div className="activityInviteBtnAllignment" >
                                                                <DropDownButton
                                                                    text="Action"
                                                                    popupSettings={{
                                                                        animate: false,
                                                                        popupClass: "inviteActionBtnFront",
                                                                    }}
                                                                    items={getDropdownItems(event.dataItem)}
                                                                    onItemClick={(e) => handleActionClick(e, event.dataItem.indexnumber, event.dataItem)}
                                                                    disabled={userType === "Admin" ? true : false}
                                                                >
                                                                </DropDownButton>
                                                            </div>
                                                        </td>
                                                    );
                                                }}
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="id"
                                                title="Resume"
                                                width="100px"
                                                filterable={false}
                                                sortable={false}
                                                cell={(event) => {
                                                    return (
                                                        <td>
                                                            <div
                                                                className="activityInviteBtnAllignment cp"
                                                                onClick={(e) =>
                                                                    downloadResume(
                                                                        e,
                                                                        event.dataItem.resumeurl
                                                                    )
                                                                }
                                                            >
                                                                {event.dataItem.resumeurl.substring(
                                                                    event.dataItem.resumeurl.length - 5,
                                                                    event.dataItem.resumeurl.length
                                                                ) === ".docx" ? (
                                                                    <>
                                                                        <img src={docIcon} />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <img src={pdfIcon} width="39px" />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                }}
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="skill.name"
                                                title="Skill"
                                                width="180px"
                                            />

                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="company"
                                                title="Company"
                                                width="180px"
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="location"
                                                title="Location"
                                                width="180px"
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="email"
                                                title="Email"
                                                width="250px"
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="intlPhone"
                                                title="Phone Number"
                                                width="180px"
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="referredBy"
                                                title="Referred By"
                                                width="250px"
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="referredOn"
                                                title="Referred On"
                                                filterable={true}
                                                sortable={true}
                                                filter={"date"}
                                                format="{0:d},{0:t}"
                                                width={245}
                                                cell={(e) => {
                                                    return (
                                                        <td>
                                                            {DateTime.fromISO(e.dataItem.createdAt)
                                                                .toFormat("MM/dd/yyyy")
                                                                .toString()}
                                                        </td>
                                                    );
                                                }}
                                            />
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="status"
                                                title="Status"
                                                width="200px"
                                            />
                                        </GridDiv>
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    <div className='referralBottomContainer'>
                        <Footer />
                    </div>
                </div >
            </>
            }
            <Dialog
                open={openCancelModal}
                onClose={closeCancelReferralModal}
                aria-labelledby="customized-dialog-title"
            >
                <DialogContent
                    className={`${classes.bodyForRemove} removemodal_responsive`}
                >
                    <div className="text-center fs-semi-bold f-color fs-18">
                        Do you want to cancel referral for {cancelCandidateDetails.email}?
                    </div>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Button className={classes.btnNO} onClick={closeCancelReferralModal}>No</Button>
                    <Button className={classes.btnYES} onClick={cancelReferral}>Yes</Button>
                </DialogActions>
            </Dialog>
            {/* For create candidate  */}
            <Dialog
                open={openCandidateProfileModel}
                onClose={closeModelCreateProfile}
                aria-labelledby="customized-dialog-title"
            >
                <DialogContent
                    className={`${classes.bodyForRemove} removemodal_responsive`}
                >
                    <div className="text-center fs-semi-bold f-color fs-18">
                        Do you want to create a Candidate profile ?
                    </div>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Button className={classes.btnNO} onClick={closeModelCreateProfile}>No</Button>
                    <Button className={classes.btnYES} onClick={openModelCreateProfile}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MyTalentReferral;