import React, { useState, useEffect, useCallback } from "react";
import "./activity.css";
import Header from "../headerAdmin/Header";

import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Typography, Paper } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Footer from "../adminFooter/Footer";
import { Autocomplete, Skeleton } from '@material-ui/lab';
import EditIcon from "../basicInfoForm/images/edit-icon.png"
import getAllRequitionForCandi from "../../apis/getAllRequitionForCandidates/getAllRequitionForCandidates";
import deleteCandidateRequisition from "../../apis/deleteCandidateRequisition/deleteCandidateRequisition";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import RecentActivities from "../../pages/RequisitionDetails/RecentActivities"
import ExcelImg from "../../components/basicInfoForm/images/excelIcon.jpg";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import pdfIcon from "../../pages/myTalentCommunity/icons/pdf.svg"
import docIcon from "../../pages/myTalentCommunity/icons/doc.png";
import debounce from 'lodash/debounce';
import getAllCandidateRequisitionStatus from "../../apis/getAllCandidateRequisitionStatus/getAllCandidateRequisitionStatus";
import updateCandidateRequisition from "../../apis/updateCandidateRequisition/updateCandidateRequisition";
import configJSON from "../../Config.json";
import * as XLSX from 'xlsx';



const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        backgroundColor: "#d1e8ff",
        marginLeft: 0,
        width: '70%',
        marginTop: '4px',
        borderRadius: "5px",
        height: "25px",
    },
    buttonForSupport: {
        justifyContent: "flex-end !important",
        padding: "5px 42px 20px !important",
    },
    autocomplete: {
        // Your custom styles for the Autocomplete component
        // For example:
        // width: '300px',
        // height:"10px"
    },
    searchIcon: {
        //   padding: theme.spacing(0, 2),

        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        //   color: 'inherit',

    },
    profileButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        width: '70px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    inputInput: {
        padding: theme.spacing(0, 4),
        // vertical padding + font size from searchIcon
        paddingLeft: "0px",
        transition: theme.transitions.create('width'),
        width: '100%',
        //   backgroundColor:"#d1e8ff",
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    title: {
        backgroundColor: "#31B3F8"
    },
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
    btnCancel: {
        width: "120px",
        height: "32px",
        backgroundColor: "#FFFFFF",
        color: "#1885E6",
        border: "1px solid #1885E6",
        paddingTop: '5px',
        fontSize: '15px',
        borderRadius: "3px",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#D1E8FF"
        }
    },
    btnSave: {
        width: "120px",
        height: "32px",
        backgroundColor: "#1885E6",
        fontSize: '15px',
        color: "#FFFFFF",
        border: "1px solid #1885E6",
        paddingTop: '5px',
        borderRadius: "3px",
        "&:hover": {
            opacity: "0.8"
        }
    },
    btnExcel: {
        display: "flex",
        justifyContent: "space-between"


    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    waitingText: {
        marginLeft: '5px'
    },

}));


const Activity = (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const [userType, setUserType] = useState(props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType);
    const [userRecruitmentpartnerId, setUserRecruitmentpartnerId] = useState(props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : props?.userRecruitmentpartnerId);
    const [userRole, setUserRole] = useState(props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType);
    const [id, setId] = useState(props?.location?.state?.idContextTest ? props?.location?.state?.idContextTest : props?.idContextTest);
    const [userRecords, setUserRecords] = useState(props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords);

    const [startDate, setStartDate] = useState(new Date());
    const [isExporting, setIsExporting] = useState(false);
    const [isActivity, setisActivity] = useState(false)
    const [status, setstatus] = useState("INTERVIEW")
    const [requi, setrequi] = useState(location.state.requisitionId)
    const [statuscode, setstatuscode] = useState()
    const [isSearching, setIsSearching] = useState(true)
    const [searchTerms, setSearchTerms] = useState([]);
    const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
    const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResult, setTotalResult] = useState(0);
    const [loading, setLoading] = useState(false);
    const [removeReq, setremoveReq] = useState(false);

    const [applicableWithdrawn, setApplicableWithdrawn] = useState(false);
    const [candReqStatusList, setCandReqStatusList] = useState([]);
    const [candidateReqStatusList_withoutApplicationWithdrawn, setCandidateReqStatusList_withoutApplicationWithdrawn] = useState([]);
    const [selectedCandidateCurrentStatus, setSelectedCandidateCurrentStatus] = useState({});
    const [selectedCandReq, setSelectedCandReq] = useState({
        _id: "",
        referenceId: "",
        requisitionId: "",
        requisitionDetailId: "",
        requisitionLeaderId: "",
        requisitionRecruiterId: "",
        eventStatus: [],
        name: "",
        email: "",
        phone: "",
        displayDate: "",
        eventDate: "",
        resumeLink: "",
        status: {
            candidateStatusCode: "",
            candidateStatusCodeId: "",
        },
        notes: "",
        isActive: true,
        noOfOpenings: 0,
        totalNoOfOpenings: 0
    });


    const [dataState, setDataState] = useState(
        {
            skip: 0,
            take: 10,
            filter: {
                logic: "and",
                filters: [],
            },
        });

    const [dataResult, setDataResult] = useState([]);

    const dataStateChange = (event) => {
        const filters = event?.dataState?.filter?.filters || [];
        setIsSearching(true)
        setDataResult([])
        const terms = filters && filters.length > 0
            ? filters
                .filter(({ value }) => value && value !== '') // Filter out objects where value is empty, null, or white spaces
                .map(({ field, value, operator }) => ({ field, value, operator }))
            : [];


        setSearchTerms(terms);

        const take = event?.dataState?.take;
        const skip = event?.dataState?.skip;
        const page = Math.floor(skip / take) + 1;
        setTakeNumberOfResult(take);
        setSkipNumberOfResult(skip);
        setPageNumber(page);
        setDataState(event.dataState);
        setIsSearching(true);

    };
    const debouncedSearch = useCallback(
        debounce(async (id, page, size, statuscode, searchTerms, userType, requi) => {
            setLoading(true);
            let search;

            if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
                search = await getAllRequitionForCandi(userRecruitmentpartnerId, page, size, statuscode, searchTerms, userType === 'Admin' ? userRole : userType, requi);
            }
            else {
                search = await getAllRequitionForCandi(id, page, size, statuscode, searchTerms, userType === 'Admin' ? userRole : userType, requi);
            }


            if (search.data.data.length <= 0) {
                setLoading(false);
                setDataResult(search.data.data[0]);
                setTotalResult(0);
                setIsSearching(false);
            }
            if (search.data.data.length > 0) {
                setDataResult(search.data.data);
                if (search.data.totalCount)
                    setTotalResult(search.data.totalCount);
                setIsSearching(false);
            }

        }, 1000),
        []
    );
    useEffect(() => {

        setIsSearching(true);
        setDataResult([]);

        debouncedSearch(id, pageNumber, takeNumberOfResult, statuscode, searchTerms, userType, requi)

    }, [searchTerms, pageNumber, takeNumberOfResult, status])

    useEffect(() => {


        let setDefaultValue = {
            skip: 0,
            take: 10,
            filter: {
                logic: "and",
                filters: [],
            }
        }
        setDataState(setDefaultValue)
    }, [status]);


    useEffect(() => {

        // if candreq list length is 0 then run the fetch API
        if (!candReqStatusList.length) {
            fetchAllStatus();
        }

        if (location?.state?.candidateState)
            setstatus(location.state.candidateState)
        setstatuscode(location.state.statuscode)
        setrequi(location.state.requisitionId)
        setPageNumber(1)
        setSearchTerms([])
        setTakeNumberOfResult(10)
    }, [location]);


    const handleCandidateLink_candidatesearch = (props) => {
        return (
            <td align="center" className="activityInviteBtnAllignment cp">
                <Button
                    size="small"
                    variant="contained"
                    disabled={userType === "Admin" ? true : false}
                    className={classes.profileButton}
                    onClick={(e) => handleGotoProfile(props?.dataItem)}
                >
                    Action
                </Button>
            </td>
        );
    };

    const closeModalReq = () => {
        setremoveReq(false)
    }

    const handleGotoProfile = (data) => {
        let currentStatusCode = data?.status?.candidateStatusCode;
        if (currentStatusCode === configJSON.candidateRequisitionStatusCode.INTERVIEW || currentStatusCode === configJSON.candidateRequisitionStatusCode.SUBMITTED || currentStatusCode === configJSON.candidateRequisitionStatusCode.APPLICATION_WITHDRAWN) {
            setApplicableWithdrawn(true);
        }
        else {
            setApplicableWithdrawn(false);
        }

        setSelectedCandReq(prevState => ({
            ...prevState,  // Spread the previous state object to retain other properties
            _id: data?._id || "",
            referenceId: data?.requisitiondetails?.reqreferenceId || "",
            requisitionId: data?.requisitionId || "",
            requisitionDetailId: data?.requisitionDetailId || "",
            requisitionLeaderId: data?.requisitionLeaderId || "",
            requisitionRecruiterId: data?.requisitionRecruiterId || "",
            name: data?.name || "",
            email: data?.email || "",
            phone: data?.phone || "",
            eventStatus: data?.eventStatus || [],
            displayDate: new Date(data?.displayDate) || "",
            eventDate: new Date(data?.eventDate) || "",
            resumeLink: data?.resumeLink || "",
            status: data?.status,
            notes: data?.notes || "",
            isActive: data?.isActive,
            noOfOpenings: data?.recruiterrequisitions?.no_of_positions || 0,
            totalNoOfOpenings: data?.requisitiondetails?.openings || 0
        }));
        setSelectedCandidateCurrentStatus(data?.status || {});
        setisActivity(true)

    }

    const activityModalClose = () => {
        setisActivity(false);
        setSelectedCandReq({
            _id: "",
            requisitionId: "",
            referenceId: "",
            requisitionDetailId: "",
            requisitionLeaderId: "",
            requisitionRecruiterId: "",
            name: "",
            email: "",
            phone: "",
            displayDate: "",
            eventStatus: [],
            eventDate: "",
            resumeLink: "",
            isActive: true,
            status: {
                candidateStatusCode: "",
                candidateStatusCodeId: "",
            },
            notes: "",
            noOfOpenings: 0,
            totalNoOfOpenings: 0
        });
        setSelectedCandidateCurrentStatus({});

    }

    const goToDashboard = () => {
        if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
            history.push({
                pathname: "/requisition-dashboard",
                state: { id: id, recruitmentPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
            });
        } else {
            history.push({
                pathname: "/dashboard",
                state: {
                    userType: userType,
                    recruitmentPartnerId: userRecruitmentpartnerId,
                    id: id,
                    userRecords: userRecords,
                    roleName: userRole
                }
            });
        }
    }

    // fetching all candidate requisition status
    const fetchAllStatus = async () => {
        try {
            const res = await getAllCandidateRequisitionStatus();
            setCandidateReqStatusList_withoutApplicationWithdrawn(res?.data?.data?.filter(item => item?.candidateStatusCode !== configJSON.candidateRequisitionStatusCode.APPLICATION_WITHDRAWN) || []);
            setCandReqStatusList(res?.data?.data || []);
        }
        catch (err) {
            console.log("Error", err);
        }
    };
    // update submit modal data
    const updateSubmitDataState = (field, val) => {
        if (field === "status") {
            setSelectedCandReq(prevState => ({
                ...prevState,  // Spread the previous state object to retain other properties
                [field]: {
                    ['candidateStatusCode']: val?.candidateStatusCode || "",
                    ['candidateStatusCodeId']: val?._id || "",
                }
            }));

            if (String((candReqStatusList?.filter(item => item?._id === val?._id))[0]?.name).toUpperCase() === "INTERVIEW" || String((candReqStatusList?.filter(item => item?._id === val?._id))[0]?.name).toUpperCase() === "DROPOUT" || String((candReqStatusList?.filter(item => item?._id === val?._id))[0]?.name).toUpperCase() === "SELECTED" || String((candReqStatusList?.filter(item => item?._id === val?._id))[0]?.name).toUpperCase() === "STARTED" || String((candReqStatusList?.filter(item => item?._id === val?._id))[0]?.name).toUpperCase() === "APPLICATION WITHDRAWN") {
                setSelectedCandReq(prevState => ({
                    ...prevState,  // Spread the previous state object to retain other properties
                    eventDate: new Date(),
                }));
            }
            else {
                setSelectedCandReq(prevState => ({
                    ...prevState,  // Spread the previous state object to retain other properties
                    eventDate: "",
                }));
            }
        }
        else {
            setSelectedCandReq(prevState => ({
                ...prevState,  // Spread the previous state object to retain other properties
                [field]: val || ""
            }));
        }
    };

    const downloadResume = async (event, url) => {
        window.open(url, "_blank");
    };


    function convertDate(str) {
        if (str === "") {
            return "";
        }
        else {
            let date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
    }

    // final submission of the modal
    const submitCandReq = async () => {

        try {
            if (selectedCandReq?.requisitionId && selectedCandReq?.requisitionDetailId && selectedCandReq?.status?.candidateStatusCodeId && selectedCandReq?.displayDate && selectedCandReq?.notes) {
                let payloadData = { ...selectedCandReq }
                let eventStatus = payloadData.eventStatus;
                payloadData.displayDate = payloadData.displayDate ? new Date().toISOString() : "";
                payloadData.eventDate = payloadData.eventDate ? convertDate(payloadData?.eventDate) : "";
                payloadData.isActive = true;

                let index = eventStatus.findIndex(item => item.candidateStatusCodeId === payloadData.status.candidateStatusCodeId);
                if (index === -1) {
                    eventStatus.push({
                        candidateStatusCode: payloadData.status.candidateStatusCode,
                        candidateStatusCodeId: payloadData.status.candidateStatusCodeId,
                        created_at: new Date().toISOString(),
                        displayDate: payloadData.displayDate,
                        eventDate: payloadData.eventDate,
                    });
                }
                else {
                    eventStatus[index].displayDate = payloadData.displayDate;
                    eventStatus[index].eventDate = payloadData.eventDate;
                    eventStatus[index].updated_at = new Date().toISOString();
                }

                payloadData.eventStatus = eventStatus;

                await updateCandidateRequisition(payloadData);
                NotificationManager.success("Candidate status updated successfully", "Success", 4000);
                closeModalReq();
                activityModalClose();
                // reload the API
                setIsSearching(true);
                setDataResult([]);
                debouncedSearch(id, pageNumber, takeNumberOfResult, statuscode, searchTerms, userType, requi)
            }
            else {
                NotificationManager.error("Please fill in all the fields", "Error", 4000);
            }

        }
        catch (error) {
            if (error.response.status === 400) {
                const errorMessage = error.response.data.errMessage;
                NotificationManager.error(errorMessage, "Error", 2500);
            }
            else {
                NotificationManager.error("Failed to submit candidate", "Error", 4000);
            }
            console.log("Error", error);
        }
    };

    function toCamelCase(str) {
        let statusValue = str;
        if (str === "INTERVIEW") {
            statusValue = "INTERVIEW SCHEDULED";
        }
        let strarr = statusValue.split(" ");
        let newStr = "";

        strarr.forEach((item, index) => {
            if (index === 0) {
                newStr = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
            }
            else {
                newStr = newStr + " " + item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
            }
        });
        return newStr;
    }

    const getSkeleton_candidateSearch = () => {
        let arr = []
        for (let i = 0; i <= 10; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
        }
        return arr;
    }

    const filterOperators = {
        text: [
            {
                text: "grid.filterContainsOperator",
                operator: "contains",
            },
        ],
        numeric: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
        date: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
        boolean: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
    };


    const excelExport = async () => {
        setIsExporting(true)
        try {
            setLoading(true);
            let search;
            let maxSize = 100;
            let end = Math.ceil(totalResult / maxSize);
            let pageStart = 1;
            let pageEnd = maxSize;
            let exportArr = [];
            for (let start = 0; start < end; start++) {
                let page = pageStart
                let size = pageEnd;
                if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
                    search = await getAllRequitionForCandi(userRecruitmentpartnerId, page, size, statuscode, searchTerms, userType === 'Admin' ? userRole : userType, requi);
                }
                else {
                    search = await getAllRequitionForCandi(id, page, size, statuscode, searchTerms, userType === 'Admin' ? userRole : userType, requi);
                }

                search.data.data.forEach(element => {
                    let data = {
                        "Name": element.name,
                        "Email": element.email,
                        "Phone": element.phone,
                        "Requisition Name": element.recruiterData.displayName,
                        "Updated On": convertDate(element.displayDate),
                    }
                    if (statuscode === configJSON.candidateRequisitionStatusCode.INTERVIEW) {
                        data["Interview Date"] = element.eventDate
                    } else if (statuscode === configJSON.candidateRequisitionStatusCode.SELECTED) {
                        data["Starting Date"] = element.eventDate
                    } else if (statuscode === configJSON.candidateRequisitionStatusCode.STARTED) {
                        data["Started On"] = element.eventDate
                    } else if (statuscode === configJSON.candidateRequisitionStatusCode.DROPOUT) {
                        data["Dropout Date"] = element.eventDate
                    } else if (statuscode === configJSON.candidateRequisitionStatusCode.APPLICATION_WITHDRAWN) {
                        data["Withdraw Date"] = element.eventDate
                    } else if (status === "All Candidate") {
                        data["Stage"] = element.candidateStatus.name;
                        data["Date"] = element.eventDate
                    }

                    exportArr.push(data)
                });
                pageStart = pageStart + maxSize;
            }
            if (exportArr.length === 0) {
                NotificationManager.error(
                    "No data present",
                    "Error",
                    2500
                );

            }
            else {
                const MAX_ROWS_PER_SHEET = 500;

                const wb = XLSX.utils.book_new();

                // Calculate the number of sheets needed
                const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

                // Loop to create sheets
                for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
                    // Get a subset of data for each sheet
                    const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
                    const endRow = Math.min((sheetIndex + 1) * MAX_ROWS_PER_SHEET, exportArr.length);
                    const subsetData = exportArr.slice(startRow, endRow);

                    // Convert JSON array to worksheet
                    const ws = XLSX.utils.json_to_sheet(subsetData);

                    // Append the sheet to the workbook
                    XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
                }

                // Save the workbook to an Excel file
                XLSX.writeFile(wb, `ActivityReport.xlsx`, { bookType: 'xlsx', type: 'file' });
            }
            setIsExporting(false)

        } catch (error) {
            console.log("error", error)
        }
    }

    const getFilteredRequisitionStatus = (statusList) => {
        let currentStatusWeightage = (candReqStatusList?.filter(item => item?._id === selectedCandidateCurrentStatus?.candidateStatusCodeId))[0]?.weightage;
        let statusListFiltered = statusList.filter(item => item.weightage >= currentStatusWeightage);
        return statusListFiltered;
    }

    return (
        <>
            <Backdrop
                className={classes.backdrop}
                open={isExporting}
            >
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}> Please wait ...</Typography>
            </Backdrop>


            <div className='talentCommunityMainContainer'>
                <div className='talentCommunityTopContainer'>
                    <Grid container>
                        <Grid item xs={12}>
                            <Header />
                        </Grid>
                    </Grid>
                    <div className="mainDiv_advanceSearch">
                        <div className="">

                            {status !== "" ?
                                <div className="titlesofCommunity">
                                    Stage : {toCamelCase(status)}
                                </div>
                                : (
                                    <div className="titlesofCommunity_blank">
                                    </div>

                                )
                            }

                            {/* </div> */}

                        </div>
                        <div className={classes.btnExcel}>
                            <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>

                                <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                            </div>
                            <div>
                                <button className='excelButton' onClick={excelExport}><img src={ExcelImg} className='excelImg_advanceSearch' alt="" /><span>Export</span></button>
                            </div>
                        </div>
                        <div className="interview_mainCont">
                            <div className="activity_topCon">
                                <div className="pt_20 KendoContainer_Activity">
                                    <GridDiv
                                        filterable={true}
                                        filter={dataState.filter}
                                        sortable={true}
                                        resizable={true}
                                        reorderable={true}
                                        pageable={{
                                            pageSizes: [10, 20, 50, 100],
                                            info: true,
                                            previousNext: true,
                                            buttonCount: 10
                                        }}
                                        skip={dataState.skip}
                                        take={dataState.take}
                                        filterOperators={filterOperators}
                                        onDataStateChange={dataStateChange}
                                        data={dataResult}
                                        total={totalResult}
                                        className='kendo_Activity'
                                    >
                                        <GridNoRecords>
                                            {isSearching ? getSkeleton_candidateSearch() : "No results found !"}
                                        </GridNoRecords>
                                        {userType === "MIS Operator" || userType === "Organization Admin" || (userType === "Admin" && (userRole === "MIS Operator" || userRole === "Organization Admin")) ? ""
                                            :
                                            <GridColumn
                                                width="150px"
                                                filterable={false}
                                                title="Action"
                                                cell={handleCandidateLink_candidatesearch}
                                            />
                                        }
                                        <GridColumn
                                            field="resumeLink"
                                            filterable={false}
                                            title="Document"
                                            width="130px"

                                            cell={(event) => {
                                                return (
                                                    <td>


                                                        <div
                                                            className="activityInviteBtnAllignment cp"
                                                            onClick={(e) =>
                                                                downloadResume(
                                                                    e,
                                                                    event.dataItem.resumeLink
                                                                )
                                                            }
                                                        >
                                                            {event.dataItem.resumeLink.substring(
                                                                event.dataItem.resumeLink.length - 5,
                                                                event.dataItem.resumeLink.length
                                                            ) === ".docx" ? (
                                                                <>
                                                                    <img src={docIcon} alt="" />
                                                                </>
                                                            ) : event.dataItem.resumeLink.substring(
                                                                event.dataItem.resumeLink.length - 4,
                                                                event.dataItem.resumeLink.length
                                                            ) === ".pdf" ? (
                                                                <>
                                                                    <img src={pdfIcon} width="39px" alt="" />
                                                                </>
                                                            ) : "N/A"}
                                                        </div>

                                                    </td>
                                                );
                                            }}
                                        />

                                        <GridColumn
                                            field="name"
                                            title="Name"
                                            width="170px"
                                        />
                                        <GridColumn
                                            className={classes.fontRoboto}
                                            field="email"
                                            title="Email"
                                            width="250px"
                                        />
                                        <GridColumn
                                            className={classes.fontRoboto}
                                            title="Phone"
                                            field="phone"
                                            width="180px"
                                            sortable={false}
                                        />
                                        <GridColumn
                                            className={classes.fontRoboto}
                                            filterable={false}
                                            field="recruiterData.displayName"
                                            title="Recruiter Name"
                                            width="170px"
                                        />


                                        {status === "INTERVIEW" ?

                                            (<GridColumn
                                                className={classes.fontRoboto}
                                                filter={"date"}
                                                field="eventDate"
                                                title="Interview Date"
                                                width="150px"

                                            />
                                            ) : ""


                                        }
                                        {status === "SELECTED" ?

                                            (<GridColumn
                                                className={classes.fontRoboto}
                                                filter={"date"}
                                                field="eventDate"
                                                title="Starting Date"
                                                width="190px"
                                            />
                                            ) : ""


                                        }

                                        {status === "STARTED" ?

                                            (<GridColumn
                                                className={classes.fontRoboto}
                                                filter={"date"}
                                                field="eventDate"
                                                title="Started On"
                                                width="190px"
                                            />
                                            ) : ""


                                        }



                                        {status === "DROPOUT" ?

                                            (<GridColumn
                                                className={classes.fontRoboto}
                                                field="eventDate"
                                                filter={"date"}
                                                title="Dropout Date"
                                                width="190px"
                                            />
                                            ) : ""


                                        }
                                        {status === "APPLICATION WITHDRAWN" ?

                                            (<GridColumn
                                                className={classes.fontRoboto}
                                                field="eventDate"
                                                filter={"date"}
                                                title="Withdraw Date"
                                                width="190px"
                                            />
                                            ) : ""


                                        }
                                        {status === "All Candidate" ?

                                            (
                                                <GridColumn
                                                    className={classes.fontRoboto}
                                                    field="candidateStatus.name"
                                                    filterable={false}
                                                    title="Stage"
                                                    width="160px"
                                                />
                                            ) : ""


                                        }
                                        {status === "All Candidate" ?

                                            (
                                                <GridColumn
                                                    className={classes.fontRoboto}
                                                    field="eventDate"
                                                    filter={"date"}
                                                    title="Date"
                                                    width="190px"
                                                />
                                            ) : ""


                                        }
                                        <GridColumn
                                            className={classes.fontRoboto}
                                            field="updated_at"
                                            filterable={false}
                                            title="Updated On"
                                            width="220px"

                                        />


                                    </GridDiv>

                                </div>


                                <div className="activity_rightCon">
                                    <RecentActivities activity={true} requisitionId={requi} idContextTest={id} userType={userType} userRecords={userRecords} userRole={userRole} userRecruitmentpartnerId={userRecruitmentpartnerId} isSearching={isSearching} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="activity_footer">
                    <Footer />
                </div>

            </div>

            {/* Action Card */}
            <Dialog
                open={isActivity}
                onClose={activityModalClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        width: "470px",
                    },
                }}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={`${classes.title} responsive_title`}
                >
                    <div className="activit_dialog_header">

                        <div className="pd_top_10"> <img src={EditIcon} height={35} width={35} alt="" />&nbsp;&nbsp;</div>
                        <div><span className="activity_dialog">Action</span></div>

                    </div>
                </DialogTitle>

                <DialogContent
                    className={`${classes.bodyForSupport} responsive_Support`}
                >

                    <div className="ml_20">
                        <span className='filterItemTitle mt_10'>Reference ID *</span>

                        <Autocomplete
                            id="free-solo-2-demo"
                            classes={{
                                root: classes.autocomplete,
                                inputRoot: classes.inputRoot,
                                popper: classes.popper,
                                // Add more classes as needed
                            }}
                            freeSolo
                            value={selectedCandReq?.referenceId || ""}
                            disabled={true}
                            className='searchreq_autocom mt_6 mb_10'
                            size="small"
                            filterSelectedOptions
                            getOptionLabel={(option) => option || ""}
                            onChange={(e, value) => updateSubmitDataState('requisitionId', value?.requisitionId)}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{ fontSize: "12px", fontFamily: 'Segoe UI' }}
                                >
                                    {children}
                                </Paper>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder=''
                                    variant="outlined"
                                />}
                        />
                        <span className='filterItemTitle'>Status *</span>

                        <Autocomplete
                            id="free-solo-2-demo"
                            options={applicableWithdrawn ?
                                getFilteredRequisitionStatus(candReqStatusList) :
                                getFilteredRequisitionStatus(candidateReqStatusList_withoutApplicationWithdrawn)
                                || []}
                            classes={{
                                root: classes.autocomplete,
                                inputRoot: classes.inputRoot,
                                popper: classes.popper,
                            }}

                            className='searchreq_autocom mt_6 mb_10'
                            size="small"
                            filterSelectedOptions
                            value={(candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0] || { name: "" }}
                            getOptionLabel={(option) => (option?.name === "INTERVIEW" ? "INTERVIEW SCHEDULED" : option.name) || ""}
                            onChange={(e, value) => updateSubmitDataState('status', value)}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{ fontSize: "16px", fontFamily: 'Segoe UI' }}
                                >
                                    {children}
                                </Paper>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder='Status'
                                    variant="outlined"
                                />}
                        />
                        {
                            String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "INTERVIEW"
                                || String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "DROPOUT"
                                || String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "SELECTED"
                                || String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "STARTED"
                                || String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "APPLICATION WITHDRAWN"
                                ?
                                (<div className="activity_date">
                                    <div className='filterItemTitle'>
                                        {String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "SELECTED"
                                            ?
                                            "Start"
                                            :
                                            String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "STARTED"
                                                ?
                                                "Joining"
                                                :
                                                String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "APPLICATION WITHDRAWN"
                                                    ?
                                                    "Withdrawn"
                                                    :
                                                    String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).toUpperCase() === "SUBMITTED"
                                                        ?
                                                        null
                                                        :
                                                        String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).charAt(0).toUpperCase()
                                                        + String((candReqStatusList?.filter(item => item?._id === selectedCandReq?.status?.candidateStatusCodeId))[0]?.name).slice(1).toLowerCase()} Date *
                                    </div>
                                    <DatePicker
                                        className="activity_date_border mt_6 mb_10"
                                        showIcon
                                        selected={selectedCandReq?.eventDate || ""}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            updateSubmitDataState('eventDate', date);
                                        }} />
                                </div>)
                                : (null)
                        }
                        <span className='filterItemTitle'>Status Updated On *</span>
                        <div className="activity_date">

                            <DatePicker
                                className="activity_date_border mt_6 mb_10"
                                showIcon
                                selected={selectedCandReq?.displayDate || ""}
                                disabled={true}
                                onChange={(date) => {
                                    setStartDate(date);
                                    updateSubmitDataState('displayDate', date);
                                }} />
                        </div>


                        <span className='filterItemTitle'>Notes *</span>

                        <textarea
                            type="text"
                            rows="5"
                            value={selectedCandReq?.notes || ""}
                            onChange={(e) => updateSubmitDataState('notes', e?.target?.value)}
                            className='activity_autocom activity_textarea mt_6 mb_10'
                        />
                    </div>

                </DialogContent>
                <DialogActions className={`${classes.buttonForSupport} `}>
                    <button
                        type="button"
                        className="btn_activity_cancel cp"
                        onClick={activityModalClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn_activity_save cp"
                        onClick={(e) => submitCandReq()}
                    >
                        Save
                    </button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    )
}

export default Activity;