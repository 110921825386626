import React, { useEffect, useState, createContext, useCallback } from "react";
import Header from "../../components/headerAdmin/Header";
import Footer from "../../components/adminFooter/Footer";
import "./pendingResumeValidationsPage.css";
import { Tooltip, makeStyles } from "@material-ui/core";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PendingResumeValidationsForm from "../../components/pendingResumeValidationsForm/pendingResumeValidationsForm";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import getAllPendingResumes from "../../apis/pendingResumeById/pendingResumeById";
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import debounce from 'lodash/debounce';

const PendingResumeValidationsContext = createContext();
export { PendingResumeValidationsContext };


const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
}))


const PendingResumeValidationsPage = (props) => {
    const classes = useStyles()
    const history = useHistory();

    const id = props?.location?.state?.id ? props?.location?.state?.id : props?.id;
    const userRecruitmentpartnerId = props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId;
    const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType;
    const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords;

    const idContextTest = id

    const [showPendingResumeForm, setShowPendingResumeForm] = useState(false);
    const [clickedRowItem, setClickedRowItem] = useState({});
    const [candidateResume, setCandidateResume] = useState({});
    const [candidateResumeId, setCandidateResumeId] = useState("");
    const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
    const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResult, setTotalResult] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchTerms, setSearchTerms] = useState([]);


    const [isSearching, setIsSearching] = useState(true)

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [],
        },
    });

    const [dataResult, setDataResult] = useState([]);

    const dataStateChange = (event) => {
        const filters = event?.dataState?.filter?.filters || [];
        setIsSearching(true)
        setDataResult([])
        const terms = filters && filters.length > 0
            ? filters
                .filter(({ value }) => value && value !== '') // Filter out objects where value is empty, null, or white spaces
                .map(({ field, value, operator }) => ({ field, value, operator }))
            : [];


        setSearchTerms(terms);

        const take = event?.dataState?.take;
        const skip = event?.dataState?.skip;
        const page = Math.floor(skip / take) + 1;

        setTakeNumberOfResult(take);
        setSkipNumberOfResult(skip);
        setPageNumber(page);
        setDataState(event.dataState);
        setIsSearching(true);

    };


    const debouncedSearch = useCallback(
        debounce(async (recruitmentpartnerId, searchTerms, page, size) => {
            setLoading(true);

            const search = await getAllPendingResumes(recruitmentpartnerId, searchTerms, page, size,userType);
            if (search.data.length <= 0) {
                setLoading(false);
                setDataResult(search.data);
                setTotalResult(search.count);
                setIsSearching(false);
            }
            if (search.data.length > 0) {
                setDataResult(search.data);
                setTotalResult(search.count);
                setIsSearching(false);
            }

        }, 1000),
        []
    );
    useEffect(() => {
        let recruitmentpartnerId = userRecruitmentpartnerId;
        debouncedSearch(recruitmentpartnerId, searchTerms, pageNumber, takeNumberOfResult)
    }, [searchTerms, pageNumber, takeNumberOfResult])
   



    const goToMyTalent = (e) => {
        if (!showPendingResumeForm) {
            history.push({
                pathname: "/dashboard",
                state: {
                    userType: userType,
                    recruitmentPartnerId: userRecruitmentpartnerId,
                    id: idContextTest,
                    userRecords: userRecords,
                    roleName: userRole
                }
            });
        } else {
            setShowPendingResumeForm(false);
        }
    };

    const rowClickHandler = (item) => {
        setCandidateResume(item);
        setShowPendingResumeForm(true);
        setClickedRowItem(item);
        setCandidateResumeId(item?.candidateResumeId);
    };

    const rowColor = (props) => {
        if ((props.dataItem.isProfileComplete || props.dataItem.isParsing === false) === false) {
            return { backgroundColor: '#ff414145', cursor: 'pointer' };
        } else {
            return { cursor: 'pointer' };
        };
    }

    const getSkeleton = () => {
        let arr = []
        for (let i = 0; i < 11; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
        }
        return arr;
    }

    const handlePhoneNumber = (props) => {
        let phoneNumber = props.dataItem.phoneNumber;
        phoneNumber = phoneNumber.replace(/[()-]/g, '');
        if (props.dataItem.countryCallingCode) {
            let ccode = props.dataItem.countryCallingCode;
            let originalPhoneNumber = phoneNumber;
            let index = originalPhoneNumber.indexOf(ccode);
            if (index === -1) {
                phoneNumber = ccode + " " + originalPhoneNumber;
            }
        }
        return (
            <td onClick={(e) => rowClickHandler(props.dataItem)}>
                {props.dataItem.phoneNumber ? (
                    <span>
                        {phoneNumber}
                    </span>
                ) : (
                    <span></span>
                )}
            </td>
        );
    }


    return (
        <>
            <div className="talentCommunityMainContainer">
                <div>
                    <Header />
                    {(userType === "Candidate" || userType === "MIS Operator") ? (

                        <>
                            <div className="row padding_80 text-center">
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </>
                    ) : <>

                        <div className="pending_resume_container">
                            <h2 className="titlesofCommunity">
                                Pending Resume Validations
                            </h2>
                            <span
                                className="goBackToRecruiterDashboard"
                                onClick={(e) => goToMyTalent(e)}
                            >
                                <ArrowBackIcon />
                                {!showPendingResumeForm
                                    ? "Go Back to Dashboard"
                                    : "Go Back to Pending Resume Validation"}
                            </span>

                            {!showPendingResumeForm && dataResult.length >= 0 ? (
                                <Grid
                                    filter={dataState.filter}
                                    filterable={true}
                                    reorderable={true}
                                    resizable={true}
                                    pageable={{
                                        buttonCount: 10,
                                        info: true,
                                        previousNext: true,
                                        pageSizes: [10, 20, 50],
                                    }}
                                    className="kendoStylePendingResume"
                                    skip={dataState.skip}
                                    take={dataState.take}
                                    data={dataResult}
                                    total={totalResult}
                                    onDataStateChange={dataStateChange}
                                    onRowClick={(e) => rowClickHandler(e.dataItem)}
                                    rowRender={(trElement, dataItem) => {
                                        const rowStyle = rowColor(dataItem);
                                        return React.cloneElement(trElement, { style: rowStyle });
                                    }}

                                >
                                    <GridNoRecords>
                                        {isSearching ? getSkeleton() : "No results found !"}
                                    </GridNoRecords>
                                    <Column
                                        field="name"
                                        title="Name"
                                        width="250px"
                                    />
                                    <Column
                                        field="emailID"
                                        title="Email"
                                        width="250px"
                                    />
                                    <Column
                                        title="Phone"
                                        width="250px"
                                        cell={(props) => handlePhoneNumber(props)}
                                    />
                                    <Column
                                        title="Status"
                                        className="icons_column"
                                        width="250px"
                                        filterable={false}
                                        cell={(props) => (
                                            <td className="status_icons">
                                                <span className={`icon ${props.dataItem.isProfileComplete ? 'success' : 'error'}`}>
                                                    {props.dataItem.isProfileComplete ? (
                                                        <Tooltip title="Profile Completed" placement="top"><CheckCircleIcon /></Tooltip>
                                                    ) : (
                                                        <Tooltip title="Profile Not Completed" placement="top"><ErrorIcon /></Tooltip>
                                                    )}
                                                </span>
                                            </td>
                                        )}
                                    />
                                </Grid>

                            ) : (
                                <PendingResumeValidationsForm
                                    idContextTest={idContextTest}
                                    clickedRowItem={clickedRowItem}
                                    candidateResume={candidateResume}
                                    setShowPendingResumeForm={setShowPendingResumeForm}
                                    showPendingResumeForm={showPendingResumeForm}
                                    userType={userType}
                                    dataResult={dataResult}
                                    setDataResult={setDataResult}
                                    userRecruitmentpartnerId={userRecruitmentpartnerId}
                                    candidateResumeId={candidateResumeId}
                                />
                            )}

                        </div>
                    </>
                    }
                </div>
                <div className="footer_container_pending_resume"><Footer /></div>

            </div>
            <NotificationContainer />
        </>
    );
};

export default PendingResumeValidationsPage;